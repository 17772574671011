import { createSlice } from "@reduxjs/toolkit";
import orderApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    orders: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const orderSlice = createSlice({
    name: "food-new-order",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        editOrder: (state, action) => {
            state.isCompleted = true;
            state.orders = state.orders.map((order) =>
                order._id === action.payload._id ? action.payload : order
            );
        },
        removeOrder: (state, action) => {
            state.isCompleted = true;
            state.orders = state.orders.filter(
                (order) => order._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setOrders,
    setPagination,
    editOrder,
    removeOrder,
} = orderSlice.actions;

export const fetchOrders = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await orderApi.getAll(params);
            dispatch(setOrders(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const cancelOrder = (id, reason) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await orderApi.cancel(id, reason);
            if (data.success) {
                dispatch(removeOrder(id));
                toast.success("Hủy đơn hàng thành công!");
            } else {
                toast.error("Lỗi hệ thống, hủy đơn hàng thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default orderSlice.reducer;
