import { createSlice } from "@reduxjs/toolkit";
import campaignApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    campaigns: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const campaignSlice = createSlice({
    name: "food-campaign",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setCampaigns: (state, action) => {
            state.campaigns = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addCampaign: (state, action) => {
            state.isCompleted = true;
            state.campaigns.unshift(action.payload);
        },
        editCampaign: (state, action) => {
            state.isCompleted = true;
            state.campaigns = state.campaigns.map((campaign) =>
                campaign._id === action.payload._id ? action.payload : campaign
            );
        },
        removeCampaign: (state, action) => {
            state.isCompleted = true;
            state.campaigns = state.campaigns.filter(
                (campaign) => campaign._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setCampaigns,
    setPagination,
    addCampaign,
    editCampaign,
    removeCampaign,
} = campaignSlice.actions;

export const fetchCampaigns = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await campaignApi.getAll(params);
            dispatch(setCampaigns(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createCampaign = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await campaignApi.create(formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addCampaign(data));
                toast.success("Thêm chiến dịch đồ ăn mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateCampaign = (campaign, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await campaignApi.update(campaign._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editCampaign(data));
                toast.success("Cập nhật chiến dịch đồ ăn thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteCampaign = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await campaignApi.delete(id);
            if (data.success) {
                dispatch(removeCampaign(id));
                toast.success("Xóa chiến dịch đồ ăn thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa chiến dịch đồ ăn thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default campaignSlice.reducer;
