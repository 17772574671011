import { Form, Input, Modal, Select } from "antd";
import { formItemLayoutHorizontal } from "utils/constants";

const { Option } = Select;

function DriverDetailModal({ open, isProcessing, onSubmit, onCancel, driver }) {
    const [form] = Form.useForm();

    const initialValues = driver || {
        name: "",
        phone: "",
        note: "",
        vehicleType: "car",
        active: true,
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => onSubmit({ ...initialValues, ...values }))
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Tài xế"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={720}
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Tên đầy đủ" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="SĐT"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input disabled={Boolean(driver)} placeholder="SĐT" />
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                    <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        placeholder="Ghi chú tài xế"
                    />
                </Form.Item>
                <Form.Item name="vehicleType" label="Loại xe">
                    <Select>
                        <Option value={"car"}>Ô tô</Option>
                        <Option value={"motor"}>Xe máy</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="active" label="Trạng thái">
                    <Select>
                        <Option value={true}>Active</Option>
                        <Option value={false}>In-active</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default DriverDetailModal;
