import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Image,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import { WALLET_REQUEST_STATUS } from "utils/constants";
import Actions from "./components/Actions";
import ChangeStatusModal from "./components/ChangeStatus";
import WalletRequestDetailModal from "./components/DetailModal";
import * as walletRequestActions from "./slice";

function WalletRequest() {
    const dispatch = useDispatch();
    const { walletRequests, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.walletRequest);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectingWalletRequest, setSelectingWalletRequest] = useState(null);
    const [searchStatus, setSearchStatus] = useState("");
    const [qrImage, setQrImage] = useState(null);

    useEffect(() => {
        dispatch(walletRequestActions.fetchWalletRequests(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setOpenChangeStatusModal(false);
            setSelectingWalletRequest(null);
        }
    }, [isCompleted]);

    useEffect(() => {
        onSearch(searchStatus);
    }, [searchStatus]);

    const onCreateItem = () => {
        setSelectingWalletRequest(null);
        setOpenDetailModal(true);
    };

    const onOpenChangeStatus = (walletRequest) => {
        setSelectingWalletRequest(walletRequest);
        setOpenChangeStatusModal(true);
    };

    const onSubmit = (walletRequest) => {
        dispatch(walletRequestActions.createRequest(walletRequest));
    };

    const onChangeStatus = (walletRequest) => {
        dispatch(walletRequestActions.updateWalletRequestStatus(walletRequest));
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingWalletRequest(null);
        dispatch(walletRequestActions.setIsProcessing(false));
    };

    const onCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
        setSelectingWalletRequest(null);
        dispatch(walletRequestActions.setIsProcessing(false));
    };

    const onVisibleQrImage = (record) => {
        setQrImage(record.qr);
    };

    const onChangeStatusSearch = (value) => {
        setSearchStatus(value);
    };

    const onSearch = (status) => {
        const params = { ...pagination };
        if (status) {
            params.status = status;
        }
        dispatch(walletRequestActions.fetchWalletRequests(params));
    };

    const onTableChange = async (pagination) => {
        dispatch(
            walletRequestActions.fetchWalletRequests({
                ...pagination,
                status: searchStatus,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Model",
            render: (record) => (
                <>
                    <Typography.Text>{record.creatorModel}</Typography.Text>
                    {record.creatorModel === "Merchant" && (
                        <Typography.Paragraph>
                            {record.creator.restaurant?.name || ""}
                        </Typography.Paragraph>
                    )}
                </>
            ),
        },
        {
            title: "Tên",
            dataIndex: ["creator", "name"],
        },
        {
            title: "Loại",
            dataIndex: "type",
            render: (type) =>
                type === "WITHDRAW" ? (
                    <Tag color="magenta">RÚT TIỀN</Tag>
                ) : (
                    <Tag color="cyan">NẠP TIỀN</Tag>
                ),
        },
        {
            title: "Số tiền",
            dataIndex: "amount",
            render: (amount) => priceToCurrency(amount),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (status) => (
                <Tag color={WALLET_REQUEST_STATUS[status].color}>
                    {WALLET_REQUEST_STATUS[status].label}
                </Tag>
            ),
        },
        {
            title: "Ghi chú",
            width: "20%",
            render: (record) => (
                <>
                    {record.transferRequest ? (
                        <Tag color="#f50">Chi hộ</Tag>
                    ) : (
                        ""
                    )}
                    {record.note ? record.note : ""}
                </>
            ),
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Thao tác",
            align: "center",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenChangeStatus}
                    onShowQr={onVisibleQrImage}
                    editDisabled={
                        record.status !== WALLET_REQUEST_STATUS.processing.value
                    }
                />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Lệnh nạp - rút ví
                </Typography.Title>
                <Space size="middle">
                    <label className="font-semibold">Trạng thái:</label>
                    <Select
                        onChange={onChangeStatusSearch}
                        value={searchStatus}
                        options={[
                            {
                                value: "",
                                label: "Tất cả",
                            },
                            ...Object.values(WALLET_REQUEST_STATUS),
                        ]}
                        className="w-60"
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={onCreateItem}
                    >
                        Tạo lệnh nạp/rút ví
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={walletRequests}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <WalletRequestDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            )}
            {openChangeStatusModal && (
                <ChangeStatusModal
                    open={openChangeStatusModal}
                    onCancel={onCloseChangeStatusModal}
                    walletRequest={selectingWalletRequest}
                    onSubmit={onChangeStatus}
                    isProcessing={isProcessing}
                />
            )}
            <Image
                style={{
                    display: "none",
                }}
                preview={{
                    visible: !!qrImage,
                    src: qrImage,
                    destroyOnClose: true,
                    onVisibleChange: (value) => setQrImage(null),
                }}
            />
        </>
    );
}

export default WalletRequest;
