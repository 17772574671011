import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Image,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import { fetchSubCategories } from "../sub-category/slice";
import Actions from "./components/Actions";
import PlaceDetailModal from "./components/DetailModal";
import * as placeActions from "./slice";
import "./Place.scss";

const { Paragraph } = Typography;
const { Option } = Select;

function Place() {
    const { places, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.servicePlace);
    const { subCategories } =
        useSelector((state) => state.serviceSubCategory) || [];

    const subCategoriesOption = subCategories.map((category) => (
        <Option key={category._id} value={category._id}>
            {category.name}
        </Option>
    ));
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingPlace, setSelectingPlace] = useState(null);
    const [searchName, setSearchName] = useState("");
    const [searchIsHot, setSearchIsHot] = useState("");
    const [searchIsPinned, setSearchIsPinned] = useState("");
    const [searchIsVerified, setSearchIsVerified] = useState("");
    const [searchIsActive, setSearchIsActive] = useState(true);
    const [searchSubCategories, setSearchSubCategory] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            placeActions.fetchPlaces({
                ...pagination,
                isActive: searchIsActive,
            })
        );
        dispatch(fetchSubCategories({ pageSize: 100 }));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingPlace(null);
        }
    }, [isCompleted]);

    const onTableChange = async (pagination) => {
        dispatch(
            placeActions.fetchPlaces({
                ...pagination,
                name: searchName,
                isHot: searchIsHot,
                isPinned: searchIsPinned,
                isVerified: searchIsVerified,
                isActive: searchIsActive,
                subCategories: searchSubCategories,
            })
        );
    };

    const onSubmit = (place, formData) => {
        if (place._id) {
            dispatch(placeActions.updatePlace(place._id, formData));
        } else {
            dispatch(placeActions.createPlace(formData));
        }
    };

    const onCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectingPlace(null);
        dispatch(placeActions.setIsProcessing(false));
    };

    const handleSearchIsHot = (isHot) => {
        setSearchIsHot(isHot);
        dispatch(
            placeActions.fetchPlaces({
                isHot: isHot,
                name: searchName,
                isPinned: searchIsPinned,
                isVerified: searchIsVerified,
                isActive: searchIsActive,
                subCategories: searchSubCategories,
                ...pagination,
                current: 1,
            })
        );
    };

    const handleSearchIsPinned = (isPinned) => {
        setSearchIsPinned(isPinned);
        dispatch(
            placeActions.fetchPlaces({
                isPinned: isPinned,
                name: searchName,
                isHot: searchIsHot,
                isVerified: searchIsVerified,
                isActive: searchIsActive,
                subCategories: searchSubCategories,
                ...pagination,
                current: 1,
            })
        );
    };

    const handleSearchIsVerified = (isVerified) => {
        setSearchIsVerified(isVerified);
        dispatch(
            placeActions.fetchPlaces({
                isVerified: isVerified,
                name: searchName,
                isHot: searchIsHot,
                isPinned: searchIsPinned,
                isActive: searchIsActive,
                subCategories: searchSubCategories,
                ...pagination,
                current: 1,
            })
        );
    };

    const handleSearchIsActive = (isActive) => {
        setSearchIsActive(isActive);
        dispatch(
            placeActions.fetchPlaces({
                isActive: isActive,
                name: searchName,
                isHot: searchIsHot,
                isPinned: searchIsPinned,
                isVerified: searchIsVerified,
                subCategories: searchSubCategories,
                ...pagination,
                current: 1,
            })
        );
    };

    const handleToggleHot = (id, isHot) => {
        Modal.confirm({
            title: `Xác nhận ${isHot ? "chọn" : "hủy"} nổi bật?`,
            content: `Bạn có chắc muốn ${
                isHot ? "chọn" : "hủy"
            } nổi bật địa điểm này?`,
            onOk: () => onSetIsHot(id, isHot),
            centered: true,
        });
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onChangeSearchSubCategory = (subCategories) => {
        setSearchSubCategory(subCategories);
        dispatch(
            placeActions.fetchPlaces({
                subCategories: subCategories,
                name: searchName,
                isHot: searchIsHot,
                isPinned: searchIsPinned,
                isVerified: searchIsVerified,
                isActive: searchIsActive,
                ...pagination,
                current: 1,
            })
        );
    };

    const onSearch = (searchValue) => {
        dispatch(
            placeActions.fetchPlaces({
                ...pagination,
                isHot: searchIsHot,
                isPinned: searchIsPinned,
                isVerified: searchIsVerified,
                isActive: searchIsActive,
                subCategories: searchSubCategories,
                current: 1,
            })
        );
    };

    const onOpenDetailModal = (place = null) => {
        setSelectingPlace(place);
        setOpenDetailModal(true);
    };

    const onSetIsHot = (_id, isHot) => {
        dispatch(placeActions.updateIsHotPlace(_id, isHot));
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Paragraph>
            ),
        },
        {
            title: "Hình ảnh",
            width: "10%",
            dataIndex: "avatar",
            render: (avatar) =>
                avatar ? (
                    <Image
                        alt="Avatar of place"
                        src={getPhotoUrl(avatar?.path || null)}
                        width="80px"
                        height="80px"
                    />
                ) : null,
        },
        {
            title: "Tên điểm dịch vụ",
            width: "20%",
            render: (record) => (
                <>
                    <b style={{ display: "block" }}>{record.name}</b>
                    {record.isHot ? (
                        <Tag
                            color="orange"
                            style={{
                                display: "inline-block",
                                marginTop: "4px",
                            }}
                        >
                            Nổi bật
                        </Tag>
                    ) : null}
                    {record.isVerified ? (
                        <Tag
                            color="blue"
                            style={{
                                display: "inline-block",
                                marginTop: "4px",
                            }}
                        >
                            Đã duyệt
                        </Tag>
                    ) : null}
                    {record.isPinned ? (
                        <Tag
                            color="red"
                            style={{
                                display: "inline-block",
                                marginTop: "4px",
                            }}
                        >
                            Ghim
                        </Tag>
                    ) : null}
                </>
            ),
        },
        {
            title: "Địa chỉ",
            dataIndex: "shortAddress",
            width: "20%",
            render: (address) => (
                <Paragraph ellipsis={{ rows: 2, tooltip: address }}>
                    {address}
                </Paragraph>
            ),
        },
        {
            title: "Danh mục",
            dataIndex: "subCategories",
            width: "15%",
            render: (subCategories) =>
                subCategories.map((e) => <Tag key={e._id}>{e.name}</Tag>),
        },
        {
            title: "Nổi bật",
            // dataIndex: "isOpen",
            align: "center",
            width: "10%",
            render: (record) => (
                <Switch
                    size="default"
                    onChange={(checked) => handleToggleHot(record._id, checked)}
                    checked={record.isHot}
                />
            ),
        },
        {
            title: "Trạng thái",
            dataIndex: "active",
            align: "center",
            width: "10%",
            render: (active) =>
                active ? (
                    <Tag color="#4caf50">Active</Tag>
                ) : (
                    <Tag color="#f44336">In-active</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: (record) => (
                <Actions record={record} onEdit={onOpenDetailModal} />
            ),
        },
    ];
    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Địa điểm dịch vụ
                </Typography.Title>

                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Tìm theo danh mục con"
                        onChange={onChangeSearchSubCategory}
                    >
                        {subCategoriesOption}
                    </Select>
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                        style={{ width: "240px" }}
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Row justify="end" align="middle">
                <Space size="middle">
                    <Switch
                        checkedChildren="Nổi bật"
                        unCheckedChildren="Nổi bật"
                        onChange={handleSearchIsHot}
                    />
                    <Switch
                        checkedChildren="Đã duyệt"
                        unCheckedChildren="Đã duyệt"
                        onChange={handleSearchIsVerified}
                    />
                    <Switch
                        checkedChildren="Đã ghim"
                        unCheckedChildren="Đã ghim"
                        onChange={handleSearchIsPinned}
                    />
                    <Switch
                        defaultChecked
                        checkedChildren="Đang kích hoạt"
                        unCheckedChildren="Hủy kích hoạt"
                        onChange={handleSearchIsActive}
                    />
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={places}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <PlaceDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCloseDetailModal}
                    place={selectingPlace}
                    subCategories={subCategories}
                />
            )}
        </>
    );
}

export default Place;
