import { Form, Input, Tag, Typography } from "antd";
const { Paragraph } = Typography;

export default function TabAccount({ user }) {
    return (
        <>
            <Form.Item name="deviceId" label="Mã thiết bị">
                <Tag>{user.deviceId || "Chưa cập nhật"}</Tag>
            </Form.Item>
            <Form.Item name="appToken" label="FCM Token">
                {user?.appTokens?.map((token) => (
                    <Paragraph copyable key={token}>
                        {token}
                    </Paragraph>
                )) || null}
            </Form.Item>
        </>
    );
}
