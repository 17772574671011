import { createSlice } from "@reduxjs/toolkit";
import adApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    ads: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const adSlice = createSlice({
    name: "food-ad",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setAds: (state, action) => {
            state.ads = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addAd: (state, action) => {
            state.isCompleted = true;
            state.ads.unshift(action.payload);
        },
        editAd: (state, action) => {
            state.isCompleted = true;
            state.ads = state.ads.map((ad) =>
                ad._id === action.payload._id ? action.payload : ad
            );
        },
        removeAd: (state, action) => {
            state.isCompleted = true;
            state.ads = state.ads.filter((ad) => ad._id !== action.payload);
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setAds,
    setPagination,
    addAd,
    editAd,
    removeAd,
} = adSlice.actions;

export const fetchAds = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await adApi.getAll(params);
            dispatch(setAds(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createAd = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await adApi.create(formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addAd(data));
                toast.success("Thêm quảng cáo mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateAd = (ad, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await adApi.update(ad._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editAd(data));
                toast.success("Cập nhật quảng cáo thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteAd = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await adApi.delete(id);
            if (data.success) {
                dispatch(removeAd(id));
                toast.success("Xóa quảng cáo thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa quảng cáo thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default adSlice.reducer;
