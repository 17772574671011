import axiosClient from "utils/api/axios-client";

const driverApi = {
    getAll: (params) => {
        const url = "/api/v1/tuktuk/drivers";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/tuktuk/drivers/${id}`;
        return axiosClient.get(url);
    },
    create: (driver) => {
        const url = `/api/v1/tuktuk/drivers/`;
        return axiosClient.post(url, driver);
    },
    update: (driver) => {
        const url = `/api/v1/tuktuk/drivers/${driver._id}`;
        return axiosClient.put(url, driver);
    },
    delete: (id) => {
        const url = `/api/v1/tuktuk/drivers/${id}`;
        return axiosClient.delete(url);
    },
};

export default driverApi;
