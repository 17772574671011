import { Form, Modal, Tabs } from "antd";
import { getPhotoThumbnailUrl } from "utils/common";
import TabAccount from "./TabAccount";
import TabInfo from "./TabInfo";

function ReviewDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onCancel, user } = props;
    const imageUrl = getPhotoThumbnailUrl(user.avatar?.path || null);

    const items = [
        {
            key: "info",
            label: "Thông tin",
            forceRender: true,
            children: <TabInfo user={user} imageUrl={imageUrl} />,
        },
        {
            key: "account",
            label: "Thiết bị",
            forceRender: true,
            children: <TabAccount user={user} />,
        },
    ];

    return (
        <Modal
            title="Người dùng"
            cancelText="Đóng"
            centered
            open={open}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={user}>
                <Tabs defaultActiveKey="info" items={items} />
            </Form>
        </Modal>
    );
}

export default ReviewDetailModal;
