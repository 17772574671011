import { PlusOutlined } from "@ant-design/icons";
import {
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    TimePicker,
    Upload,
} from "antd";
import DebounceSelect from "components/common/DebounceSelect";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import {
    formatTime,
    getBase64,
    getPhotoUrl,
    getSlug,
    parseTime,
} from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

const { TextArea } = Input;

function CampaignDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, campaign } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoUrl(campaign?.image?.path || null, "w=200&h=102")
    );
    const [image, setImage] = useState(null);

    const initialValues = campaign
        ? {
              ...campaign,
              startTime: campaign.startTime ?? 0,
              endTime: campaign.endTime ?? 1439,
              restaurants: campaign.restaurants.map((e) => ({
                  key: e._id,
                  label: e.name,
                  value: e._id,
              })),
              time: [
                  dayjs(parseTime(campaign.startTime ?? 0), "HH:mm"),
                  dayjs(parseTime(campaign.endTime ?? 1439), "HH:mm"),
              ],
          }
        : {
              name: "",
              slug: "",
              startTime: 0,
              endTime: 1439,
              priority: 99,
              inHome: false,
              active: true,
              time: [dayjs("00:00", "HH:mm"), dayjs("23:59", "HH:mm")],
          };
    const onValuesChange = (changedValues) => {
        if (changedValues.name) {
            form.setFieldValue("slug", getSlug(changedValues.name));
        } else if (changedValues.time) {
            form.setFieldValue(
                "startTime",
                formatTime(changedValues.time[0].format("HH:mm"))
            );
            form.setFieldValue(
                "endTime",
                formatTime(changedValues.time[1].format("HH:mm"))
            );
        }
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const campaign = { ...initialValues, ...values };
                if (campaign.restaurants) {
                    campaign.restaurants = campaign.restaurants.map(
                        (e) => e.value
                    );
                }
                Object.entries({
                    data: JSON.stringify(campaign),
                    image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                delete campaign.time;

                onSubmit(campaign, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Chiến dịch"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={800}
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
            >
                <Form.Item name="startTime" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="endTime" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={14}>
                        <Form.Item
                            name="name"
                            label="Tên chiến dịch"
                            rules={[
                                {
                                    required: true,
                                    message: "Trường này là bắt buộc",
                                },
                            ]}
                            labelCol={{
                                xs: { span: 24 },
                                sm: { span: 7 },
                            }}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 17 },
                            }}
                        >
                            <Input placeholder="Tên chiến dịch" />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="slug"
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                            label={false}
                        >
                            <Input disabled placeholder="Slug" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="title"
                    label="Mô tả ngắn"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Mô tả ngắn chiến dịch" />
                </Form.Item>
                <Form.Item
                    name="content"
                    label="Nội dung"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        placeholder="Nội dung chi tiết của chiến dịch"
                    />
                </Form.Item>
                <Form.Item
                    name="restaurants"
                    label="DS quán"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <DebounceSelect
                        mode="multiple"
                        placeholder="Chọn danh sách quán thuộc chiến dịch"
                        initOptions={
                            campaign?.restaurants
                                ? campaign.restaurants.map((e) => ({
                                      key: e._id,
                                      label: e.name,
                                      value: e._id,
                                  }))
                                : []
                        }
                        fetchOptions={commonApi.searchRestaurant}
                        convertOptions={(data) =>
                            data.map((res) => ({
                                key: res._id,
                                label: res.name,
                                value: res._id,
                            }))
                        }
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>

                <Form.Item
                    name="time"
                    label="Khung giờ"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <TimePicker.RangePicker
                        style={{ width: "100%" }}
                        placeholder={["Từ", "Đến"]}
                        format="HH:mm"
                    />
                </Form.Item>
                <Form.Item label="Hình ảnh">
                    <Upload
                        accept={"image/*"}
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader-cover"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item
                    name="active"
                    label="Trạng thái"
                    rules={[{ required: true }]}
                >
                    <Select placeholder="--Trạng thái--">
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>In-active</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Tuỳ chọn" style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="inHome"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch
                            checkedChildren="Hiển thị trên trang chủ"
                            unCheckedChildren="Hiển thị trên trang chủ"
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
}

CampaignDetailModal.propTypes = {
    open: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    campaign: PropTypes.object,
};

CampaignDetailModal.defaultProps = {
    isProcessing: false,
    open: false,
    campaign: null,
};

export default CampaignDetailModal;
