import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select } from "antd";
import { ADMIN_ROLES, formItemLayoutHorizontal } from "utils/constants";

const { Option } = Select;

function AccountDetailModal({
    open,
    isProcessing,
    onSubmit,
    onCancel,
    onResetPassword,
    account,
}) {
    const [form] = Form.useForm();

    const initialValues = account || {
        name: "",
        username: "",
        password: "",
        role: ADMIN_ROLES.CUSTOMER_SERVICE,
        active: true,
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => onSubmit({ ...initialValues, ...values }))
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Tài khoản"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={720}
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Tên đầy đủ" />
                </Form.Item>
                <Form.Item
                    name="username"
                    label="Tên đăng nhập"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input
                        disabled={Boolean(account)}
                        placeholder="Tên người dùng"
                    />
                </Form.Item>
                {!account && (
                    <Form.Item
                        name="password"
                        label="Mật khẩu"
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                    >
                        <Input type="password" placeholder="Mật khẩu" />
                    </Form.Item>
                )}

                <Form.Item name="role" label="Quyền">
                    <Select>
                        <Option value={ADMIN_ROLES.SUPER_ADMIN}>
                            Super Admin
                        </Option>
                        <Option value={ADMIN_ROLES.ADMIN}>Admin</Option>
                        <Option value={ADMIN_ROLES.CUSTOMER_SERVICE}>
                            Customer Service
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item name="active" label="Trạng thái">
                    <Select>
                        <Option value={true}>Active</Option>
                        <Option value={false}>In-active</Option>
                    </Select>
                </Form.Item>
                {account && (
                    <div className="text-right">
                        <Button
                            type="text"
                            onClick={() => onResetPassword(account)}
                            danger
                            className="cursor-pointer"
                        >
                            <LockOutlined /> Reset mật khẩu
                        </Button>
                    </div>
                )}
            </Form>
        </Modal>
    );
}

export default AccountDetailModal;
