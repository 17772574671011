import { Input, Modal, Typography } from "antd";
import { useState } from "react";

export default function ConfirmCancelModal({
    open,
    onClose,
    onSubmit,
    order,
    loading,
}) {
    const [cancelReason, setCancelReason] = useState("");
    return (
        <>
            <Modal
                open={open}
                onCancel={onClose}
                onOk={() => cancelReason && onSubmit(order, cancelReason)}
                title="Hủy đơn hàng"
                confirmLoading={loading}
            >
                <Typography.Paragraph>
                    Bạn có chắc chắn muốn hủy đơn hàng này không?
                </Typography.Paragraph>
                <Input
                    placeholder="Lý do hủy"
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                />
            </Modal>
        </>
    );
}
