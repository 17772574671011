import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Switch, Upload } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

const MODEL_TYPE_VALUES = {
    NONE: "None",
    MODULE: "Module",
    SERVICE_PLACE: "ServicePlace",
    SERVICE_SUB_CATEGORY: "ServiceSubCategory",
};

const MODEL_TYPE_LABELS = {
    NONE: "Không",
    MODULE: "Module",
    SERVICE_PLACE: "Điểm dịch vụ",
    SERVICE_SUB_CATEGORY: "Loại hình dịch vụ",
    FOOD_RESTAURANT: "Quán ăn",
    FOOD_CAMPAIGN: "Chiến dịch đồ ăn",
};

const MODEL_TYPES = [
    {
        label: MODEL_TYPE_LABELS.NONE,
        value: MODEL_TYPE_VALUES.NONE,
    },
    {
        label: MODEL_TYPE_LABELS.MODULE,
        value: MODEL_TYPE_VALUES.MODULE,
    },
    {
        label: MODEL_TYPE_LABELS.SERVICE_PLACE,
        value: MODEL_TYPE_VALUES.SERVICE_PLACE,
    },
    {
        label: MODEL_TYPE_LABELS.SERVICE_SUB_CATEGORY,
        value: MODEL_TYPE_VALUES.SERVICE_SUB_CATEGORY,
    },
    {
        label: MODEL_TYPE_LABELS.FOOD_RESTAURANT,
        value: MODEL_TYPE_VALUES.FOOD_RESTAURANT,
    },
    {
        label: MODEL_TYPE_LABELS.FOOD_CAMPAIGN,
        value: MODEL_TYPE_VALUES.FOOD_CAMPAIGN,
    },
];

function BannerDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, banner } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoUrl(banner?.image?.path || null, "w=200&h=102")
    );
    const [image, setImage] = useState(null);
    const [currentDocModel, setCurrentDocModel] = useState(
        banner?.docModel || MODEL_TYPE_VALUES.NONE
    );

    const initialValues = banner
        ? {
              ...banner,
          }
        : {
              name: "",
              docModel: MODEL_TYPE_VALUES.NONE,
              docId: null,
              inHome: false,
              priority: 100,
              url: "",
              openBrowser: false,
          };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const banner = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(banner),
                    image: image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(banner, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onValuesChange = (changedValues) => {
        if (changedValues["docModel"]) {
            form.setFieldValue("docId", null);
            setCurrentDocModel(changedValues["docModel"]);
        }
    };

    const renderFormItemLabelForDoc = (value) => {
        switch (value) {
            case MODEL_TYPE_VALUES.FOOD_RESTAURANT:
                return MODEL_TYPE_LABELS.FOOD_RESTAURANT;
            case MODEL_TYPE_VALUES.FOOD_CAMPAIGN:
                return MODEL_TYPE_LABELS.FOOD_CAMPAIGN;
            case MODEL_TYPE_VALUES.SERVICE_SUB_CATEGORY:
                return MODEL_TYPE_LABELS.SERVICE_SUB_CATEGORY;
            case MODEL_TYPE_VALUES.SERVICE_PLACE:
                return MODEL_TYPE_LABELS.SERVICE_PLACE;
            case MODEL_TYPE_VALUES.MODULE:
                return MODEL_TYPE_LABELS.MODULE;
            case MODEL_TYPE_VALUES.NONE:
                return MODEL_TYPE_LABELS.NONE;
            default:
                return "Không rõ";
        }
    };

    return (
        <Modal
            title="Banner"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="40%"
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Ảnh">
                    <Upload
                        accept={"image/*"}
                        name="banner"
                        listType="picture-card"
                        className="avatar-uploader-cover"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="docModel" label="Đối tượng">
                    <Select>
                        {MODEL_TYPES.map((type, index) => (
                            <Select.Option key={index} value={type.value}>
                                {type.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="docId"
                    label={`Id ${renderFormItemLabelForDoc(currentDocModel)}`}
                    hidden={currentDocModel === MODEL_TYPE_VALUES.NONE}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Tuỳ chọn" style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="inHome"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch
                            checkedChildren="Hiển thị trên trang chủ"
                            unCheckedChildren="Hiển thị trên trang chủ"
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name="url"
                    label="URL"
                    tooltip="Nếu có sẽ hiển thị webview khi người dúng ấn vào và bỏ qua đối tượng phía trên"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mở trình duyệt"
                    name="openBrowser"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default BannerDetailModal;
