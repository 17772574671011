import axiosClient from "utils/api/axios-client";

const comboTransactionApi = {
    getAll: (params) => {
        const url = "/api/v1/tuktuk/combo-transactions";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/tuktuk/combo-transactions/${id}`;
        return axiosClient.get(url);
    },
    create: (transaction) => {
        const url = `/api/v1/tuktuk/combo-transactions`;
        return axiosClient.post(url, transaction);
    },
    updateStatus: (transaction) => {
        const url = `/api/v1/tuktuk/combo-transactions/${transaction._id}/status`;
        return axiosClient.put(url, transaction);
    },
};

export default comboTransactionApi;
