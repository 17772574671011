import { Form, Image, Input, Modal, Rate, Space, Tag } from "antd";
import { getPhotoThumbnailUrl } from "utils/common";

function ReviewDetailModal(props) {
    const [form] = Form.useForm();
    const { open, onCancel, review } = props;

    const imageUrl = getPhotoThumbnailUrl(review?.image?.path || null);

    return (
        <Modal
            title="Đánh giá quán"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            okButtonProps={{ className: "hidden" }}
            onCancel={onCancel}
        >
            <Form layout="vertical" form={form} initialValues={review}>
                <Form.Item name={["order", "code"]} label="Đơn hàng">
                    <Input readOnly />
                </Form.Item>
                <Form.Item name="author" label="Tác giả">
                    <Input readOnly />
                </Form.Item>
                <Form.Item name="star" label="Đánh giá">
                    <Rate disabled />
                </Form.Item>
                <Form.Item name="content" label="Nội dung">
                    <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        readOnly
                    />
                </Form.Item>
                <Form.Item name={["restaurant", "name"]} label="Quán">
                    <Input readOnly />
                </Form.Item>
                <Space direction="vertical" style={{ marginBottom: "16px" }}>
                    <label>Danh sách món ăn</label>
                    <Space wrap={true}>
                        {review.dishes.map((dish, index) => (
                            <Tag key={index}>{dish}</Tag>
                        ))}
                    </Space>
                </Space>
                <Form.Item label="Hình ảnh">
                    <Image src={imageUrl} alt="image of the review" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ReviewDetailModal;
