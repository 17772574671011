import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Switch, Upload } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoThumbnailUrl } from "utils/common";

const { TextArea } = Input;

function ModuleDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, module } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoThumbnailUrl(module?.image?.path || null)
    );
    const [image, setImage] = useState(null);

    const initialValues = module || {
        name: "",
        code: "",
        tag: "",
        priority: 99,
        inHome: false,
        isAvailable: false,
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const module = { ...initialValues, ...values };
                formData.append("data", JSON.stringify(module));
                formData.append("image", image);
                onSubmit(module, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Module"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <TextArea
                        placeholder="Tên module"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                </Form.Item>
                <Form.Item
                    name="code"
                    label="Code"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Code" />
                </Form.Item>
                <Form.Item name="tag" label="Tag">
                    <Input placeholder="Tag" />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input placeholder="Thứ tự ưu tiên" />
                </Form.Item>
                <Form.Item
                    name="inHome"
                    valuePropName="checked"
                    style={{
                        display: "inline-block",
                        marginRight: "20px",
                    }}
                >
                    <Switch
                        checkedChildren="Hiển thị trên trang chủ"
                        unCheckedChildren="Hiển thị trên trang chủ"
                    />
                </Form.Item>
                <Form.Item
                    name="isAvailable"
                    valuePropName="checked"
                    style={{
                        display: "inline-block",
                    }}
                >
                    <Switch
                        checkedChildren="Có thể sử dụng"
                        unCheckedChildren="Chưa thể sử dụng"
                    />
                </Form.Item>
                <Form.Item label="Hình ảnh">
                    <Upload
                        accept={"image/*"}
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ModuleDetailModal;
