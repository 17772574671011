import { useState } from "react";

export default function useViewDetail(onCloseCallback = () => {}) {
    const [detail, setDetail] = useState(null);
    const [open, setOpen] = useState(false);

    const onOpen = (_detail = null) => {
        setDetail(_detail);
        setOpen(true);
    };

    const onClose = () => {
        setDetail(null);
        setOpen(false);
        onCloseCallback();
    };

    return {
        detail,
        open,
        onOpen,
        onClose,
    };
}
