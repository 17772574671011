import { configureStore } from "@reduxjs/toolkit";

import auth from "features/auth/slice";

import module from "features/module/slice";
import banner from "features/banner/slice";
import building from "features/building/slice";
import account from "features/account/slice";
import user from "features/user/slice";
import walletRequest from "features/wallet-request/slice";

import tuktukRequest from "features/tuktuk/request/slice";
import tuktukOrder from "features/tuktuk/order/slice";
import tuktukBanner from "features/tuktuk/banner/slice";
import tuktukDriver from "features/tuktuk/driver/slice";
import tuktukCombo from "features/tuktuk/combo/slice";
import tuktukComboTransaction from "features/tuktuk/combo-transaction/slice";
import tuktukPlace from "features/tuktuk/place/slice";
import tuktukConfig from "features/tuktuk/config/slice";

import newsPost from "features/news/post/slice";
import newsPendingPost from "features/news/verify/slice";

import liquidationCategory from "features/liquidation/category/slice";
import liquidationPost from "features/liquidation/post/slice";
import liquidationPendingPost from "features/liquidation/verify/slice";
import liquidationHotKeyword from "features/liquidation/hot-keyword/slice";

import marketCategory from "features/market/category/slice";
import marketPost from "features/market/post/slice";
import marketPendingPost from "features/market/verify/slice";
import marketHotKeyword from "features/market/hot-keyword/slice";

import serviceCategory from "features/service/category/slice";
import serviceSubCategory from "features/service/sub-category/slice";
import servicePlace from "features/service/place/slice";
import serviceBanner from "features/service/banner/slice";
import serviceHotKeyword from "features/service/hot-keyword/slice";

import foodCategory from "features/food/category/slice";
import foodCampaign from "features/food/campaign/slice";
import foodCollection from "features/food/collection/slice";
import foodRestaurant from "features/food/restaurant/slice";
import foodBannerUser from "features/food/banner/app-user/slice";
import foodBannerMerchant from "features/food/banner/app-merchant/slice";
import foodAd from "features/food/ad/list/slice";
import foodAdType from "features/food/ad/type/slice";
import foodVoucher from "features/food/voucher/slice";
import foodReview from "features/food/review/slice";
import foodOrder from "features/food/order/history/slice";
import foodNewOrder from "features/food/order/new/slice";
import foodRestaurantLabel from "features/food/restaurant-label/slice";
import foodHotKeyword from "features/food/setup/hot-keyword/slice";
import foodConfig from "features/food/setup/general/slice";

import deliveryOrder from "features/delivery/order/slice";
import deliveryShipper from "features/delivery/shipper/slice";
import deliveryConfig from "features/delivery/config/slice";

import transportDriver from "features/transport/driver/slice";
import transportOrder from "features/transport/order/slice";

export const store = configureStore({
    reducer: {
        auth,
        module,
        banner,
        building,
        account,
        user,
        walletRequest,

        tuktukRequest,
        tuktukOrder,
        tuktukBanner,
        tuktukDriver,
        tuktukCombo,
        tuktukComboTransaction,
        tuktukPlace,
        tuktukConfig,

        newsPost,
        newsPendingPost,

        liquidationCategory,
        liquidationPost,
        liquidationPendingPost,
        liquidationHotKeyword,

        marketCategory,
        marketPost,
        marketPendingPost,
        marketHotKeyword,

        serviceCategory,
        serviceSubCategory,
        servicePlace,
        serviceBanner,
        serviceHotKeyword,

        foodCategory,
        foodCampaign,
        foodCollection,
        foodRestaurant,
        foodBannerUser,
        foodBannerMerchant,
        foodAd,
        foodAdType,
        foodVoucher,
        foodReview,
        foodOrder,
        foodNewOrder,
        foodHotKeyword,
        foodRestaurantLabel,
        foodConfig,

        deliveryOrder,
        deliveryShipper,
        deliveryConfig,

        transportDriver,
        transportOrder,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
