import { Button, Collapse } from "antd";
import { priceToCurrency } from "utils/common";

export default function DishOptionItem({ data, onEdit, onDelete }) {
    return (
        <>
            <Collapse>
                <Collapse.Panel
                    key="panel"
                    showArrow={false}
                    collapsible="header"
                    header={
                        <div className="w-full flex flex-row items-center justify-between">
                            <div>
                                <div>
                                    <span>
                                        <b>
                                            {data.name} ({data.options.length}{" "}
                                            tùy chọn)
                                        </b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {data.isSingleChoice
                                            ? "Chọn 1"
                                            : "Chọn nhiều"}
                                        ,{" "}
                                        {data.isRequired
                                            ? "Bắt buộc"
                                            : "Không bắt buộc"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    extra={
                        <div className="flex h-full items-center">
                            <Button type="link" onClick={() => onEdit(data)}>
                                Sửa
                            </Button>
                            <Button
                                type="link"
                                danger
                                onClick={() => onDelete(data)}
                            >
                                Xóa
                            </Button>
                        </div>
                    }
                >
                    {data.options.map((option) => (
                        <p key={option._id}>
                            {option.name} - {priceToCurrency(option.price)} -{" "}
                            {option.isAvailable ? "Có sẵn" : "Không khả dụng"}
                        </p>
                    ))}
                </Collapse.Panel>
            </Collapse>
        </>
    );
}
