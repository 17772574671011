import { Divider, Row, Select, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_STATUS } from "utils/constants";
import Actions from "./components/Actions";
import ChangeStatusModal from "./components/ChangeStatus";
import * as orderActions from "./slice";

function Order() {
    const dispatch = useDispatch();
    const { orders, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.deliveryOrder);

    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectingOrder, setSelectingOrder] = useState(null);
    const [searchStatus, setSearchStatus] = useState("");

    useEffect(() => {
        dispatch(orderActions.fetchOrders(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenChangeStatusModal(false);
            setSelectingOrder(null);
        }
    }, [isCompleted]);

    useEffect(() => {
        onSearch(searchStatus);
    }, [searchStatus]);

    const onOpenChangeStatus = (order) => {
        setSelectingOrder(order);
        setOpenChangeStatusModal(true);
    };

    const onChangeStatus = (order) => {
        dispatch(orderActions.updateStatus(order));
    };

    const onCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
        setSelectingOrder(null);
        dispatch(orderActions.setIsProcessing(false));
    };

    const onChangeStatusSearch = (value) => {
        setSearchStatus(value);
    };

    const onSearch = (status) => {
        const params = { ...pagination };
        if (status) {
            params.status = status;
        }
        dispatch(orderActions.fetchOrders(params));
    };

    const onTableChange = async (pagination) => {
        dispatch(
            orderActions.fetchOrders({
                ...pagination,
                status: searchStatus,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Khách hàng",
            dataIndex: "user",
            render: (user) => (
                <>
                    <Typography.Text>{user.name}</Typography.Text>
                    <Typography.Paragraph>{user.phone}</Typography.Paragraph>
                </>
            ),
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
        },
        {
            title: "Yêu cầu",
            dataIndex: "content",
            width: "20%",
            render: (content) => (
                <div style={{ whiteSpace: "pre-line" }}>
                    <Typography.Paragraph
                        ellipsis={{
                            rows: 4,
                            expandable: true,
                            symbol: "Xem thêm",
                        }}
                    >
                        {content}
                    </Typography.Paragraph>
                </div>
            ),
        },
        {
            title: "Dịch vụ",
            dataIndex: "type",
            render: (type) => (type === "delivery" ? "Giao hàng" : "Mua hộ"),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (status) => (
                <Tag color={REQUEST_STATUS[status]?.color || "default"}>
                    {REQUEST_STATUS[status]?.label || "Không xác định"}
                </Tag>
            ),
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Thao tác",
            align: "center",
            render: (record) => (
                <Actions record={record} onEdit={onOpenChangeStatus} />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Đơn hàng
                </Typography.Title>
                <Space size="middle">
                    <label className="font-semibold">Trạng thái:</label>
                    <Select
                        onChange={onChangeStatusSearch}
                        value={searchStatus}
                        options={[
                            {
                                value: "",
                                label: "Tất cả",
                            },
                            ...Object.values(REQUEST_STATUS),
                        ]}
                        className="w-60"
                    />
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={orders}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openChangeStatusModal && (
                <ChangeStatusModal
                    open={openChangeStatusModal}
                    onCancel={onCloseChangeStatusModal}
                    comboTransaction={selectingOrder}
                    onSubmit={onChangeStatus}
                    isProcessing={isProcessing}
                />
            )}
        </>
    );
}

export default Order;
