import axiosClient from "utils/api/axios-client";

const pendingPostApi = {
    getAll: (params) => {
        const url = "/api/v1/liquidation/posts";
        return axiosClient.get(url, {
            params,
        });
    },
    verify: (id) => {
        const url = `/api/v1/liquidation/posts/${id}/verify`;
        return axiosClient.put(url);
    },
    reject: (id, rejectReason) => {
        const url = `/api/v1/liquidation/posts/${id}/reject`;
        return axiosClient.put(url, { rejectReason });
    },
};

export default pendingPostApi;
