import hotKeywordApi from "./api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialHotKeyword = {
    hotKeywords: [],
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

const hotKeyword = createSlice({
    name: "food-hotKeyword",
    initialState: initialHotKeyword,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            state.isProcessing = action.payload;
        },
        setHotKeywords: (state, action) => {
            state.hotKeywords = action.payload;
        },
        removeHotKeywords: (state, action) => {
            state.hotKeywords = state.hotKeywords.filter(
                (keyword) => keyword !== action.payload
            );
        },
    },
});

const { reducer, actions } = hotKeyword;

export const {
    setIsLoading,
    setIsProcessing,
    setHotKeywords,
    removeHotKeywords,
} = actions;

export const fetchHotKeywords = () => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await hotKeywordApi.getHotKeywords();
            dispatch(setHotKeywords(data.data));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const updateHotKeywords = (hotKeywords) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await hotKeywordApi.update(hotKeywords);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(setHotKeywords(data.data));
                toast.success("Thiết lập danh sách hot keyword thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default reducer;
