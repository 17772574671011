import axiosClient from "utils/api/axios-client";

const campaignApi = {
    getAll: (params) => {
        const url = "/api/v1/food/campaigns";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/food/campaigns/${id}`;
        return axiosClient.get(url);
    },
    create: (campaign) => {
        const url = `/api/v1/food/campaigns/`;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        return axiosClient.post(url, campaign, config);
    },
    update: (id, campaign) => {
        const url = `/api/v1/food/campaigns/${id}`;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        return axiosClient.put(url, campaign, config);
    },
    delete: (id) => {
        const url = `/api/v1/food/campaigns/${id}`;
        return axiosClient.delete(url);
    },
};

export default campaignApi;
