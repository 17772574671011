import { Button, Divider, Form, Input, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import { fetchConfig, updateConfig } from "./slice";

export default function FeeCharge() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { config, isLoading } = useSelector((state) => state.tuktukConfig);
    const [banks, setBanks] = useState([]);

    useEffect(() => {
        const getBanks = async () => {
            const data = await commonApi.getBanks();
            if (!data || data.error) {
                toast.error(data.error.msg);
            } else {
                setBanks(data);
            }
        };
        dispatch(fetchConfig());
        getBanks();
    }, [dispatch]);

    useEffect(() => {
        form.setFieldsValue(config);
    }, [config]);

    const initialValues = {
        paymentMethod: {
            tooltip: "",
            codAvailable: false,
            codNote: "Không hỗ trợ",
            walletAvailable: false,
            walletNote: "Thanh toán bằng ví CUZI",
            qrCodeAvailable: true,
            qrCodeNote: "Thanh toán bằng mã QR",
            atmAvailable: false,
            atmNote: "Đang bảo trì",
        },
        banking: {
            bankId: "",
            accountNumber: "",
            accountName: "",
        },
    };

    const onFinish = (config) => {
        dispatch(updateConfig({ ...config }));
    };

    return (
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 1000 }}
            initialValues={initialValues}
            autoComplete="off"
            onFinish={onFinish}
        >
            <Divider orientation="left">
                Thiết lập phương thức thanh toán
            </Divider>
            <Form.Item
                label="Ghi chú tooltip"
                name={["paymentMethod", "tooltip"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thanh toán tiền mặt"
                name={["paymentMethod", "codAvailable"]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Mô tả tiền mặt"
                name={["paymentMethod", "codNote"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thanh toán ví"
                name={["paymentMethod", "walletAvailable"]}
            >
                <Switch />
            </Form.Item>
            <Form.Item label="Mô tả ví" name={["paymentMethod", "walletNote"]}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                label="Thanh toán QR code"
                name={["paymentMethod", "qrCodeAvailable"]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Mô tả QR code"
                name={["paymentMethod", "qrCodeNote"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thanh toán ATM"
                name={["paymentMethod", "atmAvailable"]}
            >
                <Switch defaultChecked={false} disabled />
            </Form.Item>
            <Form.Item label="Mô tả ATM" name={["paymentMethod", "atmNote"]}>
                <Input disabled />
            </Form.Item>
            <br />
            <Divider orientation="left">Tài khoản ngân hàng</Divider>
            <Form.Item name={["banking", "bankId"]} label="Ngân hàng">
                <Select
                    showSearch
                    optionFilterProp="label"
                    options={banks.map((bank) => ({
                        value: bank.id,
                        label: bank.name,
                    }))}
                ></Select>
            </Form.Item>
            <Form.Item name={["banking", "accountNumber"]} label="Số tài khoản">
                <Input />
            </Form.Item>
            <Form.Item name={["banking", "accountName"]} label="Chủ tài khoản">
                <Input />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Cập nhật
                </Button>
            </Form.Item>
        </Form>
    );
}
