import { Col, Descriptions, Drawer, Row, Typography } from "antd";
import { flatten } from "flat";
import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";
import { priceToCurrency } from "utils/common";
import orderApi from "../api";

function OrderDetailDrawer(props) {
    const { open, onClose, order } = props;

    const [detail, setDetail] = useState(null);

    useEffect(() => {
        const getOrderCost = async () => {
            const data = await orderApi.getCost(order._id);
            setDetail({ ...flatten(data) });
        };
        getOrderCost();
    }, [order]);

    const detailFields = [
        {
            key: "code",
            label: "Mã đơn",
            isNotCurrency: true,
            labelStyle: {
                fontSize: "18px",
            },
            contentStyle: {
                fontSize: "18px",
            },
        },
        {
            key: "dishCount",
            label: "Số món",
            isNotCurrency: true,
        },
        {
            key: "distance",
            label: "Khoảng cách",
            render: (detailOrder) => `${detailOrder.distance}km`,
        },
        {
            key: "dishFeeActual",
            label: "Tổng giá món",
        },
        {
            key: "dishFee",
            label: "Tổng giá món sau giảm giá",
        },
        {
            key: "deliveryFeeActual",
            label: "Phí ship",
        },
        {
            key: "deliveryFee",
            label: "Phí ship sau giảm giá",
        },
        {
            key: "serviceFee",
            label: "Phí dịch vụ",
        },
        {
            key: "discount",
            label: "Giảm giá",
        },
        {
            key: "discountObj",
            label: "Đối tượng giảm giá",
            isNotCurrency: true,
        },
        {
            key: "discountRestaurantPaid",
            label: "Số tiền giảm giá quán chịu",
        },
        {
            key: "discountAppPaid",
            label: "Số tiền giảm giá app chịu",
        },
        {
            key: "totalActual",
            label: "Tổng giá trị đơn hàng ban đầu",
        },
        {
            key: "total",
            label: "Tổng giá trị đơn hàng sau giảm giá",
        },
        {
            key: "totalRestaurantFee",
            label: "Số tiền shipper trả quán",
        },
        {
            key: "totalUserFee",
            label: "Số tiền shipper thu khách",
        },
        {
            key: "restaurantCharge",
            label: "% chiết khấu quán",
            render: (detail) => `${detail.restaurantCharge}%`,
        },
        {
            key: "restaurantReceiveActual",
            label: "Số tiền quán thực tế nhận được",
        },
        {
            key: "restaurantTransValue",
            label: "Số tiền cần cộng trừ vào ví quán",
        },
        {
            key: "shipperCharge",
            label: "% chiết khấu shipper",
            render: (detail) => `${detail.shipperCharge}%`,
        },
        {
            key: "shipperReceiveActual",
            label: "Số tiền shipper thực tế nhận được",
        },
        {
            key: "shipperTransValue",
            label: "Số tiền cần cộng trừ vào ví shipper",
        },
        {
            key: "appReceiveActual",
            label: "Số tiền app thực tế nhận được",
        },
        {
            key: "deliveryExtraActive",
            label: "Chế độ giờ cao điểm",
            render: (detailOrder) =>
                detailOrder.deliveryExtraActive ? "Có" : "Không",
        },
        {
            key: "deliveryExtraPercent",
            label: "% tăng phí ship giờ cao điểm",
            render: (detailOrder) => `${detailOrder.deliveryExtraPercent}%`,
        },
        {
            key: "paymentMethod",
            label: "Phương thức thanh toán",
            isNotCurrency: true,
        },
    ];

    return (
        detail && (
            <Drawer
                title="Chi tiết phí"
                placement="right"
                onClose={onClose}
                open={open}
                width={500}
            >
                <Descriptions bordered>
                    {detailFields.map((field) => (
                        <Descriptions.Item
                            label={field.label}
                            key={field.key}
                            span={24}
                            labelStyle={{
                                ...(field.labelStyle || {}),
                                fontWeight: 600,
                            }}
                            contentStyle={{ ...(field.contentStyle || {}) }}
                        >
                            {field.render
                                ? field.render(detail)
                                : field.isNotCurrency
                                ? detail[field.key]
                                : priceToCurrency(detail[field.key])}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
            </Drawer>
        )
    );
}

export default OrderDetailDrawer;
