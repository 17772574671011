import {
    DownOutlined,
    EyeOutlined,
    LockOutlined,
    UnlockOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({ record, onView, onActivate }) {
    const KEY_ACTIONS = {
        VIEW: "view",
        ACTIVATE: "activate",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.VIEW:
                onView(record);
                break;
            case KEY_ACTIONS.ACTIVATE:
                onActivate(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.VIEW,
            label: "Xem thông tin",
            icon: <EyeOutlined />,
        },
        {
            danger: record.active,
            key: KEY_ACTIONS.ACTIVATE,
            label: `${record.active ? "Hủy kích hoạt" : "Kích hoạt"}`,
            icon: record.active ? <LockOutlined /> : <UnlockOutlined />,
        },
    ];

    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
