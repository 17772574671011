import axiosClient from "utils/api/axios-client";

const reviewApi = {
    getAllRestaurant: (params) => {
        const url = "/api/v1/food/restaurant-reviews";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/food/reviews/${id}`;
        return axiosClient.get(url);
    },
    update: (review) => {
        const url = `/api/v1/food/reviews/${review._id}`;
        return axiosClient.put(url, review);
    },
    updateHiddenStatus: (id, hiddenReason) => {
        const url = `/api/v1/food/reviews/${id}/hidden`;
        return axiosClient.put(url, { hiddenReason });
    },
    delete: (id) => {
        const url = `/api/v1/food/reviews/${id}`;
        return axiosClient.delete(url);
    },
};

export default reviewApi;
