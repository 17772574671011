import { Button, Col, Divider, Form, Row, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHotKeywords, updateHotKeywords, setHotKeywords } from "./slice";
import { debounce } from "lodash";

export default function HotKeywords() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { hotKeywords, isLoading } = useSelector(
        (state) => state.foodHotKeyword
    );
    const [keywords, setKeywords] = useState("");
    const [listHotKeyword, setListHotKeyword] = useState([]);

    useEffect(() => {
        dispatch(fetchHotKeywords());
    }, [dispatch]);

    useEffect(() => {
        setKeywords(hotKeywords?.join(", "));
        setListHotKeyword(hotKeywords);
    }, [hotKeywords]);

    const onChangeKeywords = (e) => {
        const newKeywords = e.target.value;
        setKeywords(newKeywords);
        debouncedChangeKeywords(newKeywords);
    };
    const debouncedChangeKeywords = debounce((keywords) => {
        const kws = keywords
            .split(",")
            .map((kw) => kw.trim())
            .filter((kw) => !!kw);
        setListHotKeyword(kws);
    }, 1000);

    const onSubmit = () => {
        const kws = keywords
            .split(",")
            .map((e) => e.trim())
            .filter((e) => !!e);
        dispatch(updateHotKeywords({ keywords: kws }));
    };
    const debounceSubmit = debounce(onSubmit, 1000);

    const handleClose = (removedTag) => {
        const newKws = listHotKeyword.filter((item) => item !== removedTag);
        dispatch(setHotKeywords(newKws));
        setListHotKeyword(newKws);
    };

    return (
        <Row>
            <Col md={12}>
                <Divider orientation="left">Từ khóa tìm kiếm nổi bật</Divider>
                <TextArea
                    placeholder="Điền các keyword cách nhau bởi dấu ,"
                    autoSize={{
                        minRows: 4,
                        maxRows: 10,
                    }}
                    value={keywords}
                    onChange={onChangeKeywords}
                    style={{ maxWidth: "680px" }}
                />
                <br />
                <br />
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    onClick={debounceSubmit}
                >
                    Cập nhật
                </Button>
            </Col>
            <Col md={12}>
                <Divider orientation="left">
                    Danh sách từ khóa tìm kiếm nổi bật
                </Divider>
                <div
                    style={{
                        marginBottom: 16,
                    }}
                >
                    {listHotKeyword?.map((item, index) => (
                        <span
                            key={index}
                            style={{
                                display: "inline-block",
                                marginTop: "10px",
                            }}
                        >
                            <Tag
                                style={{ fontSize: "14px" }}
                                closable
                                onClose={(e) => {
                                    e.preventDefault();
                                    handleClose(item);
                                }}
                            >
                                {item}
                            </Tag>
                        </span>
                    ))}
                </div>
            </Col>
        </Row>
    );
}
