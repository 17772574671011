import { Form, Input, InputNumber, Modal, Segmented, Select } from "antd";
import { toast } from "react-toastify";
import { currencyToPrice, priceToCurrency } from "utils/common";

function WalletRequestDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel } = props;

    const initialValues = {
        type: "RECHARGE",
        creatorModel: "",
        creator: "",
        amount: 0,
        note: "",
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const walletRequest = { ...initialValues, ...values };
                if (
                    walletRequest.creatorModel === "User" &&
                    !walletRequest.note
                ) {
                    toast.error(
                        "Ghi chú là bắt buộc nếu Đối tượng giao dịch là Người dùng"
                    );
                    return;
                } else {
                    onSubmit(walletRequest);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Lệnh nạp/rút ví"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="type"
                    label="Loại giao dịch"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Segmented
                        options={[
                            {
                                label: "Nạp tiền",
                                value: "RECHARGE",
                            },
                            {
                                label: "Rút tiền",
                                value: "WITHDRAW",
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="creatorModel"
                    label="Đối tượng giao dịch"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "Người dùng",
                                value: "User",
                            },
                            {
                                label: "Chủ quán",
                                value: "FoodMerchant",
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="creator"
                    label="ID đối tượng"
                    tooltip="ID của Người dùng hoặc Chủ quán, dựa theo Đối tượng giao dịch đã chọn. Nếu đối tượng đã chọn là Chủ quán thì ID là ID của Quán"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="amount"
                    label="Số tiền"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                        {
                            type: "number",
                            min: 1000,
                            message: "Số tiền tối thiểu là 1.000đ",
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        formatter={(value) => priceToCurrency(value)}
                        parser={(value) => currencyToPrice(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="note"
                    label="Ghi chú"
                    tooltip="Bắt buộc nếu Đối tượng giao dịch là Người dùng vì ghi chú sẽ hiện trên lịch sử ví của Người dùng"
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default WalletRequestDetailModal;
