import { createSlice } from "@reduxjs/toolkit";
import pendingPostApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    posts: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const pendingPostSlice = createSlice({
    name: "news-pending-post",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setPosts: (state, action) => {
            state.posts = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        editPost: (state, action) => {
            state.isCompleted = true;
            state.posts = state.posts.map((post) =>
                post._id === action.payload._id ? action.payload : post
            );
        },
        removePost: (state, action) => {
            state.isCompleted = true;
            state.posts = state.posts.filter(
                (post) => post._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setPosts,
    setPagination,
    editPost,
    removePost,
} = pendingPostSlice.actions;

export const fetchPendingPosts = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await pendingPostApi.getAll(params);
            dispatch(setPosts(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const verifyPost = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await pendingPostApi.verify(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePost(id));
                toast.success("Cập nhật trạng thái bài viết thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const rejectPost = (id, rejectData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await pendingPostApi.reject(id, rejectData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePost(id));
                toast.success("Cập nhật trạng thái bài viết thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default pendingPostSlice.reducer;
