import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Space,
    Table,
    Typography,
    Image,
    Input,
    Modal,
    Row,
    Divider,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import BannerDetailModal from "./components/DetailModal";
import Actions from "./components/Actions";
import * as bannerActions from "./slice";

const { Paragraph } = Typography;

export default function Banner() {
    const { banners, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.banner);
    useEffect(() => {
        dispatch(bannerActions.fetchBanners(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingBanner(null);
        }
    }, [isCompleted]);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingBanner, setSelectingBanner] = useState(null);
    const [searchName, setSearchName] = useState("");

    const dispatch = useDispatch();

    const onTableChange = async (pagination) => {
        dispatch(
            bannerActions.fetchBanners({
                ...pagination,
                name: searchName,
            })
        );
    };

    const onSubmit = (banner, formData) => {
        if (!banner._id) {
            dispatch(bannerActions.createBanner(formData));
        } else {
            dispatch(bannerActions.updateBanner(banner, formData));
        }
    };

    const onCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectingBanner(null);
        dispatch(bannerActions.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            bannerActions.fetchBanners({
                ...pagination,
                name: searchName,
                current: 1,
            })
        );
    };

    const onOpenDetailModal = (banner = null) => {
        setSelectingBanner(banner);
        setOpenDetailModal(true);
    };

    const onDelete = (banner) => {
        Modal.confirm({
            title: `Xác nhận reset mật khẩu`,
            content: `Bạn có chắc chắn muốn xóa banner này?`,
            onOk: () => dispatch(bannerActions.deleteBanner(banner._id)),
            centered: true,
        });
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Paragraph>
            ),
        },
        {
            title: "Tên",
            // width: "10%",
            dataIndex: "name",
        },
        {
            title: "Hình ảnh",
            width: "300px",
            dataIndex: "image",
            render: (image) => (
                <Image
                    alt="Banner image"
                    src={getPhotoUrl(image?.path || null)}
                    width="250px"
                    height="100px"
                />
            ),
        },
        {
            title: "Thứ tự",
            dataIndex: "priority",
            width: "10%",
            align: "center",
        },
        {
            title: "Đối tượng",
            dataIndex: "docModel",
            align: "center",
        },
        {
            title: "Hiển thị trên trang chủ",
            dataIndex: "inHome",
            align: "center",
            // width: "10%",
            sorter: (a, b) => a.inHome - b.inHome,
            render: (inHome) =>
                inHome ? (
                    <span className="text-xl">
                        <CheckOutlined />
                    </span>
                ) : (
                    <span className="text-slate-300 text-xl">
                        <CloseOutlined />
                    </span>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];
    return (
        <>
            <Row align="middle" justify="space-between">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Banner
                </Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={banners}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <BannerDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCloseDetailModal}
                    banner={selectingBanner}
                />
            )}
        </>
    );
}
