import axiosClient from "utils/api/axios-client";

const orderApi = {
    getAll: (params) => {
        const url = "/api/v1/food/orders";
        return axiosClient.get(url, { params });
    },
    getDetail: (id) => {
        const url = `/api/v1/food/orders/${id}/detail`;
        return axiosClient.get(url);
    },
    getCost: (id) => {
        const url = `/api/v1/food/orders/${id}/cost`;
        return axiosClient.get(url);
    },
    getRejects: (id) => {
        const url = `/api/v1/food/orders/${id}/reject`;
        return axiosClient.get(url);
    },
    cancel: (id, reason) => {
        const url = `/api/v1/food/orders/${id}/cancel`;
        return axiosClient.delete(url, { data: { reason } });
    },
};

export default orderApi;
