import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import restaurantApi from "./api";
import { toast } from "react-toastify";
import { HTTP_METHODS } from "utils/constants";

const initialState = {
    restaurants: [],
    totalUpdate: 0,
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
    isCompletedUpdatingMenu: false,
    isCompletedUpdatingDish: false,
    isCompletedUpdatingDishOption: false,
    isCompletedProcessingUpdateInfo: false,
    menuInfo: {
        menus: [],
        dishes: [],
        dishOptions: [],
    },
};

export const getMenus = createAsyncThunk(
    "restaurants/getMenus",
    async (idRestaurant, thunkAPI) => {
        try {
            const data = await restaurantApi.getMenus(idRestaurant);
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
    {
        getPendingMeta: () => {
            return { method: HTTP_METHODS.GET };
        },
    }
);

export const createMenu = createAsyncThunk(
    "restaurants/createMenu",
    async (menu, thunkAPI) => {
        try {
            const data = await restaurantApi.createMenu(menu);
            if (data.error) {
                toast.error(data.error.msg);
                throw new Error(data.error.msg);
            }
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateMenu = createAsyncThunk(
    "restaurants/updateMenu",
    async (menu, thunkAPI) => {
        try {
            const data = await restaurantApi.updateMenu(menu._id, menu);
            if (data.error) {
                toast.error(data.error.msg);
                throw new Error(data.error.msg);
            }
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const deleteMenu = createAsyncThunk(
    "restaurants/deleteMenu",
    async (id, thunkAPI) => {
        try {
            await restaurantApi.deleteMenu(id);
            return id;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getDishOptions = createAsyncThunk(
    "restaurants/getDishOptions",
    async (idRestaurant, thunkAPI) => {
        try {
            const data = await restaurantApi.getDishOptions(idRestaurant);
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
    {
        getPendingMeta: () => {
            return { method: HTTP_METHODS.GET };
        },
    }
);

export const createDishOption = createAsyncThunk(
    "restaurants/createDishOption",
    async (dishOption, thunkAPI) => {
        try {
            const data = await restaurantApi.createDishOption(dishOption);
            if (data.error) {
                toast.error(data.error.msg);
                throw new Error(data.error.msg);
            }
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateDishOption = createAsyncThunk(
    "restaurants/updateDishOption",
    async (dishOption, thunkAPI) => {
        try {
            const data = await restaurantApi.updateDishOption(
                dishOption._id,
                dishOption
            );
            if (data.error) {
                toast.error(data.error.msg);
                throw new Error(data.error.msg);
            }
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const deleteDishOption = createAsyncThunk(
    "restaurants/deleteDishOption",
    async (id, thunkAPI) => {
        try {
            await restaurantApi.deleteDishOption(id);
            return id;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getDishes = createAsyncThunk(
    "restaurants/getDishes",
    async (idRestaurant, thunkAPI) => {
        try {
            const data = await restaurantApi.getDishes(idRestaurant);
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
    {
        getPendingMeta: () => {
            return { method: HTTP_METHODS.GET };
        },
    }
);

export const createDish = createAsyncThunk(
    "restaurants/createDish",
    async (dish, thunkAPI) => {
        try {
            const data = await restaurantApi.createDish(dish);
            if (data.error) {
                toast.error(data.error.msg);
                throw new Error(data.error.msg);
            }
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateDish = createAsyncThunk(
    "restaurants/updateDish",
    async (arg, thunkAPI) => {
        try {
            const { dish, formData } = arg;
            const data = await restaurantApi.updateDish(dish._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
                throw new Error(data.error.msg);
            }
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateDishStatus = createAsyncThunk(
    "restaurants/updateDishStatus",
    async (arg, thunkAPI) => {
        try {
            const { _id, status } = arg;
            const data = await restaurantApi.updateDishStatus(_id, status);
            if (data.success) {
                return { _id, status };
            } else {
                throw "Thay đổi trạng thái món ăn thất bại! Vui lòng thử lại sau";
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateDishIsHot = createAsyncThunk(
    "restaurants/updateDishIsHot",
    async (arg, thunkAPI) => {
        try {
            const { _id, isHot } = arg;
            const data = await restaurantApi.updateDishIsHot(_id, isHot);
            if (data.success) {
                return { _id, isHot };
            } else {
                throw "Thay đổi trạng thái món nổi bật thất bại! Vui lòng thử lại sau";
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateDishIsAvailable = createAsyncThunk(
    "restaurants/updateDishIsAvailable",
    async (arg, thunkAPI) => {
        try {
            const { _id, isAvailable } = arg;
            const data = await restaurantApi.updateDishIsAvailable(
                _id,
                isAvailable
            );
            if (data.success) {
                return { _id, isAvailable };
            } else {
                throw "Thay đổi trạng thái món thất bại! Vui lòng thử lại sau";
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateDishIsHidden = createAsyncThunk(
    "restaurants/updateDishIsHidden",
    async (arg, thunkAPI) => {
        try {
            const { _id, isHidden } = arg;
            const data = await restaurantApi.updateDishIsHidden(_id, isHidden);
            if (data.success) {
                return { _id, isHidden };
            } else {
                throw "Thay đổi trạng thái món thất bại! Vui lòng thử lại sau";
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const deleteDish = createAsyncThunk(
    "restaurants/deleteDish",
    async (id, thunkAPI) => {
        try {
            await restaurantApi.deleteDish(id);
            return id;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const restaurantSlice = createSlice({
    name: "food-restaurant",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setIsCompletedUpdatingMenu: (state, action) => {
            state.isCompletedUpdatingMenu = action.payload;
        },
        setIsCompletedUpdatingDish: (state, action) => {
            state.isCompletedUpdatingDish = action.payload;
        },
        setIsCompletedUpdatingDishOption: (state, action) => {
            state.isCompletedUpdatingDishOption = action.payload;
        },
        setIsCompletedProcessingUpdateInfo: (state, action) => {
            state.isCompletedProcessingUpdateInfo = action.payload;
        },
        setRestaurants: (state, action) => {
            state.restaurants = action.payload;
        },
        setTotalUpdateCount: (state, action) => {
            state.totalUpdate = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = { ...action.payload };
        },
        addRestaurant: (state, action) => {
            state.isCompleted = true;
            state.restaurants.unshift(action.payload);
        },
        editRestaurant: (state, action) => {
            state.restaurants = state.restaurants.map((restaurant) =>
                restaurant._id === action.payload._id
                    ? action.payload
                    : restaurant
            );
        },
        editRestaurantIsOpen: (state, action) => {
            state.restaurants = state.restaurants.map((restaurant) =>
                restaurant._id === action.payload._id
                    ? { ...restaurant, isOpen: action.payload.isOpen }
                    : restaurant
            );
        },
        editRestaurantIsPromo: (state, action) => {
            state.restaurants = state.restaurants.map((restaurant) =>
                restaurant._id === action.payload._id
                    ? { ...restaurant, isPromo: action.payload.isPromo }
                    : restaurant
            );
        },
        editRestaurantActive: (state, action) => {
            state.restaurants = state.restaurants.map((restaurant) =>
                restaurant._id === action.payload._id
                    ? { ...restaurant, active: action.payload.active }
                    : restaurant
            );
        },
        removeRestaurant: (state, action) => {
            state.isCompleted = true;
            state.restaurants = state.restaurants.filter(
                (user) => user._id !== action.payload._id
            );
        },
        setMenus: (state, action) => {
            state.menuInfo.menus = action.payload;
        },
        clearDetailRestaurantState: (state) => {
            state.menuInfo = initialState.menuInfo;
            state.isLoading = false;
            state.isProcessing = false;
            state.isCompleted = false;
            state.isCompletedUpdatingMenu = false;
            state.isCompletedUpdatingDish = false;
            state.isCompletedUpdatingDishOption = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMenus.fulfilled, (state, action) => {
                state.menuInfo.menus = action.payload.sort(
                    (a, b) => a.priority - b.priority
                );
            })
            .addCase(getDishes.fulfilled, (state, action) => {
                state.menuInfo.dishes = action.payload;
            })
            .addCase(getDishOptions.fulfilled, (state, action) => {
                state.menuInfo.dishOptions = action.payload.sort(
                    (a, b) => a.priority - b.priority
                );
            })
            .addCase(createMenu.fulfilled, (state, action) => {
                state.menuInfo.menus.push(action.payload);
                state.isCompletedUpdatingMenu = true;
            })
            .addCase(updateMenu.fulfilled, (state, action) => {
                state.menuInfo.menus = state.menuInfo.menus.map((option) =>
                    option._id === action.payload._id ? action.payload : option
                );
                state.isCompletedUpdatingMenu = true;
            })
            .addCase(deleteMenu.fulfilled, (state, action) => {
                state.menuInfo.menus = state.menuInfo.menus.filter(
                    (option) => option._id !== action.payload
                );
                state.isCompletedUpdatingMenu = true;
            })
            .addCase(createDishOption.fulfilled, (state, action) => {
                state.menuInfo.dishOptions.push(action.payload);
                state.menuInfo.dishOptions = state.menuInfo.dishOptions.sort(
                    (a, b) => a.priority - b.priority
                );
                state.isCompletedUpdatingDishOption = true;
            })
            .addCase(updateDishOption.fulfilled, (state, action) => {
                state.menuInfo.dishOptions = state.menuInfo.dishOptions
                    .map((option) =>
                        option._id === action.payload._id
                            ? action.payload
                            : option
                    )
                    .sort((a, b) => a.priority - b.priority);
                state.isCompletedUpdatingDishOption = true;
            })
            .addCase(deleteDishOption.fulfilled, (state, action) => {
                state.menuInfo.dishOptions = state.menuInfo.dishOptions
                    .filter((option) => option._id !== action.payload)
                    .sort((a, b) => a.priority - b.priority);
                state.isCompletedUpdatingDishOption = true;
            })
            .addCase(createDish.fulfilled, (state, action) => {
                state.menuInfo.dishes.push(action.payload);
                state.isCompletedUpdatingDish = true;
            })
            .addCase(updateDish.fulfilled, (state, action) => {
                state.menuInfo.dishes = state.menuInfo.dishes.map((option) =>
                    option._id === action.payload._id ? action.payload : option
                );
                state.isCompletedUpdatingDish = true;
            })
            .addCase(updateDishStatus.fulfilled, (state, action) => {
                state.menuInfo.dishes = state.menuInfo.dishes.map((option) =>
                    option._id === action.payload._id
                        ? { ...option, status: action.payload.status }
                        : option
                );
                toast.success("Cập nhật trạng thái món ăn thành công");
            })
            .addCase(updateDishIsHot.fulfilled, (state, action) => {
                state.menuInfo.dishes = state.menuInfo.dishes.map((option) =>
                    option._id === action.payload._id
                        ? { ...option, isHot: action.payload.isHot }
                        : option
                );
                toast.success("Cập nhật trạng thái món nổi bật thành công");
            })
            .addCase(updateDishIsAvailable.fulfilled, (state, action) => {
                state.menuInfo.dishes = state.menuInfo.dishes.map((option) =>
                    option._id === action.payload._id
                        ? { ...option, isAvailable: action.payload.isAvailable }
                        : option
                );
                toast.success("Cập nhật trạng thái món thành công");
            })
            .addCase(updateDishIsHidden.fulfilled, (state, action) => {
                state.menuInfo.dishes = state.menuInfo.dishes.map((option) =>
                    option._id === action.payload._id
                        ? { ...option, isHidden: action.payload.isHidden }
                        : option
                );
                toast.success("Cập nhật trạng thái món thành công");
            })
            .addCase(deleteDish.fulfilled, (state, action) => {
                state.menuInfo.dishes = state.menuInfo.dishes.filter(
                    (option) => option._id !== action.payload
                );
                state.isCompletedUpdatingDish = true;
            })
            .addMatcher(
                (action) => action.type.endsWith("/pending"),
                (state, action) => {
                    if (action.meta.method === HTTP_METHODS.GET) {
                        state.isLoading = true;
                    } else {
                        state.isProcessing = true;
                    }
                }
            )
            .addMatcher(
                (action) =>
                    action.type.endsWith("/fulfilled") ||
                    action.type.endsWith("/rejected"),
                (state) => {
                    state.isLoading = false;
                    state.isProcessing = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action) => {
                    console.error(action.payload);
                }
            );
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setIsCompletedUpdatingMenu,
    setIsCompletedUpdatingDish,
    setIsCompletedUpdatingDishOption,
    setIsCompletedProcessingUpdateInfo,
    setRestaurants,
    setTotalUpdateCount,
    setPagination,
    addRestaurant,
    editRestaurant,
    editRestaurantIsOpen,
    editRestaurantIsPromo,
    editRestaurantActive,
    removeRestaurant,
    setMenus,
    clearDetailRestaurantState,
} = restaurantSlice.actions;

export const fetchRestaurants = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await restaurantApi.getAll(params);
            dispatch(
                setRestaurants(
                    data.data.restaurants ? data.data.restaurants : data.data
                )
            );
            dispatch(setTotalUpdateCount(data.data?.totalUpdate || 0));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createRestaurant = (restaurant) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.create(restaurant);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addRestaurant(data));
                toast.success("Thêm quán mới thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const resetPasswordRestaurant = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.resetPassword(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Reset mật khẩu tài khoản thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateRestaurantInfo = (restaurant, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateInfo(
                restaurant._id,
                formData
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                const newRestaurantData = { ...restaurant, ...data };
                dispatch(editRestaurant(newRestaurantData));
                toast.success("Cập nhật thông tin quán ăn thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateIsOpenRestaurant = (id, isOpen) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateIsOpen(id, isOpen);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRestaurantIsOpen({ _id: id, isOpen }));
                toast.success("Cập nhật thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateIsPromoRestaurant = (id, isPromo) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateIsPromo(id, isPromo);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRestaurantIsPromo({ _id: id, isPromo }));
                toast.success("Cập nhật thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateActiveRestaurant = (id, active, sendNoti) => {
    return async (dispatch) => {
        try {
            dispatch(setIsCompletedProcessingUpdateInfo(true));
            const data = await restaurantApi.updateActive(id, active, sendNoti);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRestaurantActive({ _id: id, active }));
                toast.success("Cập nhật thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsCompletedProcessingUpdateInfo(false));
        }
    };
};
export const updateRestaurantAccount = (restaurant, accountData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateAccount(
                restaurant._id,
                accountData
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                const newRestaurantData = {
                    ...restaurant,
                    merchant: { ...data },
                };
                dispatch(editRestaurant(newRestaurantData));
                toast.success("Cập nhật tài khoản chủ quán thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateRestaurantBanking = (restaurant, bankingData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateBanking(
                restaurant._id,
                bankingData
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                const newRestaurantData = {
                    ...restaurant,
                    merchant: { ...data },
                };
                dispatch(editRestaurant(newRestaurantData));
                toast.success("Cập nhật tài khoản ngân hàng thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateRestaurantConfig = (restaurant, configData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateConfig(
                restaurant._id,
                configData
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                const newRestaurantData = {
                    ...restaurant,
                    config: { ...data },
                };
                dispatch(editRestaurant(newRestaurantData));
                toast.success("Cập nhật thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateRestaurantCharge = (restaurant, chargeInfo) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.updateCharge(
                restaurant._id,
                chargeInfo
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRestaurant({ ...restaurant, ...chargeInfo }));
                toast.success("Cập nhật chiết khấu quán thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const setupEvent = (restaurant, eventInfo) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.setupEvent(
                restaurant._id,
                eventInfo
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRestaurant({ ...restaurant, event: eventInfo }));
                toast.success("Thiết lập sự kiện cho quán thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteRestaurant = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantApi.delete(id);
            dispatch(removeRestaurant(data));
            toast.success("Xóa tài khoản thành công!");
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateMenusOrder = (newMenus) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const menus = newMenus.map((menu, index) => ({
                ...menu,
                priority: index,
            }));
            const data = await restaurantApi.updateMenuPriority(menus);
            if (data.success) {
                toast.success("Cập nhật thứ tự Menu thành công");
                dispatch(setMenus(menus));
            } else {
                toast.error(
                    "Cập nhật thứ tự Menu thất bại. Vui lòng thử lại sau"
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const verifyUpdateInfo = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompletedProcessingUpdateInfo(false));
            const data = await restaurantApi.verifyUpdateInfo(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(setIsCompletedProcessingUpdateInfo(true));
                toast.success("Duyệt thông tin quán ăn thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const rejectUpdateInfo = (id, rejectReason) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompletedProcessingUpdateInfo(false));
            const data = await restaurantApi.rejectUpdateInfo(id, rejectReason);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(setIsCompletedProcessingUpdateInfo(true));
                toast.success("Từ chối thông tin quán ăn thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default restaurantSlice.reducer;
