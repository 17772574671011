import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { useMemo, useRef, useState } from "react";

const DebounceSelect = ({
    initOptions = [],
    fetchOptions,
    debounceTimeout = 500,
    convertOptions,
    ...props
}) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState(initOptions);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value)
                .then((data) => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }
                    const opts = convertOptions ? convertOptions(data) : data;
                    setOptions(opts);
                    setFetching(false);
                })
                .catch((err) => {
                    console.log();
                });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            allowClear={true}
            {...props}
            showSearch
        />
    );
};

export default DebounceSelect;
