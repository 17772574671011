import { Button, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DishOptionDetailModal from "./DishOptionDetailModal";
import DishOptionItem from "./Item";
import * as restaurantActions from "features/food/restaurant/slice";

export default function TabDishOption({ restaurant }) {
    const [openDishOptionModal, setOpenDishOptionModal] = useState(false);
    const [selectedDishOption, setSelectedDishOption] = useState(null);
    const {
        menuInfo: { dishOptions },
        isCompletedUpdatingDishOption,
    } = useSelector((state) => state.foodRestaurant);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isCompletedUpdatingDishOption) {
            onCloseDishOptionModal();
            dispatch(restaurantActions.setIsCompletedUpdatingDishOption(false));
        }
    }, [isCompletedUpdatingDishOption]);

    const onOpenDishOptionModal = (_selectedDishOption = null) => {
        setOpenDishOptionModal(true);
        if (_selectedDishOption) {
            setSelectedDishOption(_selectedDishOption);
        }
    };

    const onCloseDishOptionModal = () => {
        setOpenDishOptionModal(false);
        if (selectedDishOption) {
            setSelectedDishOption(null);
        }
    };

    const onDeleteDishOption = (dishOption) => {
        Modal.confirm({
            title: `Xác nhận xoá nhóm tuỳ chọn`,
            content: `Bạn có chắc chắn muốn xoá nhóm tuỳ chọn này?`,
            onOk: () =>
                dispatch(restaurantActions.deleteDishOption(dishOption._id)),
            centered: true,
        });
    };

    const onSubmitDishOption = (dishOption) => {
        if (dishOption._id) {
            dispatch(restaurantActions.updateDishOption(dishOption));
        } else {
            dispatch(restaurantActions.createDishOption(dishOption));
        }
    };

    return (
        <>
            <div className="mb-4 text-right">
                <Button onClick={() => onOpenDishOptionModal()} type="primary">
                    Thêm nhóm tuỳ chọn
                </Button>
            </div>
            <Space direction="vertical" size="middle" className="w-full">
                {dishOptions.map((option) => (
                    <DishOptionItem
                        data={option}
                        key={option._id}
                        onEdit={onOpenDishOptionModal}
                        onDelete={onDeleteDishOption}
                    />
                ))}
            </Space>
            {openDishOptionModal && (
                <DishOptionDetailModal
                    open={openDishOptionModal}
                    dishOption={selectedDishOption}
                    onCancel={onCloseDishOptionModal}
                    onSubmit={onSubmitDishOption}
                    restaurant={restaurant}
                />
            )}
        </>
    );
}
