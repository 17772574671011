import { createSlice } from "@reduxjs/toolkit";
import moduleApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    modules: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const moduleslice = createSlice({
    name: "module",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setModules: (state, action) => {
            state.modules = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addModule: (state, action) => {
            state.isCompleted = true;
            state.modules.unshift(action.payload);
        },
        editModule: (state, action) => {
            state.isCompleted = true;
            state.modules = state.modules.map((module) =>
                module._id === action.payload._id ? action.payload : module
            );
        },
        removeModule: (state, action) => {
            state.isCompleted = true;
            state.modules = state.modules.filter(
                (module) => module._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setModules,
    setPagination,
    addModule,
    editModule,
    removeModule,
} = moduleslice.actions;

export const fetchModules = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await moduleApi.getAll(params);
            dispatch(setModules(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createModule = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await moduleApi.create(formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addModule(data));
                toast.success("Thêm module mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateModule = (id, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await moduleApi.update(id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editModule(data));
                toast.success("Cập nhật module thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteModule = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await moduleApi.delete(id);
            if (data.success) {
                dispatch(removeModule(id));
                toast.success("Xóa module thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa module thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default moduleslice.reducer;
