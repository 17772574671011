import { Form, Input, Modal, Segmented } from "antd";

export default function EditModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, order } = props;

    const initialValues = {
        _id: order._id,
        user: order?.user?._id,
        quantity: order?.quantity || 1,
        ticket: order?.ticket || 0,
        amount: order?.amount || 0,
        paymentMethod: order?.paymentMethod || "cash",
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const data = { ...initialValues, ...values };
                onSubmit(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Cập nhật chuyến đi"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item name="user" label="ID khách hàng">
                    <Input />
                </Form.Item>
                <Form.Item name="quantity" label="Số người">
                    <Input type={"number"} min={1} />
                </Form.Item>
                <Form.Item name="ticket" label="Số lượt trừ khách">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="amount" label="Số tiền khách cần thanh toán">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="paymentMethod" label="Hình thức thanh toán">
                    <Segmented
                        options={[
                            { label: "Tiền mặt", value: "cash" },
                            { label: "Chuyển khoản", value: "banking" },
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
