import axiosClient from "utils/api/axios-client";

const shipperApi = {
    getAll: (params) => {
        const url = "/api/v1/delivery/shippers";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/delivery/shippers/${id}`;
        return axiosClient.get(url);
    },
    create: (shipper) => {
        const url = `/api/v1/delivery/shippers/`;
        return axiosClient.post(url, shipper);
    },
    update: (shipper) => {
        const url = `/api/v1/delivery/shippers/${shipper._id}`;
        return axiosClient.put(url, shipper);
    },
    delete: (id) => {
        const url = `/api/v1/delivery/shippers/${id}`;
        return axiosClient.delete(url);
    },
};

export default shipperApi;
