import axiosClient from "utils/api/axios-client";

const orderApi = {
    getAll: (params) => {
        const url = "/api/v1/liquidation/posts";
        return axiosClient.get(url, {
            params,
        });
    },
    get: (id) => {
        const url = `/api/v1/liquidation/posts/${id}`;
        return axiosClient.get(url);
    },
    update: (id, post) => {
        const url = `/api/v1/liquidation/posts/${id}`;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        return axiosClient.put(url, post, config);
    },
    delete: (id) => {
        const url = `/api/v1/liquidation/posts/${id}`;
        return axiosClient.delete(url);
    },
    hide: (id) => {
        const url = `/api/v1/liquidation/posts/${id}/hide`;
        return axiosClient.put(url);
    },
    show: (id) => {
        const url = `/api/v1/liquidation/posts/${id}/show`;
        return axiosClient.put(url);
    },
};

export default orderApi;
