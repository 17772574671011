import React, { useState } from "react";
import { Button, Image, Input } from "antd";
import { getPhotoUrl, priceToCurrency } from "utils/common";
import DefaultDishImg from "assets/img/no-image.png";
import { uniqueId } from "lodash";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

export default function DishRow({
    dish,
    prevSelectedDish = null,
    onCancelEditing = () => {},
    onConfirmSelect,
}) {
    const INIT_DISH_INFO = {
        dishId: dish._id,
        customizes: prevSelectedDish?.customizes || [],
        customizesSummary: prevSelectedDish?.customizesSummary || "",
        quantity: prevSelectedDish?.quantity || 0,
        price: dish.price,
        name: dish.name,
        note: prevSelectedDish?.note || "",
        _id: prevSelectedDish?._id || uniqueId(),
    };
    const [dishInfo, setDishInfo] = useState(INIT_DISH_INFO);

    const onResetDishInfo = () => {
        setDishInfo(INIT_DISH_INFO);
    };

    const onChangeDishQuantity = (quantity) => {
        if (quantity > 0) {
            setDishInfo((c) => ({ ...c, quantity }));
        } else if (quantity === 0) {
            onResetDishInfo();
        }
    };

    const onChangeDishNote = (note) => {
        setDishInfo((c) => ({ ...c, note }));
    };

    const onChangeDishOption = (
        selectedOption,
        selectedChildOption,
        quantity
    ) => {
        setDishInfo((currentDishInfo) => {
            let updatedCustomizes = [...currentDishInfo.customizes];

            const optionIndex = updatedCustomizes.findIndex(
                (customize) => customize.optionId === selectedOption._id
            );

            if (optionIndex !== -1) {
                // Option already exists
                if (selectedOption.isSingleChoice) {
                    // For single-choice options, keep only the selected child option
                    updatedCustomizes[optionIndex] = {
                        ...updatedCustomizes[optionIndex],
                        options:
                            quantity > 0
                                ? [{ ...selectedChildOption, quantity }]
                                : [],
                    };
                } else {
                    // For multi-choice options, add, update, or remove child options based on the quantity
                    const childOptionIndex = updatedCustomizes[
                        optionIndex
                    ].options.findIndex(
                        (option) => option.slug === selectedChildOption.slug
                    );

                    if (childOptionIndex !== -1) {
                        if (quantity > 0) {
                            // Update the quantity of the existing child option
                            updatedCustomizes[optionIndex].options[
                                childOptionIndex
                            ] = {
                                ...updatedCustomizes[optionIndex].options[
                                    childOptionIndex
                                ],
                                quantity,
                            };
                        } else {
                            // Remove the child option if quantity is 0
                            updatedCustomizes[optionIndex].options.splice(
                                childOptionIndex,
                                1
                            );
                        }
                    } else if (quantity > 0) {
                        // Add new child option if it doesn't exist and quantity is greater than 0
                        updatedCustomizes[optionIndex].options.push({
                            ...selectedChildOption,
                            quantity,
                        });
                    }
                }
            } else if (quantity > 0) {
                // Add new option if it doesn't exist and quantity is greater than 0
                updatedCustomizes.push({
                    optionId: selectedOption._id,
                    options: [{ ...selectedChildOption, quantity }],
                });
            }

            return {
                ...currentDishInfo,
                customizes: updatedCustomizes,
                customizesSummary: updatedCustomizes
                    .map((option) =>
                        option.options
                            .map(
                                (childOption) =>
                                    `${childOption.name} x${childOption.quantity}`
                            )
                            .join(", ")
                    )
                    .join(", "),
            };
        });
    };

    const onSaveDish = () => {
        const requiredOptions = dish.options.filter(
            (option) => option.isRequired
        );
        let isValid = true;
        if (requiredOptions.length > 0) {
            // console.log(requiredOptions);
            for (let i = 0; i < requiredOptions.length; ++i) {
                const option = requiredOptions[i];
                // console.log(option);
                if (
                    !dishInfo.customizes.find(
                        (customizeOption) =>
                            customizeOption.optionId === option._id
                    )
                ) {
                    isValid = false;
                    break;
                }
            }
        }
        if (isValid) {
            onConfirmSelect(dishInfo);
            onResetDishInfo();
            onCancelEditing();
        } else {
            toast.error(
                "Có tùy chọn món ăn bắt buộc đang bị để trống! Vui lòng bổ sung thêm"
            );
        }
    };

    return (
        <>
            <React.Fragment>
                <tr>
                    <td className="border-0 border-t border-solid border-gray-300 w-1">
                        <div className="flex gap-x-2 items-center">
                            <Button
                                size="small"
                                onClick={() =>
                                    onChangeDishQuantity(dishInfo.quantity - 1)
                                }
                                disabled={dishInfo.quantity === 0}
                            >
                                -
                            </Button>
                            <span>{dishInfo.quantity}</span>
                            <Button
                                size="small"
                                onClick={() =>
                                    onChangeDishQuantity(dishInfo.quantity + 1)
                                }
                            >
                                +
                            </Button>
                        </div>
                    </td>
                    <td className="border-0 border-t border-solid border-gray-300">
                        <div className="flex gap-x-2 items-center">
                            <Image
                                src={
                                    dish.image
                                        ? getPhotoUrl(dish.image.path || null)
                                        : DefaultDishImg
                                }
                                preview={Boolean(dish.image)}
                                width={60}
                                height={60}
                            />
                            <span className="ml-2">{dish.name}</span>
                        </div>
                    </td>
                    <td className="border-0 border-t border-solid border-gray-300 text-right">
                        {priceToCurrency(dish.price)}
                    </td>
                </tr>
                {dishInfo.quantity > 0 && (
                    <>
                        {dish.options.map((option) => {
                            return (
                                <React.Fragment key={option._id}>
                                    <tr>
                                        <td colSpan={3}>
                                            <span className="font-semibold text-lg">
                                                {option.name}{" "}
                                                {option.isRequired &&
                                                    "(Bắt buộc)"}
                                            </span>
                                        </td>
                                    </tr>
                                    {option.options.map((childOption) => {
                                        const existingOption =
                                            dishInfo.customizes
                                                .find(
                                                    (_option) =>
                                                        _option.optionId ===
                                                        option._id
                                                )
                                                ?.options.find(
                                                    (_childOption) =>
                                                        _childOption.slug ===
                                                        childOption.slug
                                                );

                                        const quantity = existingOption
                                            ? existingOption.quantity
                                            : 0;
                                        return (
                                            <tr
                                                key={
                                                    childOption._id ||
                                                    childOption.optionId
                                                }
                                            >
                                                <td colSpan={2}>
                                                    <div className="flex gap-x-2 items-center">
                                                        <Button
                                                            icon={
                                                                <MinusOutlined />
                                                            }
                                                            size="small"
                                                            onClick={() =>
                                                                onChangeDishOption(
                                                                    option,
                                                                    childOption,
                                                                    quantity - 1
                                                                )
                                                            }
                                                            disabled={
                                                                quantity === 0
                                                            }
                                                        />
                                                        {quantity}
                                                        <Button
                                                            icon={
                                                                <PlusOutlined />
                                                            }
                                                            size="small"
                                                            onClick={() =>
                                                                onChangeDishOption(
                                                                    option,
                                                                    childOption,
                                                                    quantity + 1
                                                                )
                                                            }
                                                        />
                                                        <span>
                                                            {childOption.name}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    {priceToCurrency(
                                                        childOption.price
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                        <tr>
                            <td colSpan={3}>
                                <Input.TextArea
                                    placeholder="Ghi chú"
                                    value={dishInfo.note}
                                    onChange={(e) =>
                                        onChangeDishNote(e.target.value)
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className="flex gap-x-2 justify-end">
                                    {prevSelectedDish && (
                                        <Button
                                            onClick={() => {
                                                onCancelEditing();
                                                onResetDishInfo();
                                            }}
                                        >
                                            Hủy bỏ
                                        </Button>
                                    )}
                                    <Button type="primary" onClick={onSaveDish}>
                                        Xác nhận chọn
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </React.Fragment>
        </>
    );
}
