import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Form,
    Input,
    Modal,
    Segmented,
    Space,
    Switch,
} from "antd";
import { useSelector } from "react-redux";
import { getSlug } from "utils/common";

export default function DishOptionDetailModal(props) {
    const [form] = Form.useForm();
    const { open, onSubmit, onCancel, dishOption, restaurant } = props;

    const { isProcessing } = useSelector((state) => state.foodRestaurant);

    const initialValues = dishOption || {
        name: "",
        priority: 100,
        isRequired: false,
        isSingleChoice: true,
        options: [],
        restaurant: restaurant._id,
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const dishOption = { ...initialValues, ...values };
                onSubmit(dishOption);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const onUpdateSlug = (slugKey, nameValue) => {
        form.setFieldValue(slugKey, getSlug(nameValue));
    };

    return (
        <Modal
            title="Nhóm tùy chọn"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="name"
                    label="Tên nhóm tùy chọn"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự sắp xếp">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="isSingleChoice" label="Số lượng chọn">
                    <Segmented
                        options={[
                            { label: "Chọn một", value: true },
                            { label: "Chọn nhiều", value: false },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="isRequired"
                    valuePropName="checked"
                    style={{
                        display: "inline-block",
                        marginRight: "20px",
                    }}
                >
                    <Switch
                        checkedChildren="Bắt buộc"
                        unCheckedChildren="Bắt buộc"
                    />
                </Form.Item>
                <div className="mb-4">
                    <label>Tùy chọn</label>
                </div>
                <Form.List name="options">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <Space
                                    key={field.key}
                                    direction="vertical"
                                    size="small"
                                >
                                    <Space size="middle">
                                        <Form.Item
                                            isListField={true}
                                            label="Tên"
                                            name={[field.name, "name"]}
                                            initialValue=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Tên là bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) =>
                                                    onUpdateSlug(
                                                        [
                                                            "options",
                                                            field.name,
                                                            "slug",
                                                        ],
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            isListField={true}
                                            label="Slug"
                                            name={[field.name, "slug"]}
                                            initialValue=""
                                        >
                                            <Input disabled />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                            onClick={() => remove(field.name)}
                                        />
                                    </Space>
                                    <Space size="middle">
                                        <Form.Item
                                            isListField={true}
                                            label="Giá tiền"
                                            name={[field.name, "price"]}
                                            initialValue={0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Giá tiền là bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            isListField={true}
                                            name={[field.name, "isAvailable"]}
                                            valuePropName="checked"
                                            initialValue={true}
                                            label="Trạng thái"
                                        >
                                            <Switch
                                                checkedChildren="Có sẵn"
                                                unCheckedChildren="Không khả dụng"
                                            />
                                        </Form.Item>
                                    </Space>
                                    <Divider />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Thêm tùy chọn
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}
