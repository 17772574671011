import { Divider, Form, Input, Modal, Select, Switch, Upload } from "antd";
import Loading from "components/Loading";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getBase64, getPhotoThumbnailUrl, getPhotoUrl } from "utils/common";

const { TextArea } = Input;

function PostDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, categories, post } = props;

    const initialValues = post
        ? {
              ...post,
              category: post.category._id,
          }
        : {
              title: "",
              content: "",
              category: undefined,
              price: 0,
          };
    const [fileList, setFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImg, setPreviewImg] = useState(null);
    const [loadedImg, setLoadedImg] = useState(false);

    const handlePreview = async (file) => {
        if (!file.previewUr && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImg({
            url: file.previewUr || file.preview,
            title:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
        setPreviewVisible(true);
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const onLoadImg = () => {
        setLoadedImg(true);
    };

    const handleCancelPreview = () => {
        setLoadedImg(false);
        setPreviewImg(null);
        setPreviewVisible(false);
    };

    useEffect(() => {
        form.setFieldsValue({ ...initialValues, post });
        const images =
            post?.photos.map((img) => ({
                ...img,
                uid: img._id,
                previewUr: getPhotoUrl(img.path),
                url: getPhotoThumbnailUrl(img.path),
            })) || [];
        setFileList(images);
    }, [post]);

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const post = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(post),
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(post, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Chi tiết bài đăng"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form
                // labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                // wrapperCol={{ xs: { span: 24 }, sm: { span: 16 } }}
                layout="vertical"
                form={form}
                initialValues={initialValues}
            >
                <Form.Item name="code" label="Code">
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    name="category"
                    label="Danh mục sản phẩm"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select>
                        {categories.map((category) => (
                            <Select.Option
                                key={category._id}
                                value={category._id}
                            >
                                {category.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="title"
                    label="Tiêu đề"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Nhập tiêu đề" />
                </Form.Item>
                <Form.Item
                    name="content"
                    label="Mô tả chi tiết"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <TextArea
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        placeholder="Vui lòng nhập mô tả chi tiết"
                    />
                </Form.Item>
                <Form.Item name="price" label="Giá bán">
                    <Input
                        type="number"
                        placeholder="Nhập giá bán hoặc bỏ trống"
                    />
                </Form.Item>
                <Form.Item
                    name="isPinned"
                    valuePropName="checked"
                    style={{
                        display: "inline-block",
                        marginRight: "20px",
                    }}
                >
                    <Switch
                        checkedChildren="Ghim tin"
                        unCheckedChildren="Ghim tin"
                    />
                </Form.Item>
                <Form.Item
                    name="showPrice"
                    valuePropName="checked"
                    style={{
                        display: "inline-block",
                        marginRight: "20px",
                        marginBottom: 0,
                    }}
                >
                    <Switch
                        checkedChildren="Hiển thị giá bán"
                        unCheckedChildren="Hiển thị giá bán"
                    />
                </Form.Item>
                <Form.Item
                    name="showPhone"
                    valuePropName="checked"
                    style={{
                        display: "inline-block",
                        marginRight: "20px",
                        marginBottom: 0,
                    }}
                >
                    <Switch
                        checkedChildren="số điện thoại"
                        unCheckedChildren="số điện thoại"
                    />
                </Form.Item>
                <Divider />
                <Form.Item>
                    <div className="clearfix">
                        <Upload
                            accept={"image/*"}
                            multiple={true}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        ></Upload>
                    </div>
                </Form.Item>
            </Form>
            {previewImg && (
                <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancelPreview}
                >
                    <div style={{ minHeight: "200px" }}>
                        <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImg.url}
                            onLoad={onLoadImg}
                        />
                    </div>
                    {!loadedImg && <Loading />}
                </Modal>
            )}
        </Modal>
    );
}

PostDetailModal.propTypes = {
    open: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    post: PropTypes.object,
};

PostDetailModal.defaultProps = {
    isProcessing: false,
    open: false,
    post: null,
};

export default PostDetailModal;
