import {
    DashboardOutlined,
    DownOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    GiftOutlined,
    PercentageOutlined,
    SnippetsOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({
    record,
    onEditRestaurant,
    onEditMenu,
    onOpenUpdateChargeModal,
    onOpenStatsModal,
    onActive,
    onSetupEvent,
}) {
    const KEY_ACTIONS = {
        EDIT_RESTAURANT: "edit-restaurant",
        EDIT_MENU: "edit-menu",
        UPDATE_CHARGE: "update-charge",
        SETUP_EVENT: "setup-event",
        VIEW_STATS: "view_stats",
        ACTIVE: "active",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT_RESTAURANT:
                onEditRestaurant(record);
                break;
            case KEY_ACTIONS.EDIT_MENU:
                onEditMenu(record);
                break;
            case KEY_ACTIONS.UPDATE_CHARGE:
                onOpenUpdateChargeModal(record);
                break;
            case KEY_ACTIONS.SETUP_EVENT:
                onSetupEvent(record);
                break;
            case KEY_ACTIONS.VIEW_STATS:
                onOpenStatsModal(record);
                break;
            case KEY_ACTIONS.ACTIVE:
                onActive(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT_RESTAURANT,
            label: "Sửa thông tin quán",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.EDIT_MENU,
            label: "Sửa menu món ăn",
            icon: <SnippetsOutlined />,
        },
        {
            key: KEY_ACTIONS.SETUP_EVENT,
            label: "Thiết lập sự kiện",
            icon: <GiftOutlined />,
        },
        {
            key: KEY_ACTIONS.UPDATE_CHARGE,
            label: "Sửa chiết khấu",
            icon: <PercentageOutlined />,
        },
        {
            key: KEY_ACTIONS.VIEW_STATS,
            label: "Xem thống kê",
            icon: <DashboardOutlined />,
        },
        {
            key: KEY_ACTIONS.ACTIVE,
            label: record.active ? "Huỷ kích hoạt" : "Kích hoạt",
            icon: record.active ? <EyeInvisibleOutlined /> : <EyeOutlined />,
            danger: record.active,
        },
    ];

    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
