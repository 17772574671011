import axiosClient from "utils/api/axios-client";

const voucherApi = {
    getAll: (params) => {
        const url = "/api/v1/food/vouchers";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/food/vouchers/${id}`;
        return axiosClient.get(url);
    },
    create: (voucher) => {
        const url = `/api/v1/food/vouchers/`;
        return axiosClient.post(url, voucher);
    },
    update: (voucher) => {
        const url = `/api/v1/food/vouchers/${voucher._id}`;
        return axiosClient.put(url, voucher);
    },
    delete: (id) => {
        const url = `/api/v1/food/vouchers/${id}`;
        return axiosClient.delete(url);
    },
    verify: (id) => {
        const url = `/api/v1/food/vouchers/${id}/verify`;
        return axiosClient.put(url);
    },
    reject: (id, reason) => {
        const url = `/api/v1/food/vouchers/${id}/reject`;
        return axiosClient.put(url, { reason });
    },
    getCode: () => {
        const url = `/api/v1/food/vouchers/random-code`;
        return axiosClient.get(url);
    },
};

export default voucherApi;
