import { createSlice } from "@reduxjs/toolkit";
import comboApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    combos: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const comboSlice = createSlice({
    name: "tuktuk-combo",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setCombos: (state, action) => {
            state.combos = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addCombo: (state, action) => {
            state.isCompleted = true;
            state.combos.unshift(action.payload);
        },
        editCombo: (state, action) => {
            state.isCompleted = true;
            state.combos = state.combos.map((combo) =>
                combo._id === action.payload._id ? action.payload : combo
            );
        },
        removeCombo: (state, action) => {
            state.isCompleted = true;
            state.combos = state.combos.filter(
                (user) => user._id !== action.payload._id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setCombos,
    setPagination,
    addCombo,
    editCombo,
    removeCombo,
} = comboSlice.actions;

export const fetchCombos = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await comboApi.getAll(params);
            dispatch(setCombos(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createCombo = (combo) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await comboApi.create(combo);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addCombo(data));
                toast.success("Thêm combo mới thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateCombo = (combo, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await comboApi.update(combo._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editCombo(data));
                toast.success("Cập nhật combo thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteCombo = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await comboApi.delete(id);
            if (data.success) dispatch(removeCombo(id));
            toast.success("Xóa combo thành công!");
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default comboSlice.reducer;
