import { Form, Input, Modal, Select } from "antd";
import { WALLET_REQUEST_STATUS } from "utils/constants";

function ChangeStatusModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, walletRequest } = props;

    const initialValues = {
        _id: walletRequest._id,
        status: "",
        note: "",
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const data = { ...initialValues, ...values };
                onSubmit(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Cập nhật trạng thái"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="status"
                    label="Trạng thái"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select
                        options={Object.values(WALLET_REQUEST_STATUS).filter(
                            (option) =>
                                option.value !==
                                WALLET_REQUEST_STATUS.processing.value
                        )}
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Form.Item
                            name="note"
                            label="Ghi chú"
                            requiredMark={
                                form.getFieldValue("status") === "fail"
                            }
                            rules={[
                                {
                                    required:
                                        form.getFieldValue("status") === "fail",
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangeStatusModal;
