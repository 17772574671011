import { createSlice } from "@reduxjs/toolkit";
import postApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    posts: [],
    pagination: { current: 1, pageSize: 20 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const postSlice = createSlice({
    name: "market-post",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setPosts: (state, action) => {
            state.posts = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        editPost: (state, action) => {
            state.isCompleted = true;
            state.posts = state.posts.map((post) =>
                post._id === action.payload._id ? action.payload : post
            );
        },
        removePost: (state, action) => {
            state.isCompleted = true;
            state.posts = state.posts.filter(
                (post) => post._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setPosts,
    setPagination,
    editPost,
    removePost,
} = postSlice.actions;

export const fetchPosts = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await postApi.getAll(params);
            dispatch(setPosts(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const updatePost = (post, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await postApi.update(post._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editPost(data));
                toast.success("Cập nhật bài đăng thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deletePost = (id) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            await postApi.delete(id);
            dispatch(removePost(id));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const hidePost = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await postApi.hide(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePost(id));
                toast.success("Cập nhật trạng thái bài viết thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const showPost = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await postApi.show(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePost(id));
                toast.success("Cập nhật trạng thái bài viết thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default postSlice.reducer;
