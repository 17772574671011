import { createSlice } from "@reduxjs/toolkit";
import categoryApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    categories: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const categorySlice = createSlice({
    name: "liquidation-category",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addCategory: (state, action) => {
            state.isCompleted = true;
            state.categories.unshift(action.payload);
        },
        editCategory: (state, action) => {
            state.isCompleted = true;
            state.categories = state.categories.map((category) =>
                category._id === action.payload._id ? action.payload : category
            );
        },
        removeCategory: (state, action) => {
            state.isCompleted = true;
            state.categories = state.categories.filter(
                (category) => category._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setCategories,
    setPagination,
    addCategory,
    editCategory,
    removeCategory,
} = categorySlice.actions;

export const fetchCategories = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await categoryApi.getAll(params);
            dispatch(setCategories(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createCategory = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await categoryApi.create(formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addCategory(data));
                toast.success("Thêm danh mục thanh lý mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateCategory = (category, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await categoryApi.update(category._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editCategory(data));
                toast.success("Cập nhật danh mục thanh lý thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteCategory = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await categoryApi.delete(id);
            if (data.success) {
                dispatch(removeCategory(id));
                toast.success("Xóa danh mục thanh lý thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa danh mục thanh lý thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default categorySlice.reducer;
