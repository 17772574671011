import { Modal, Radio, Space } from "antd";
import { useState } from "react";

export default function AutoAvailableDishModal(props) {
    const { open, onSubmit, onClose, isProcessing } = props;
    const [time, setTime] = useState("");

    const onChangeTime = (e) => {
        setTime(e.target.value);
    };

    return (
        <Modal
            title={`Tự động bật lại món`}
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={() => onSubmit(time)}
            onCancel={onClose}
            confirmLoading={isProcessing}
        >
            <Radio.Group onChange={onChangeTime} value={time}>
                <Space direction="vertical">
                    <Radio value={"1h"}>Sau 1 giờ</Radio>
                    <Radio value={"4h"}>Sau 4 giờ</Radio>
                    <Radio value={"tomorrow"}>Cho đến ngày mai</Radio>
                    <Radio value={""}>Cho đến khi mở lại</Radio>
                </Space>
            </Radio.Group>
        </Modal>
    );
}
