import axiosClient from "utils/api/axios-client";

const adApi = {
    getAll: (params) => {
        const url = "/api/v1/food/ads";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/food/ads/${id}`;
        return axiosClient.get(url);
    },
    create: (ad) => {
        const url = `/api/v1/food/ads/`;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        return axiosClient.post(url, ad, config);
    },
    update: (id, ad) => {
        const url = `/api/v1/food/ads/${id}`;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        return axiosClient.put(url, ad, config);
    },
    delete: (id) => {
        const url = `/api/v1/food/ads/${id}`;
        return axiosClient.delete(url);
    },
};

export default adApi;
