import { PlusOutlined } from "@ant-design/icons";
import { Col, Form, Input, Modal, Row, Select, Space, Upload } from "antd";
import DebounceSelect from "components/common/DebounceSelect";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import {
    getBase64,
    getPhotoThumbnailUrl,
    getPhotoUrl,
    getSlug,
} from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

const { TextArea } = Input;

function CollectionDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, collection } = props;
    const [thumbUrl, setThumbUrl] = useState(
        getPhotoThumbnailUrl(collection?.thumb?.path || null)
    );
    const [coverUrl, setCoverUrl] = useState(
        getPhotoUrl(collection?.cover?.path || null, "w=200&h=102")
    );
    const [thumb, setThumb] = useState(null);
    const [cover, setCover] = useState(null);

    const initialValues = collection
        ? {
              ...collection,
              restaurants: collection.restaurants.map((e) => ({
                  key: e._id,
                  label: e.name,
                  value: e._id,
              })),
          }
        : {
              name: "",
              slug: "",
              priority: 99,
              active: true,
          };
    const onValuesChange = (changedValues) => {
        if (changedValues.name) {
            form.setFieldValue("slug", getSlug(changedValues.name));
        }
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const collection = { ...initialValues, ...values };
                if (collection.restaurants) {
                    collection.restaurants = collection.restaurants.map(
                        (e) => e.value
                    );
                }
                Object.entries({
                    data: JSON.stringify(collection),
                    thumb,
                    cover,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                delete collection.time;

                onSubmit(collection, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUploadThumb = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setThumbUrl(preview);
            setThumb(file);
        }
        return false;
    };

    const beforeUploadCover = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setCoverUrl(preview);
            setCover(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Bộ sưu tập"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={800}
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
            >
                <Row gutter={24}>
                    <Col span={14}>
                        <Form.Item
                            name="name"
                            label="Tên bộ sưu tập"
                            rules={[
                                {
                                    required: true,
                                    message: "Trường này là bắt buộc",
                                },
                            ]}
                            labelCol={{
                                xs: { span: 24 },
                                sm: { span: 7 },
                            }}
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 17 },
                            }}
                        >
                            <Input placeholder="Tên bộ sưu tập" />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="slug"
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                            label={false}
                        >
                            <Input disabled placeholder="Slug" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="content"
                    label="Nội dung"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        placeholder="Nội dung chi tiết của BST"
                    />
                </Form.Item>
                <Form.Item
                    name="restaurants"
                    label="DS quán"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <DebounceSelect
                        mode="multiple"
                        placeholder="Chọn danh sách quán thuộc BST"
                        initOptions={
                            collection?.restaurants
                                ? collection.restaurants.map((e) => ({
                                      key: e._id,
                                      label: e.name,
                                      value: e._id,
                                  }))
                                : []
                        }
                        fetchOptions={commonApi.searchRestaurant}
                        convertOptions={(data) =>
                            data.map((res) => ({
                                key: res._id,
                                label: res.name,
                                value: res._id,
                            }))
                        }
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item
                    name="active"
                    label="Trạng thái"
                    rules={[{ required: true }]}
                >
                    <Select placeholder="--Trạng thái--">
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>In-active</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Ảnh Thumb"
                    tooltip="Ảnh hiển thị ngoài trang chủ (vuông)"
                >
                    <Upload
                        accept={"image/*"}
                        name="thumb"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUploadThumb}
                    >
                        {thumbUrl ? (
                            <img
                                src={thumbUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="Ảnh Cover"
                    tooltip="Ảnh hiển thị trang chi tiết (hình chữ nhật)"
                >
                    <Space>
                        <Upload
                            accept={"image/*"}
                            name="cover"
                            listType="picture-card"
                            className="avatar-uploader-cover"
                            showUploadList={false}
                            beforeUpload={beforeUploadCover}
                        >
                            {coverUrl ? (
                                <img
                                    src={coverUrl}
                                    alt="image"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

CollectionDetailModal.propTypes = {
    open: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    collection: PropTypes.object,
};

CollectionDetailModal.defaultProps = {
    isProcessing: false,
    open: false,
    collection: null,
};

export default CollectionDetailModal;
