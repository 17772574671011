import {
    Button,
    Divider,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "./slice";
import commonApi from "utils/api";
import { toast } from "react-toastify";

export default function FeeCharge() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { config, isLoading } = useSelector((state) => state.foodConfig);
    const [banks, setBanks] = useState([]);
    const [isMaintenanceModeEnable, setIsMaintenanceModeEnable] =
        useState(false);
    const [isNoteOrderShow, setIsNoteOrderShow] = useState(false);

    useEffect(() => {
        const getBanks = async () => {
            const data = await commonApi.getBanks();
            if (!data || data.error) {
                toast.error(data.error.msg);
            } else {
                setBanks(data);
            }
        };
        dispatch(fetchConfig());
        getBanks();
    }, [dispatch]);

    useEffect(() => {
        form.setFieldsValue(config);
    }, [config]);

    const initialValues = {
        maintenanceMode: {
            enable: false,
            message: "Bạn không thể đặt đơn lúc này do hệ thống đang bảo trì",
        },
        noteOrder: {
            show: false,
            message: "Đơn hàng có thể giao chậm hơn dự kiến vì lý do thời tiết",
        },
        fees: {
            service: 0,
        },
        charge: {
            restaurant: 0,
        },
        order: {
            maxTimeToConfirm: 0,
            maxTimeToPayment: 0,
            maxDistanceToOrder: 0,
        },
        paymentMethod: {
            tooltip: "",
            codAvailable: true,
            codNote: "",
            walletAvailable: true,
            walletNote: "Thanh toán bằng ví CUZI",
            qrCodeAvailable: true,
            qrCodeNote: "Thanh toán bằng mã QR",
            atmAvailable: false,
            atmNote: "Đang bảo trì",
        },
        transfer: {
            enable: false,
            maxAmount: 500000,
            maxCountPerDay: 3,
        },
    };

    const onValueChange = (changedValues, allValues) => {
        if (changedValues.maintenanceMode?.enable !== undefined) {
            setIsMaintenanceModeEnable(allValues.maintenanceMode.enable);
        } else if (changedValues.noteOrder?.show !== undefined) {
            setIsNoteOrderShow(allValues.noteOrder.show);
        }
    };

    const onFinish = (config) => {
        dispatch(updateConfig({ ...config }));
    };

    return (
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 1000 }}
            initialValues={initialValues}
            autoComplete="off"
            onValuesChange={onValueChange}
            onFinish={onFinish}
        >
            <Divider orientation="left">Thiết lập đơn</Divider>
            <Form.Item
                label="Phí dịch vụ (đ)"
                name={["fees", "service"]}
                rules={[{ required: true, message: "Trường này là bắt buộc" }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                label="Chiết khấu quán"
                name={["charge", "restaurant"]}
                rules={[
                    { required: true, message: "Trường này là bắt buộc" },
                    {
                        type: "number",
                        max: 100,
                        message: "Chiết khấu quán không được lớn hơn 100%",
                    },
                ]}
            >
                <InputNumber
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                />
            </Form.Item>
            <Form.Item
                label="Thời gian tối đa quán xác nhận đơn (s)"
                name={["order", "maxTimeToConfirm"]}
                rules={[{ required: true, message: "Trường này là bắt buộc" }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                label="Thời gian tối khách thanh toán (s)"
                name={["order", "maxTimeToPayment"]}
                rules={[{ required: true, message: "Trường này là bắt buộc" }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                label="Khoảng cách tối đa đặt đơn (km)"
                name={["order", "maxDistanceToOrder"]}
                rules={[{ required: true, message: "Trường này là bắt buộc" }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <br />
            <Divider orientation="left">Lưu ý khi đặt đơn</Divider>
            <Form.Item
                label="Hiển thị lưu ý"
                name={["noteOrder", "show"]}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item label="Nội dung" name={["noteOrder", "message"]}>
                <Input disabled={!isNoteOrderShow} />
            </Form.Item>
            <br />
            <Divider orientation="left">Bật/Tắt bảo trì hệ thống</Divider>

            <Form.Item
                label="Chế độ bảo trì"
                name={["maintenanceMode", "enable"]}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Tin nhắn thông báo"
                name={["maintenanceMode", "message"]}
            >
                <Input disabled={!isMaintenanceModeEnable} />
            </Form.Item>
            <br />
            <Divider orientation="left">
                Thiết lập phương thức thanh toán
            </Divider>
            <Form.Item
                label="Ghi chú tooltip"
                name={["paymentMethod", "tooltip"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thanh toán tiền mặt"
                name={["paymentMethod", "codAvailable"]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Mô tả tiền mặt"
                name={["paymentMethod", "codNote"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thanh toán ví"
                name={["paymentMethod", "walletAvailable"]}
            >
                <Switch />
            </Form.Item>
            <Form.Item label="Mô tả ví" name={["paymentMethod", "walletNote"]}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                label="Thanh toán QR code"
                name={["paymentMethod", "qrCodeAvailable"]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Mô tả QR code"
                name={["paymentMethod", "qrCodeNote"]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thanh toán ATM"
                name={["paymentMethod", "atmAvailable"]}
            >
                <Switch defaultChecked={false} disabled />
            </Form.Item>
            <Form.Item label="Mô tả ATM" name={["paymentMethod", "atmNote"]}>
                <Input disabled />
            </Form.Item>
            <br />
            <Divider orientation="left">Tài khoản ngân hàng</Divider>
            <Form.Item name={["banking", "bankId"]} label="Ngân hàng">
                <Select
                    showSearch
                    optionFilterProp="label"
                    options={banks.map((bank) => ({
                        value: bank.id,
                        label: bank.name,
                    }))}
                ></Select>
            </Form.Item>
            <Form.Item name={["banking", "accountNumber"]} label="Số tài khoản">
                <Input />
            </Form.Item>
            <Form.Item name={["banking", "accountName"]} label="Chủ tài khoản">
                <Input />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Cập nhật
                </Button>
            </Form.Item>
        </Form>
    );
}
