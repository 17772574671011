import { createSlice } from "@reduxjs/toolkit";
import orderApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    orders: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const orderSlice = createSlice({
    name: "delivery-order",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addOrder: (state, action) => {
            state.isCompleted = true;
            state.orders.unshift(action.payload);
        },
        editOrder: (state, action) => {
            state.isCompleted = true;
            state.orders = state.orders.map((order) =>
                order._id === action.payload._id ? action.payload : order
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setOrders,
    setPagination,
    addOrder,
    editOrder,
    removeOrder,
} = orderSlice.actions;

export const fetchOrders = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await orderApi.getAll(params);
            dispatch(setOrders(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const updateStatus = (request) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await orderApi.updateStatus(request);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editOrder(data.data));
                toast.success("Thay đổi trạng thái đơn hàng thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default orderSlice.reducer;
