import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Pagination, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import { getPhotoThumbnailUrl, getPhotoUrl } from "utils/common";

export default function SelectPhotoModal(props) {
    const { open, onClose } = props;

    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [photoPagination, setPhotoPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 1,
    });
    const [uploadingPhotos, setUploadingPhotos] = useState(false);
    const [fileList, setFileList] = useState([]);

    const handleChangePhoto = ({ fileList }) => {
        setFileList(fileList);
    };

    const onUploadPhoto = async () => {
        setUploadingPhotos(true);
        const formData = new FormData();
        formData.append("data", JSON.stringify({ xxxx: "xxx" }));
        for (let file of fileList) {
            formData.append("files", file.originFileObj);
        }
        try {
            const res = await commonApi.uploadPhotos(formData);
            if (res) {
                toast.success("Upload ảnh thành công!");
                setFileList([]);
                await fetchPhotos({ current: 1, pageSize: 20 });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setUploadingPhotos(false);
        }
    };

    const fetchPhotos = async (params) => {
        setLoadingPhotos(true);
        try {
            const data = await commonApi.getPhotos(params || photoPagination);
            setPhotos(data.result);
            setPhotoPagination(data.info);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingPhotos(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchPhotos();
        }
    }, [open]);

    const beforeUpload = (file) => false;
    const onChangePhotoPagination = async (current, pageSize) => {
        await fetchPhotos({ current, pageSize });
    };
    const onClickPhoto = async (path) => {
        const _path = getPhotoUrl(path);
        await navigator.clipboard.writeText(_path);
        toast.success("Sao chép URL hình ảnh thành công");
    };

    return (
        <Modal
            title="Thêm ảnh"
            cancelText="Đóng"
            centered
            width={680}
            open={open}
            onCancel={onClose}
        >
            <Upload
                accept={"image/*"}
                multiple={true}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangePhoto}
                beforeUpload={beforeUpload}
            >
                <div>
                    <PlusOutlined />
                    <div className="ant-upload-text">Thêm mới</div>
                </div>
            </Upload>
            {fileList.length > 0 && (
                <Button onClick={onUploadPhoto} loading={uploadingPhotos}>
                    <UploadOutlined /> Upload
                </Button>
            )}
            <Divider />
            <h4>Hình ảnh đã tải lên</h4>
            <small style={{ display: "block", marginBottom: "10px" }}>
                Click vào hình ảnh để copy URL
            </small>
            {loadingPhotos ? (
                <div className="loading-photos">
                    <Spin tip="Loading..." />
                </div>
            ) : (
                <div className="list-photo">
                    {photos.map((photo) => (
                        <div
                            className="picture"
                            key={photo.id}
                            onClick={() => onClickPhoto(photo.path)}
                        >
                            <img
                                src={getPhotoThumbnailUrl(photo?.path)}
                                alt="photo"
                            />
                        </div>
                    ))}
                </div>
            )}

            <Pagination
                defaultCurrent={photoPagination.current}
                total={photoPagination.total}
                pageSize={photoPagination.pageSize}
                onChange={onChangePhotoPagination}
            />
        </Modal>
    );
}
