import { createSlice } from "@reduxjs/toolkit";
import shipperApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    shippers: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const shipperSlice = createSlice({
    name: "delivery-shipper",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setShippers: (state, action) => {
            state.shippers = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addShipper: (state, action) => {
            state.isCompleted = true;
            state.shippers.unshift(action.payload);
        },
        editShipper: (state, action) => {
            state.isCompleted = true;
            state.shippers = state.shippers.map((shipper) =>
                shipper._id === action.payload._id ? action.payload : shipper
            );
        },
        removeShipper: (state, action) => {
            state.isCompleted = true;
            state.shippers = state.shippers.filter(
                (user) => user._id !== action.payload._id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setShippers,
    setPagination,
    addShipper,
    editShipper,
    removeShipper,
} = shipperSlice.actions;

export const fetchShippers = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await shipperApi.getAll(params);
            dispatch(setShippers(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createShipper = (shipper) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await shipperApi.create(shipper);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addShipper(data));
                toast.success("Thêm shipper mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateShipper = (shipper) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await shipperApi.update(shipper);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editShipper(data));
                toast.success("Cập nhật shipper thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteShipper = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await shipperApi.delete(id);
            dispatch(removeShipper(data));
            toast.success("Xóa shipper thành công!");
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default shipperSlice.reducer;
