import {
    EditOutlined,
    PlusOutlined,
    RedoOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Typography } from "antd";
import useViewDetail from "hooks/useViewDetail";
import { useState } from "react";
import { useEffect } from "react";
import { priceToCurrency } from "utils/common";
import orderApi from "../../api";
import DishesModal from "./DishesModal";
import { toast } from "react-toastify";

function OrderEditDrawer(props) {
    const { open, onClose, order } = props;

    const [detail, setDetail] = useState(null);
    const [newDetail, setNewDetail] = useState(null);

    const newDishModal = useViewDetail();

    useEffect(() => {
        const getOrderEditDetail = async () => {
            try {
                const data = await orderApi.getEditDetail(order._id);
                if (data.error) {
                    toast.error(data.error.msg);
                } else {
                    setDetail(data);
                }
            } catch (err) {
                console.log("Có lỗi xảy ra");
            }
        };
        getOrderEditDetail();
    }, [order]);

    const onAddDishesToOrder = (newDishes) => {
        setDetail({
            ...detail,
            order: { ...detail.order, dishes: newDishes },
        });
        newDishModal.onClose();
    };

    const onEdit = () => {
        setNewDetail(null);
        newDishModal.onOpen();
    };

    const onCheckOrder = async () => {
        try {
            const data = await orderApi.checkEdit(
                order._id,
                detail.order.dishes
            );
            if (data.error) {
                toast.error(data.error.msg);
                setNewDetail(null);
            } else {
                setNewDetail(data);
            }
        } catch (err) {
            console.log("Có lỗi xảy ra");
            setNewDetail(null);
        }
    };

    const onSubmitEditOrder = async () => {
        try {
            const data = await orderApi.edit(order._id, detail.order.dishes);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Chỉnh sửa đơn hàng thành công");
            }
        } catch (err) {
            console.log("Có lỗi xảy ra");
        }
    };

    return (
        detail && (
            <>
                <Drawer
                    title="Chi tiết đơn hàng"
                    placement="right"
                    onClose={onClose}
                    open={open}
                    width={500}
                >
                    <h2>Danh sách món ăn ({detail.order.dishCount} món)</h2>
                    <div>
                        {detail.order.dishes.map((dish) => (
                            <div
                                key={dish._id}
                                className="flex gap-x-4 border-0 first:border-t border-b border-solid border-gray-200 p-2"
                            >
                                <div>{dish.quantity}x</div>
                                <div className="flex-grow">
                                    <div>{dish.name}</div>
                                    {dish.customizesSummary && (
                                        <div>
                                            <Typography.Text className="text-xs">
                                                {dish.customizesSummary}
                                            </Typography.Text>
                                        </div>
                                    )}
                                    {dish.note && (
                                        <div>
                                            <Typography.Text className="text-xs">
                                                {dish.note}
                                            </Typography.Text>
                                        </div>
                                    )}
                                </div>
                                <div>{priceToCurrency(dish.totalCost)}</div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4">
                        <div className="flex gap-x-2 justify-end">
                            <Button
                                type="primary"
                                ghost
                                icon={<EditOutlined />}
                                onClick={onEdit}
                            >
                                Chỉnh sửa
                            </Button>
                            <Button
                                type="primary"
                                icon={<RedoOutlined />}
                                onClick={onCheckOrder}
                            >
                                Tính lại đơn
                            </Button>
                        </div>
                        <br />
                        <h3>Trước khi chỉnh sửa:</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="w-full font-semibold">
                                        Tổng tiền món:
                                    </td>
                                    <td className="text-right">
                                        {priceToCurrency(
                                            detail.order.dishFeeActual
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-full font-semibold">
                                        Phí giao hàng:
                                    </td>
                                    <td className="text-right">
                                        {priceToCurrency(
                                            detail.order.deliveryFeeActual
                                        )}
                                    </td>
                                </tr>
                                {detail.order.discount ? (
                                    <tr>
                                        <td className="w-full font-semibold">
                                            Giảm giá:
                                        </td>
                                        <td className="text-right">
                                            {priceToCurrency(
                                                detail.order.discount
                                            )}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td className="w-full font-semibold">
                                        Phí dịch vụ:
                                    </td>
                                    <td className="text-right">
                                        {priceToCurrency(
                                            detail.order.serviceFee || 0
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-full font-semibold">
                                        Tổng tiền:
                                    </td>
                                    <td className="text-right">
                                        <del>
                                            {detail.order.discount
                                                ? priceToCurrency(
                                                      detail.order.totalActual
                                                  )
                                                : ""}
                                        </del>
                                        &nbsp;
                                        {priceToCurrency(detail.order.total)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        {newDetail ? (
                            <>
                                <h3>Sau khi chỉnh sửa:</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="w-full font-semibold">
                                                Tổng tiền món:
                                            </td>
                                            <td className="text-right">
                                                {priceToCurrency(
                                                    newDetail.dishFeeActual
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-full font-semibold">
                                                Phí giao hàng:
                                            </td>
                                            <td className="text-right">
                                                {priceToCurrency(
                                                    newDetail.deliveryFeeActual
                                                )}
                                            </td>
                                        </tr>
                                        {newDetail.discount ? (
                                            <tr>
                                                <td className="w-full font-semibold">
                                                    Giảm giá:
                                                </td>
                                                <td className="text-right">
                                                    {priceToCurrency(
                                                        newDetail.discount
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td className="w-full font-semibold">
                                                Phí dịch vụ:
                                            </td>
                                            <td className="text-right">
                                                {priceToCurrency(
                                                    newDetail.serviceFee || 0
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-full font-semibold">
                                                Tổng tiền:
                                            </td>
                                            <td className="text-right">
                                                <del>
                                                    {newDetail.discount
                                                        ? priceToCurrency(
                                                              newDetail.totalActual
                                                          )
                                                        : ""}
                                                </del>
                                                &nbsp;
                                                {priceToCurrency(
                                                    newDetail.total
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <div className="flex gap-x-2 justify-end">
                                    <Button
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        onClick={onSubmitEditOrder}
                                    >
                                        Cập nhật chỉnh sửa
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </Drawer>
                {newDishModal.open && (
                    <DishesModal
                        open={newDishModal.open}
                        selectedDishes={detail.order.dishes}
                        menus={detail.menus}
                        onClose={newDishModal.onClose}
                        onConfirm={onAddDishesToOrder}
                    />
                )}
            </>
        )
    );
}

export default OrderEditDrawer;
