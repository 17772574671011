import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Image,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import PlaceDetailModal from "./components/DetailModal";
import * as placeActions from "./slice";

const { Paragraph } = Typography;

export default function Place() {
    const { places, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.tuktukPlace);
    useEffect(() => {
        dispatch(placeActions.fetchPlaces(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingPlace(null);
        }
    }, [isCompleted]);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingPlace, setSelectingPlace] = useState(null);
    const [searchName, setSearchName] = useState("");

    const dispatch = useDispatch();

    const onTableChange = async (pagination) => {
        dispatch(
            placeActions.fetchPlaces({
                ...pagination,
                name: searchName,
            })
        );
    };

    const onSubmit = (place, formData) => {
        if (!place._id) {
            dispatch(placeActions.createPlace(formData));
        } else {
            dispatch(placeActions.updatePlace(place, formData));
        }
    };

    const onCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectingPlace(null);
        dispatch(placeActions.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            placeActions.fetchPlaces({
                ...pagination,
                name: searchName,
                current: 1,
            })
        );
    };

    const onOpenDetailModal = (place = null) => {
        setSelectingPlace(place);
        setOpenDetailModal(true);
    };

    const onDelete = (place) => {
        Modal.confirm({
            title: `Xác nhận xóa place`,
            content: `Bạn có chắc chắn muốn xóa place này?`,
            onOk: () => dispatch(placeActions.deletePlace(place._id)),
            centered: true,
        });
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Paragraph>
            ),
        },
        {
            title: "Tên",
            // width: "10%",
            dataIndex: "name",
        },
        {
            title: "Hình ảnh",
            width: "220px",
            dataIndex: "image",
            render: (image) => (
                <Image
                    alt="Place image"
                    src={getPhotoUrl(image?.path || null)}
                    width="150px"
                    height="150px"
                />
            ),
        },
        {
            title: "Thứ tự",
            dataIndex: "priority",
            width: "15%",
            align: "center",
        },
        {
            title: "Hiển thị",
            dataIndex: "inHome",
            align: "center",
            width: "15%",
            render: (inHome) =>
                inHome ? (
                    <Tag color="#4caf50">Hiện</Tag>
                ) : (
                    <Tag color="#f44336">Ẩn</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "20%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];
    return (
        <>
            <Row align="middle" justify="space-between">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Địa điểm Hot
                </Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={places}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <PlaceDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCloseDetailModal}
                    place={selectingPlace}
                />
            )}
        </>
    );
}
