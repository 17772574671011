import { createSlice } from "@reduxjs/toolkit";
import voucherApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    vouchers: [],
    totalVerify: 0,
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const voucherSlice = createSlice({
    name: "food-voucher",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setVouchers: (state, action) => {
            state.vouchers = action.payload;
        },
        setTotalVerifyCount: (state, action) => {
            state.totalVerify = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addVoucher: (state, action) => {
            state.isCompleted = true;
            state.vouchers.unshift(action.payload);
        },
        editVoucher: (state, action) => {
            state.isCompleted = true;
            state.vouchers = state.vouchers.map((voucher) =>
                voucher._id === action.payload._id ? action.payload : voucher
            );
        },
        removeVoucher: (state, action) => {
            state.isCompleted = true;
            state.vouchers = state.vouchers.filter(
                (voucher) => voucher._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setTotalVerifyCount,
    setVouchers,
    setPagination,
    addVoucher,
    editVoucher,
    removeVoucher,
} = voucherSlice.actions;

export const fetchVouchers = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await voucherApi.getAll(params);
            dispatch(
                setVouchers(data.data.vouchers ? data.data.vouchers : data.data)
            );
            dispatch(setTotalVerifyCount(data.data?.totalVerify || 0));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createVoucher = (voucher) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await voucherApi.create(voucher);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addVoucher(data));
                toast.success("Thêm voucher mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateVoucher = (voucher) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await voucherApi.update(voucher);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editVoucher(data));
                toast.success("Cập nhật voucher thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteVoucher = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await voucherApi.delete(id);
            if (data.success) {
                dispatch(removeVoucher(id));
                toast.success("Xóa voucher thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa voucher thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const verifyVoucher = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompleted(false));
            const data = await voucherApi.verify(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(setIsCompleted(true));
                toast.success("Duyệt voucher thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const rejectVoucher = (id, reason) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompleted(false));
            const data = await voucherApi.reject(id, reason);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(setIsCompleted(true));
                toast.success("Từ chối voucher thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default voucherSlice.reducer;
