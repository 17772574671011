import { Avatar, Col, Form, Input, Row, Space, Tag } from "antd";
import moment from "moment";

export default function TabInfo({ user, imageUrl }) {
    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="name" label="Họ tên">
                        <Space size="small">
                            {imageUrl ? (
                                <Avatar
                                    src={imageUrl}
                                    alt="avatar of the user"
                                />
                            ) : (
                                <Avatar
                                    className="select-none"
                                    alt="Avatar of user"
                                    style={{ backgroundColor: "#87d068" }}
                                >
                                    {user.name[0].toUpperCase()}
                                </Avatar>
                            )}
                            <span>{user.name}</span>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="phone" label="Số điện thoại">
                        <Tag>{user.phone}</Tag>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={["building", "name"]} label="Tòa nhà">
                        <Tag>{user.building?.name || ""}</Tag>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="email" label="Email">
                        <Tag>{user.email || "Chưa cập nhật"}</Tag>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="emailVerified" label="Xác thực email">
                        <Tag color={user.emailVerified ? "#4caf50" : "#f44336"}>
                            {user.emailVerified
                                ? "Đã xác thực"
                                : "Chưa xác thực"}
                        </Tag>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}></Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="createdAt" label="Ngày tham gia">
                        <Tag>
                            {moment(user.createdAt).format("DD/MM/YYYY HH:mm")}
                        </Tag>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="active" label="Trạng thái">
                        <Tag color={user.active ? "#4caf50" : "#f44336"}>
                            {user.active ? "Hoạt động" : "Không hoạt động"}
                        </Tag>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
