import axiosClient from "utils/api/axios-client";

const api = {
    getAll: (params) => {
        const url = "/api/v1/food/ad-types";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/food/ad-types/${id}`;
        return axiosClient.get(url);
    },
    create: (adType) => {
        const url = `/api/v1/food/ad-types/`;
        return axiosClient.post(url, adType);
    },
    update: (adType) => {
        const url = `/api/v1/food/ad-types/${adType._id}`;
        return axiosClient.put(url, adType);
    },
    delete: (id) => {
        const url = `/api/v1/food/ad-types/${id}`;
        return axiosClient.delete(url);
    },
};

export default api;
