import { Form, Input, Modal } from "antd";

export default function UpdateUsermodal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, order } = props;

    const initialValues = {
        _id: order?.user?._id,
        user: `${order?.user?.name} - ${order?.user?.phone}`,
        ticket: order?.user?.tuktuk?.ticket || 0,
        counter: order?.user?.tuktuk?.counter || 0,
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const data = { ...initialValues, ...values };
                onSubmit(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Cập nhật thông tin khách hàng"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item name="user" label="ID khách hàng">
                    <Input readOnly />
                </Form.Item>
                <Form.Item name="ticket" label="Số lượt còn lại">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="counter" label="Bộ đếm lượt Đi 5 tặng 1">
                    <Input type={"number"} min={0} />
                </Form.Item>
            </Form>
        </Modal>
    );
}
