/* eslint-disable no-undef */
export const API_PATH = process.env.REACT_APP_API_URL;
export const CDN_PATH = process.env.REACT_APP_CDN;

export const PRIMARY_COLOR = "#FFB534";

export const HTTP_METHODS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
};

export const ADMIN_ROLES = {
    SUPER_ADMIN: "super_admin",
    ADMIN: "admin",
    CUSTOMER_SERVICE: "customer_service",
};

export const ACCOUNT_ROLES = {
    SUPER_ADMIN: "super_admin",
    ADMIN: "admin",
    CUSTOMER_SERVICE: "customer_service",
};

export const DISH_STATUSES = {
    AVAILABLE: "available",
    UNAVAILABLE: "unavailable",
    SOLD_OUT: "sold-out",
};

export const DISH_STATUS_LABEL = {
    [DISH_STATUSES.AVAILABLE]: "Có sẵn",
    [DISH_STATUSES.UNAVAILABLE]: "Không khả dụng",
    [DISH_STATUSES.SOLD_OUT]: "Hết hàng",
};

export const formItemLayoutHorizontal = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

export const BANKS = [
    {
        label: "Techcombank",
        value: "Techcombank",
        acronym: "TCB",
    },
    {
        label: "Vietcombank",
        value: "Vietcombank",
        acronym: "VCB",
    },
    {
        label: "TPBank",
        value: "TPBank",
        acronym: "TPB",
    },
    {
        label: "Agribank",
        value: "Agribank",
        acronym: "TPB",
    },
    {
        label: "MB Bank",
        value: "MB Bank",
        acronym: "MB",
    },
    {
        label: "ACB (Á Châu)",
        value: "ACB (Á Châu)",
        acronym: "ACB",
    },
    {
        label: "VietinBank",
        value: "VietinBank",
        acronym: "VTB",
    },
    {
        label: "VIB",
        value: "VIB",
        acronym: "VIB",
    },
    {
        label: "Nam A Bank",
        value: "Nam A Bank",
        acronym: "NAB",
    },
    {
        label: "Sacombank",
        value: "Sacombank",
        acronym: "SCB",
    },
    {
        label: "BIDV",
        value: "BIDV",
        acronym: "BIDV",
    },
    {
        label: "HDBank",
        value: "HDBank",
        acronym: "HDB",
    },
    {
        label: "Eximbank",
        value: "Eximbank",
        acronym: "EXB",
    },
    {
        label: "VPBank",
        value: "VPBank",
        acronym: "VPB",
    },
];

export const defaultGeolocation = {
    lat: 21.016621,
    long: 105.814932,
};

export const MAPBOX_TOKEN = `pk.eyJ1IjoiaG9haXBoYW4iLCJhIjoiY2s4NjRuNm40MGUweDNwcGZ4azZhMTA1MCJ9.rExJxHGtG7JXWJwEjJTTCg`;

export const MAP_STYLE = `mapbox://styles/hoaiphan/ck864r8qf05om1inqvmp3phk4`;

export const mapboxStyles = {
    Basic: "mapbox://styles/hoaiphan/ck864r8qf05om1inqvmp3phk4",
    Street: "mapbox://styles/hoaiphan/ck879yu1t0xnh1iqlc4pwgbcd",
    Decimal: "mapbox://styles/hoaiphan/ck865rxlz01421imkp9e4k7rm",
    Light: "mapbox://styles/mapbox/light-v10",
};

export const TUKTUK_ORDER_PAYMENT_METHODS = {
    cash: {
        label: "Tiền mặt",
        color: "gold",
    },
    banking: {
        label: "Chuyển khoản",
        color: "cyan",
    },
    ticket: {
        label: "Trừ lượt",
        color: "blue",
    },
};

export const REQUEST_STATUS = {
    done: {
        value: "done",
        label: "Đã nhận",
        color: "green",
    },
    new: {
        value: "new",
        label: "Đang chờ",
        color: "gold",
    },
    canceled: {
        value: "canceled",
        label: "Hủy",
        color: "red",
    },
};

export const COMBO_TRANSACTION_STATUS = {
    processing: {
        value: "processing",
        label: "Đang xử lý",
        color: "gold",
    },
    success: {
        value: "success",
        label: "Thành công",
        color: "green",
    },
    fail: {
        value: "fail",
        label: "Thất bại",
        color: "red",
    },
};

export const WALLET_REQUEST_STATUS = {
    processing: {
        value: "processing",
        label: "Đang xử lý",
        color: "gold",
    },
    success: {
        value: "success",
        label: "Thành công",
        color: "green",
    },
    fail: {
        value: "fail",
        label: "Thất bại",
        color: "red",
    },
};

export const DELIVERY_STATUS = {
    finding: { state: "Đang tìm tài xế", color: "volcano", value: "finding" },
    "going-to-restaurant": {
        state: "Đang đến quán",
        color: "magenta",
        value: "going-to-restaurant",
    },
    "arrived-restaurant": {
        state: "Đã đến quán",
        color: "cyan",
        value: "arrived-restaurant",
    },
    "picked-up": { state: "Đã lấy hàng", color: "green", value: "picked-up" },
};
