import { Button, Col, Descriptions, Drawer, Row, Typography } from "antd";
import { flatten } from "flat";
import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";
import { priceToCurrency } from "utils/common";
import orderApi from "../api";

function OrderDetailDrawer(props) {
    const { open, onClose, order, onViewCost } = props;

    const [detail, setDetail] = useState(null);

    useEffect(() => {
        const getOrderDetail = async () => {
            const data = await orderApi.getDetail(order._id);
            setDetail({ ...flatten(data), dishes: data.dishes });
        };
        getOrderDetail();
    }, [order]);

    const detailFields = [
        {
            key: "code",
            label: "Mã đơn",
            labelStyle: {
                fontSize: "18px",
            },
            contentStyle: {
                fontSize: "18px",
            },
        },
        {
            key: "status",
            label: "Trạng thái",
        },
        {
            key: "routes.customer.name",
            label: "Khách hàng",
        },
        {
            key: "routes.customer.phone",
            label: "SĐT",
        },
        {
            key: "routes.customer.address",
            label: "Địa chỉ",
            fullWidth: true,
        },
        {
            key: "routes.restaurant.name",
            label: "Quán",
        },
        {
            key: "routes.restaurant.phone",
            label: "SĐT quán",
        },
        {
            key: "routes.restaurant.address",
            label: "Địa chỉ quán",
            fullWidth: true,
        },
        {
            key: "shipper.name",
            label: "Tài xế",
        },
        {
            key: "shipper.phone",
            label: "SĐT tài xế",
        },
        {
            key: "distance",
            label: "Khoảng cách",
            render: (detailOrder) => `${detailOrder.distance}km`,
        },
        {
            key: "orderedAt",
            label: "Thời gian dặt hàng",
            render: (detailOrder) =>
                moment(detailOrder.orderedAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            key: "note",
            label: "Ghi chú",
            fullWidth: true,
        },
        {
            key: "paymentMethod",
            label: "Phương thức thanh toán",
            fullWidth: true,
        },
    ];

    return (
        detail && (
            <Drawer
                title="Chi tiết đơn hàng"
                placement="right"
                onClose={onClose}
                open={open}
                width={500}
            >
                <Descriptions title="Thông tin đơn hàng">
                    {detailFields.map((field) => (
                        <Descriptions.Item
                            label={field.label}
                            key={field.key}
                            span={field.fullWidth ? 24 : 12}
                            labelStyle={{
                                ...(field.labelStyle || {}),
                                fontWeight: 600,
                            }}
                            contentStyle={{ ...(field.contentStyle || {}) }}
                        >
                            {field.render
                                ? field.render(detail)
                                : detail[field.key]}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
                <h2>Danh sách món ăn ({detail.dishCount} món)</h2>
                <div>
                    {detail.dishes.map((dish) => (
                        <div
                            key={dish._id}
                            className="flex gap-x-4 border-0 first:border-t border-b border-solid border-gray-200 p-2"
                        >
                            <div>{dish.quantity}x</div>
                            <div className="flex-grow">
                                <div>{dish.name}</div>
                                {dish.customizesSummary && (
                                    <div>
                                        <Typography.Text className="text-xs">
                                            {dish.customizesSummary}
                                        </Typography.Text>
                                    </div>
                                )}
                                {dish.note && (
                                    <div>
                                        <Typography.Text className="text-xs">
                                            {dish.note}
                                        </Typography.Text>
                                    </div>
                                )}
                            </div>
                            <div>{priceToCurrency(dish.totalCost)}</div>
                        </div>
                    ))}
                </div>
                <div className="mt-4">
                    <table>
                        <tbody>
                            <tr>
                                <td className="w-full font-semibold">
                                    Tổng tiền món:
                                </td>
                                <td className="text-right">
                                    {priceToCurrency(detail.dishFeeActual)}
                                </td>
                            </tr>
                            <tr>
                                <td className="w-full font-semibold">
                                    Phí giao hàng:
                                </td>
                                <td className="text-right">
                                    {priceToCurrency(detail.deliveryFeeActual)}
                                </td>
                            </tr>
                            <tr>
                                <td className="w-full font-semibold">
                                    Tổng tiền:
                                </td>
                                <td className="text-right">
                                    {priceToCurrency(detail.totalActual)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Button
                    type="primary"
                    className="float-right mt-4"
                    onClick={onViewCost}
                >
                    Xem chi tiết phí
                </Button>
            </Drawer>
        )
    );
}

export default OrderDetailDrawer;
