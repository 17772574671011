import { useEffect, useState } from "react";
import { Button, Modal, Space } from "antd";
import MenuItem from "./Item";
import { useDispatch, useSelector } from "react-redux";
import MenuInfoModal from "./MenuInfoModal";
import DishDetailModal from "./DishDetailModal";
import restaurantApi from "../../../api";
import * as restaurantActions from "../../../slice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { classNames } from "utils/common";
import DishOfMenuModal from "./DishOfMenuModal";
import { toast } from "react-toastify";

export default function TabMenu({ restaurant, onClose }) {
    const [openMenuModal, setOpenMenuModal] = useState(false);
    const [openDishModal, setOpenDishModal] = useState(false);
    const [openDishOfMenuModal, setOpenDishOfMenuModal] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [selectedDish, setSelectedDish] = useState(null);
    const [isEditingMenuOrder, setIsEditingMenuOrder] = useState(false);
    const [tempMenusForOrdering, setTempMenusForOrdering] = useState([]);
    const {
        menuInfo: { menus, dishes },
        isCompletedUpdatingDish,
        isCompletedUpdatingMenu,
    } = useSelector((state) => state.foodRestaurant);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isCompletedUpdatingDish) {
            onCloseDishDetailModal();
            dispatch(restaurantActions.setIsCompletedUpdatingDish(false));
        }
    }, [isCompletedUpdatingDish]);

    useEffect(() => {
        if (isCompletedUpdatingMenu) {
            onCloseMenuInfoModal();
            dispatch(restaurantActions.setIsCompletedUpdatingMenu(false));
        }
    }, [isCompletedUpdatingMenu]);

    const onOpenMenuModal = (menu = null) => {
        setOpenMenuModal(true);
        setSelectedMenu(menu);
    };

    const onOpenDishOfMenuModal = (menu = null) => {
        setOpenDishOfMenuModal(true);
        setSelectedMenu(menu);
    };

    const onCloseMenuInfoModal = () => {
        setOpenMenuModal(false);
        if (selectedMenu) {
            setSelectedMenu(null);
        }
    };

    const onOpenDishDetailModal = (dish = null) => {
        setOpenDishModal(true);
        setSelectedDish(dish);
    };

    const onCloseDishDetailModal = () => {
        setOpenDishModal(false);
        if (selectedDish) {
            setSelectedDish(null);
        }
    };

    const onCloseDishOfMenuModal = () => {
        setOpenDishOfMenuModal(false);
        if (selectedMenu) {
            setSelectedMenu(null);
        }
    };

    const onSubmitDish = (dish, formData) => {
        if (dish._id) {
            dispatch(restaurantActions.updateDish({ dish, formData }));
        } else {
            dispatch(restaurantActions.createDish(formData));
        }
    };

    const onApplyDiscount = async (discount) => {
        try {
            const data = await restaurantApi.updateDiscount(
                restaurant._id,
                discount
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Câp nhật giảm giá thành công");
                setOpenDishModal(false);
                setOpenMenuModal(false);
                setOpenDishOfMenuModal(false);
                onClose();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onApplySellTime = async (sellTime) => {
        try {
            const data = await restaurantApi.updateSellTime(
                restaurant._id,
                sellTime
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Câp nhật thời gian bán thành công");
                setOpenDishModal(false);
                setOpenMenuModal(false);
                setOpenDishOfMenuModal(false);
                onClose();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onSubmitMenu = (menu) => {
        if (menu._id) {
            dispatch(restaurantActions.updateMenu(menu));
        } else {
            dispatch(restaurantActions.createMenu(menu));
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            tempMenusForOrdering,
            result.source.index,
            result.destination.index
        );
        setTempMenusForOrdering(items);
    };

    const startEditingMenuOrder = () => {
        setTempMenusForOrdering([...menus]);
        setIsEditingMenuOrder(true);
    };

    const confirmEditingMenuOrder = () => {
        setIsEditingMenuOrder(false);
        dispatch(restaurantActions.updateMenusOrder(tempMenusForOrdering));
    };

    const onDeleteMenu = (menu) => {
        const dishesInMenu = dishes.filter((dish) => dish.menu === menu._id);
        if (dishesInMenu.length > 0) {
            Modal.warn({
                title: "Cảnh báo",
                content:
                    "Menu hiện đang có món ăn. Bạn cần chuyển các món ăn sang menu khác trước khi tiến hành xóa",
                centered: true,
            });
        } else {
            Modal.confirm({
                title: `Xác nhận xoá menu`,
                content: `Bạn có chắc chắn muốn xoá menu này?`,
                onOk: () => dispatch(restaurantActions.deleteMenu(menu._id)),
                centered: true,
            });
        }
    };

    return (
        <>
            <Space direction="vertical" size="middle" className="w-full">
                <Space size="middle" className="w-full justify-end">
                    <Button type="primary" onClick={() => onOpenMenuModal()}>
                        Thêm danh mục
                    </Button>
                    <Button onClick={() => onOpenDishDetailModal()}>
                        Thêm món ăn
                    </Button>
                </Space>
                <Space className="float-right" size="middle">
                    {isEditingMenuOrder ? (
                        <>
                            <Button
                                type="primary"
                                onClick={confirmEditingMenuOrder}
                            >
                                Xác nhận
                            </Button>
                            <Button
                                onClick={() => setIsEditingMenuOrder(false)}
                            >
                                Hủy
                            </Button>
                        </>
                    ) : (
                        <Button onClick={startEditingMenuOrder}>
                            Sửa thứ tự
                        </Button>
                    )}
                </Space>
                <Space
                    size="middle"
                    direction="vertical"
                    className={classNames(
                        "w-full",
                        isEditingMenuOrder &&
                            "border border-gray-300 border-solid p-4 box-border"
                    )}
                >
                    <MenuItem
                        numberOfDish={dishes.length}
                        onViewDishes={onOpenDishOfMenuModal}
                    />
                    {menus.length > 0 && (
                        <>
                            {isEditingMenuOrder ? (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                <Space
                                                    size="middle"
                                                    direction="vertical"
                                                    className="w-full"
                                                >
                                                    {tempMenusForOrdering.map(
                                                        (item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={
                                                                    item.id
                                                                }
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <MenuItem
                                                                            menu={
                                                                                item
                                                                            }
                                                                            onEdit={
                                                                                onOpenMenuModal
                                                                            }
                                                                            onDelete={
                                                                                onDeleteMenu
                                                                            }
                                                                            numberOfDish={
                                                                                dishes.filter(
                                                                                    (
                                                                                        dish
                                                                                    ) =>
                                                                                        dish.menu ===
                                                                                        item._id
                                                                                )
                                                                                    .length
                                                                            }
                                                                            onViewDishes={
                                                                                onOpenDishOfMenuModal
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}
                                                </Space>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            ) : (
                                <Space
                                    size="middle"
                                    direction="vertical"
                                    className="w-full"
                                >
                                    {menus.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            menu={item}
                                            onEdit={onOpenMenuModal}
                                            onDelete={onDeleteMenu}
                                            numberOfDish={
                                                dishes.filter(
                                                    (dish) =>
                                                        dish.menu === item._id
                                                ).length
                                            }
                                            onViewDishes={onOpenDishOfMenuModal}
                                        />
                                    ))}
                                </Space>
                            )}
                        </>
                    )}
                </Space>
            </Space>
            {openMenuModal && (
                <MenuInfoModal
                    open={openMenuModal}
                    menu={selectedMenu}
                    onCancel={onCloseMenuInfoModal}
                    onSubmit={onSubmitMenu}
                    restaurant={restaurant}
                />
            )}
            {openDishModal && (
                <DishDetailModal
                    open={openDishModal}
                    onApplyDiscount={onApplyDiscount}
                    onApplySellTime={onApplySellTime}
                    onCancel={onCloseDishDetailModal}
                    onSubmit={onSubmitDish}
                    dish={selectedDish}
                    restaurant={restaurant}
                    menu={selectedMenu}
                />
            )}
            {openDishOfMenuModal && (
                <DishOfMenuModal
                    open={openDishOfMenuModal}
                    menu={selectedMenu}
                    onCancel={onCloseDishOfMenuModal}
                    onOpenDishModal={onOpenDishDetailModal}
                />
            )}
        </>
    );
}
