import { createSlice } from "@reduxjs/toolkit";
import driverApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    drivers: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const driverSlice = createSlice({
    name: "delivery-driver",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setDrivers: (state, action) => {
            state.drivers = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addDriver: (state, action) => {
            state.isCompleted = true;
            state.drivers.unshift(action.payload);
        },
        editDriver: (state, action) => {
            state.isCompleted = true;
            state.drivers = state.drivers.map((driver) =>
                driver._id === action.payload._id ? action.payload : driver
            );
        },
        removeDriver: (state, action) => {
            state.isCompleted = true;
            state.drivers = state.drivers.filter(
                (user) => user._id !== action.payload._id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setDrivers,
    setPagination,
    addDriver,
    editDriver,
    removeDriver,
} = driverSlice.actions;

export const fetchDrivers = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await driverApi.getAll(params);
            dispatch(setDrivers(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createDriver = (driver) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await driverApi.create(driver);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addDriver(data));
                toast.success("Thêm tài xế mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateDriver = (driver) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await driverApi.update(driver);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editDriver(data));
                toast.success("Cập nhật tài xế thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteDriver = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await driverApi.delete(id);
            dispatch(removeDriver(data));
            toast.success("Xóa tài xế thành công!");
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default driverSlice.reducer;
