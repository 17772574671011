import {
    DatePicker,
    Divider,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_STATUS } from "utils/constants";
import Actions from "./components/Actions";
import ChangeStatusModal from "./components/ChangeStatus";
import * as requestActions from "./slice";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const RANGE_PRESETS = [
    {
        label: "Hôm nay",
        value: [dayjs(), dayjs()],
    },
    {
        label: "Hôm qua",
        value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
        label: "Tuần này",
        value: [dayjs().startOf("week"), dayjs()],
    },
    {
        label: "Tuần trước",
        value: [
            dayjs().subtract(1, "week").startOf("week"),
            dayjs().subtract(1, "week").endOf("week"),
        ],
    },
    {
        label: "Tháng này",
        value: [dayjs().startOf("month"), dayjs()],
    },
    {
        label: "Tháng trước",
        value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
        ],
    },
    {
        label: "Tất cả",
        value: [dayjs("13/10/2024", "DD/MM/YYYY"), dayjs()],
    },
];

function Request() {
    const dispatch = useDispatch();
    const { requests, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.tuktukRequest);

    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectingRequest, setSelectingRequest] = useState(null);
    const [searchValue, setSearchValue] = useState({
        status: "",
        range: [dayjs(), dayjs()],
        from: dayjs().format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });
    const [searchState, setSearchState] = useState({
        status: "",
        range: [dayjs(), dayjs()],
        from: dayjs().format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });

    useEffect(() => {
        dispatch(
            requestActions.fetchRequests({
                ...pagination,
                ...searchValue,
            })
        );
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenChangeStatusModal(false);
            setSelectingRequest(null);
        }
    }, [isCompleted]);

    const onChangeSearchValue = (property, value, refresh) => {
        setSearchValue((prev) => ({ ...prev, [property]: value }));
        if (refresh) {
            setSearchState((prev) => ({ ...prev, [property]: value }));
            dispatch(
                requestActions.fetchRequests({
                    ...pagination,
                    ...searchValue,
                    [property]: value,
                })
            );
        }
    };

    const onChangeSearchRange = (dates) => {
        setSearchValue((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
        setSearchState((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
        dispatch(
            requestActions.fetchRequests({
                ...pagination,
                ...searchValue,
                from: dates[0].format("YYYY-MM-DD"),
                to: dates[1].format("YYYY-MM-DD"),
            })
        );
    };
    const onOpenChangeStatus = (request) => {
        setSelectingRequest(request);
        setOpenChangeStatusModal(true);
    };

    const onChangeStatus = (request) => {
        dispatch(requestActions.updateRequestStatus(request));
    };

    const onCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
        setSelectingRequest(null);
        dispatch(requestActions.setIsProcessing(false));
    };

    const onTableChange = async (pagination) => {
        dispatch(
            requestActions.fetchRequests({
                ...pagination,
                ...searchState,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Khách hàng",
            render: (record) =>
                record.user ? (
                    <>
                        <Typography.Text>{record.user.name}</Typography.Text>
                        <Typography.Paragraph>
                            {record.user.phone}
                        </Typography.Paragraph>
                    </>
                ) : (
                    <>
                        <Typography.Text>GUEST</Typography.Text>
                        {record.phone && (
                            <Typography.Paragraph>
                                {record.phone}
                            </Typography.Paragraph>
                        )}
                    </>
                ),
        },
        {
            title: "Điểm đón",
            dataIndex: "from",
        },
        {
            title: "Điểm đến",
            dataIndex: "to",
        },
        {
            title: "Số người",
            dataIndex: "quantity",
        },
        {
            title: "Xe nhận",
            render: (record) =>
                record.driver ? (
                    <>
                        <Typography.Paragraph>
                            {record.driver.name}
                        </Typography.Paragraph>
                        [<Typography.Text>{record.vehicle}</Typography.Text>]
                    </>
                ) : (
                    <></>
                ),
        },
        {
            title: "Xe nhận",
            dataIndex: "vehicle",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (status) => (
                <Tag color={REQUEST_STATUS[status]?.color || "default"}>
                    {REQUEST_STATUS[status]?.label || "Không xác định"}
                </Tag>
            ),
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Thao tác",
            align: "center",
            render: (record) => (
                <Actions record={record} onEdit={onOpenChangeStatus} />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Yêu cầu gọi xe
                </Typography.Title>
                <Space size="middle">
                    <RangePicker
                        presets={RANGE_PRESETS}
                        value={searchValue.range}
                        onChange={onChangeSearchRange}
                        style={{ width: "250px" }}
                    />
                    <Select
                        onChange={(value) =>
                            onChangeSearchValue("status", value, true)
                        }
                        value={searchValue.status}
                        options={[
                            {
                                value: "",
                                label: "Tất cả",
                            },
                            ...Object.values(REQUEST_STATUS),
                        ]}
                        style={{ width: "140px" }}
                    />
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={requests}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openChangeStatusModal && (
                <ChangeStatusModal
                    open={openChangeStatusModal}
                    onCancel={onCloseChangeStatusModal}
                    comboTransaction={selectingRequest}
                    onSubmit={onChangeStatus}
                    isProcessing={isProcessing}
                />
            )}
        </>
    );
}

export default Request;
