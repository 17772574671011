import { toast } from "react-toastify";
import axiosClient from "../../utils/api/axios-client";

export const login = async ({ username, password }) => {
    try {
        const data = await axiosClient.post("/api/v1/login", {
            username,
            password,
        });
        if (data.error) {
            throw data.error.msg;
        }
        const token = data.accessToken;
        return token;
    } catch (e) {
        console.error(e);
        toast.error(e);
        return false;
    }
};

export const changePassword = async ({ oldPassword, newPassword }) => {
    try {
        const data = await axiosClient.put("/api/v1/change-password", {
            oldPassword,
            newPassword,
        });
        if (data.error) {
            throw data.error.msg;
        }
        return true;
    } catch (e) {
        console.error(e);
        toast.error(e);
        return false;
    }
};
