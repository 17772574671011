import { Button, Divider, Empty, Input, Modal, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import eventApi from "./api";

export default function Event() {
    const [dishId, setDishId] = useState("");
    const [dishName, setDishName] = useState("");
    const [users, setUsers] = useState([]);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [isLoadingStop, setIsLoadingStop] = useState(false);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const data = await eventApi.getEventData();
                if (data.error) {
                    toast.error(data.error);
                } else {
                    const { dishId, dishName, users } = data;
                    setDishId(dishId);
                    setUsers(users);
                    setDishName(dishName);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchEvent();
    }, []);

    const onChangeDish = (e) => {
        setDishId(e.target.value);
    };

    const onConfirmUpdate = async () => {
        if (dishName) {
            Modal.confirm({
                title: `Xác nhận ${dishName ? "dổi món" : "bật chương trình"}`,
                content: `${
                    dishName ? "Đổi món" : "Bật chương trình"
                } chương trình sẽ xóa tất cả danh sách khách hàng đã đặt món cũ?`,
                onOk: () => onUpdate(),
                centered: true,
            });
        } else {
            await onUpdate();
        }
    };

    const onUpdate = async () => {
        if (!dishId) {
            toast.error("ID món là bắt buộc!");
            return;
        }
        try {
            setIsLoadingUpdate(true);
            const data = await eventApi.update(dishId);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                setDishName(data.dishName);
                setUsers([]);
                toast.success("Cập nhật chương trình thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const onConfirmStop = () => {
        Modal.confirm({
            title: `Xác nhận dừng chương trình`,
            content: `Dừng chương trình sẽ xóa tất cả danh sách khách hàng đã đặt món này?`,
            onOk: () => onStop(),
            centered: true,
        });
    };

    const onStop = async () => {
        try {
            setIsLoadingStop(true);
            const data = await eventApi.stop();
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                setDishId(null);
                setDishName(null);
                toast.success("Dừng chương trình thành công");
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingStop(false);
        }
    };

    return (
        <>
            <Divider orientation="left">Chương trình 1 khách - 1 món</Divider>
            <Space>
                <Input
                    value={dishId}
                    placeholder="ID món"
                    onChange={onChangeDish}
                    style={{ width: "300px" }}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingUpdate}
                    onClick={onConfirmUpdate}
                >
                    {dishName ? "Đổi món" : "Bật chương trình"}
                </Button>
                <Button
                    danger
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingStop}
                    onClick={onConfirmStop}
                    disabled={!dishName}
                >
                    Dừng chương trình
                </Button>
            </Space>
            <br />
            <br />
            {dishName && (
                <div style={{ width: "600px" }}>
                    <b>
                        Món đang chạy:{" "}
                        <Tag bordered={false} color="cyan">
                            {dishName}
                        </Tag>
                    </b>
                    <br />
                    <br />
                    <b>Danh sách khách đã đặt món ({users.length}):</b>
                    <br />
                    <ul>
                        {users.length
                            ? users.map((user) => (
                                  <li
                                      style={{ marginBottom: "14px" }}
                                      key={user._id}
                                  >
                                      {user.name} - {user.phone}
                                  </li>
                              ))
                            : ""}
                    </ul>
                    <br />
                </div>
            )}
        </>
    );
}
