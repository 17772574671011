import { Form, Input, Modal, Select } from "antd";
import { formItemLayoutHorizontal } from "utils/constants";

const { Option } = Select;

function AccountDetailModal({
    open,
    isProcessing,
    onSubmit,
    onCancel,
    building,
}) {
    const [form] = Form.useForm();

    const initialValues = building || {
        name: "",
        priority: "",
        active: true,
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => onSubmit({ ...initialValues, ...values }))
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Tòa nhà"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={720}
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Tên đầy đủ" />
                </Form.Item>
                <Form.Item
                    name="priority"
                    label="Thứ tự ưu tiên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Thứ tự ưu tiên" />
                </Form.Item>

                <Form.Item name="active" label="Trạng thái">
                    <Select>
                        <Option value={true}>Active</Option>
                        <Option value={false}>In-active</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AccountDetailModal;
