import { DownOutlined, EditOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { WALLET_REQUEST_STATUS } from "utils/constants";

export default function Actions({ record, onEdit, onShowQr, editDisabled }) {
    const KEY_ACTIONS = {
        EDIT: "edit",
        SHOW_QR: "show_qr",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onEdit(record);
                break;
            case KEY_ACTIONS.SHOW_QR:
                onShowQr(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa trạng thái",
            icon: <EditOutlined />,
            disabled: editDisabled,
        },
    ];

    if (record.status === WALLET_REQUEST_STATUS.processing.value && record.qr) {
        actions.push({
            key: KEY_ACTIONS.SHOW_QR,
            label: "Hiện VietQR",
            icon: <QrcodeOutlined />,
        });
    }

    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
