import { Button, Divider, Form, Input } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig, updateConfig } from "./slice";

const { TextArea } = Input;

export default function FeeCharge() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { config, isLoading } = useSelector((state) => state.deliveryConfig);

    useEffect(() => {
        dispatch(fetchConfig());
    }, [dispatch]);

    useEffect(() => {
        form.setFieldsValue(config);
    }, [config]);

    const initialValues = {
        deleveryFee: "",
        buyForFree: "",
    };

    const onFinish = (config) => {
        dispatch(updateConfig({ ...config }));
    };

    return (
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 1000 }}
            initialValues={initialValues}
            autoComplete="off"
            onFinish={onFinish}
        >
            <Divider orientation="left">Cách tính phí</Divider>
            <Form.Item label="Phí giao hàng" name={"deleveryFee"}>
                <TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
            </Form.Item>
            <Form.Item label="Phí mua hộ" name={"buyForFee"}>
                <TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Cập nhật
                </Button>
            </Form.Item>
        </Form>
    );
}
