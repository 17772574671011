import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { classNames } from "utils/common";

export default function MenuItem({
    menu,
    onEdit,
    numberOfDish,
    onViewDishes,
    onDelete,
}) {
    const KEY_ACTIONS = {
        EDIT: "edit",
        DELETE: "delete",
        VIEW_DISHES: "view-dishes",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onEdit(menu);
                break;
            case KEY_ACTIONS.DELETE:
                onDelete(menu);
                break;
            case KEY_ACTIONS.VIEW_DISHES:
                onViewDishes(menu);
                break;
            default:
                break;
        }
    };
    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.DELETE,
            label: "Xóa",
            icon: <DeleteOutlined />,
            danger: true,
        },
        {
            key: KEY_ACTIONS.VIEW_DISHES,
            label: "Xem danh sách món",
            icon: <EyeOutlined />,
        },
    ];

    return (
        <>
            <Space
                size="middle"
                className="justify-between w-full bg-gray-200 rounded-lg p-4 box-border"
            >
                <span className={classNames("font-semibold text-lg")}>
                    {menu?.name || "Tất cả"} ({numberOfDish} món)
                </span>
                <Dropdown
                    menu={{
                        items: actions.filter((action) =>
                            menu
                                ? action
                                : action.key === KEY_ACTIONS.VIEW_DISHES
                        ),
                        onClick,
                    }}
                >
                    <Button>
                        <Space>
                            Thao tác
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Space>
        </>
    );
}
