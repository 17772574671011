import { DragOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Upload } from "antd";
import Loading from "components/Loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";
import { toast } from "react-toastify";
import { getBase64, getPhotoThumbnailUrl, getPhotoUrl } from "utils/common";
// import Loading from "../../../components/Loading";
// import {
//   getBase64,
//   getPhotoThumbnailUrl,
//   getPhotoUrl,
// } from "../../../utils/common";
// import { updateSortingPhotoPlace } from "../slice";

const SortableItem = SortableElement(({ item }) => {
    return (
        <div className="picture">
            <img src={item.url} />
        </div>
    );
});

const SortableList = SortableContainer(({ items }) => {
    return (
        <div className="pictures-sort">
            {items.map((item, index) => (
                <SortableItem key={item._id} index={index} item={item} />
            ))}
        </div>
    );
});

function Photo(props) {
    const { form, place, setImages } = props;
    const images = place
        ? place.photos.map((img) => ({
              ...img,
              uid: img._id,
              previewUr: getPhotoUrl(img.path, "q=50"),
              url: getPhotoThumbnailUrl(img.path),
          }))
        : [];
    const [fileList, setFileList] = useState(images);
    const [newFileList, setNewFileList] = useState([]);
    const [deleteFileList, setDeleteFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImg, setPreviewImg] = useState(null);
    const [loadedImg, setLoadedImg] = useState(false);
    const [sortItem, setSortItem] = useState(images);
    const [isSorting, setIsSorting] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            imgNew: newFileList,
        });
    }, [newFileList]);

    useEffect(() => {
        form.setFieldsValue({
            imgDelete: deleteFileList,
        });
    }, [deleteFileList]);

    useEffect(() => {
        form.setFieldsValue({
            imgSorting: sortItem.map((item) => item._id),
        });
    }, [sortItem]);

    const handleCancel = () => {
        setLoadedImg(false);
        setPreviewImg(null);
        setPreviewVisible(false);
    };

    const handlePreview = async (file) => {
        if (!file.previewUr && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImg({
            url: file.previewUr || file.preview,
            title:
                file.name ||
                file.path.substring(file.path.lastIndexOf("/") + 1),
        });
        setPreviewVisible(true);
    };
    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleRemove = (file) => {
        const isNewFile = newFileList.find((e) => e.uid === file.uid);
        if (isNewFile) {
            setNewFileList((newFileList) =>
                newFileList.filter((e) => e.uid !== file.uid)
            );
        } else {
            setDeleteFileList((deleteFileList) => [
                ...deleteFileList,
                file._id,
            ]);
        }
    };

    const beforeUpload = (file) => {
        setNewFileList((newFileList) => [...newFileList, file]);
        return false;
    };

    const onLoadImg = () => {
        setLoadedImg(true);
    };

    const handleSortingPhoto = () => {
        const newPhotos = form.getFieldValue("imgNew");
        const deletePhotos = form.getFieldValue("imgDelete");
        if (newPhotos.length || deletePhotos.length) {
            toast.error("Vui lòng lưu thay đổi trước khi sửa bố cục");
        } else {
            setIsSorting(true);
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setSortItem((sortItem) => arrayMove(sortItem, oldIndex, newIndex));
    };

    return (
        <>
            <Form.Item name="imgNew" noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="imgDelete" noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="imgSorting" noStyle>
                <Input type="hidden" />
            </Form.Item>
            <h3
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                }}
            >
                {isSorting ? (
                    <Button
                        size="small"
                        danger
                        onClick={() => setIsSorting(false)}
                    >
                        Huỷ
                    </Button>
                ) : (
                    <Button
                        disabled={!place || !fileList.length}
                        size="small"
                        danger
                        onClick={handleSortingPhoto}
                    >
                        <DragOutlined /> Sửa bố cục
                    </Button>
                )}
            </h3>
            {isSorting ? (
                <>
                    <SortableList
                        items={sortItem}
                        shouldUseDragHandle={true}
                        helperClass="sortable"
                        axis="xy"
                        onSortEnd={onSortEnd}
                    />
                </>
            ) : (
                <Form.Item>
                    <div className="clearfix">
                        <Upload
                            accept={"image/*"}
                            multiple={true}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            onRemove={handleRemove}
                            beforeUpload={beforeUpload}
                        >
                            <div>
                                <PlusOutlined />
                                <div className="ant-upload-text">Thêm mới</div>
                            </div>
                        </Upload>
                    </div>
                </Form.Item>
            )}
            {previewImg && (
                <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <div style={{ minHeight: "200px" }}>
                        <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImg.url}
                            onLoad={onLoadImg}
                        />
                    </div>
                    {!loadedImg && <Loading />}
                </Modal>
            )}
        </>
    );
}

export default Photo;
