import {
    CloseOutlined,
    DollarOutlined,
    DownOutlined,
    EyeOutlined,
    UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { ADMIN_ROLES } from "utils/constants";

export default function Actions({
    record,
    onViewDetail,
    onViewCost,
    onViewReject,
    onCancel,
}) {
    const { userInfo } = useSelector((state) => state.auth);

    const KEY_ACTIONS = {
        VIEW_ORDER: "view_order",
        VIEW_COST: "view_cost",
        VIEW_REJECT: "view_reject",
        CANCEL: "cancel",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.VIEW_ORDER:
                onViewDetail(record);
                break;
            case KEY_ACTIONS.VIEW_COST:
                onViewCost(record);
                break;
            case KEY_ACTIONS.VIEW_REJECT:
                onViewReject(record);
                break;
            case KEY_ACTIONS.CANCEL:
                onCancel(record);
                break;
            default:
                break;
        }
    };

    const actions = {
        super_admin: [
            {
                key: KEY_ACTIONS.VIEW_ORDER,
                label: "Xem chi tiết đơn",
                icon: <EyeOutlined />,
            },
            {
                key: KEY_ACTIONS.VIEW_COST,
                label: "Xem chi tiết phí",
                icon: <DollarOutlined />,
            },
            {
                key: KEY_ACTIONS.VIEW_REJECT,
                label: "DS tài xế bỏ qua",
                icon: <UserDeleteOutlined />,
            },
            // {
            //   key: KEY_ACTIONS.CANCEL,
            //   disabled: userInfo.role !== ADMIN_ROLES.SUPER_ADMIN,
            //   danger: true,
            //   label: "Hủy đơn",
            //   icon: <CloseOutlined />,
            // },
        ],
    };

    return (
        <Dropdown menu={{ items: actions["super_admin"] || [], onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
