import { Avatar, Dropdown, Layout, Space } from "antd";
import { LockOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "features/auth/slice";
import { PRIMARY_COLOR } from "utils/constants";
import ChangePasswordModal from "./components/ChangePasswordModal";

export default function DashboardHeader() {
    const dispatch = useDispatch();

    const { userInfo, isProcessing } = useSelector((state) => state.auth);
    const [openChangePassword, setOpenChangePassword] = useState(false);

    const KEY_ACTIONS = {
        SIGN_OUT: "sign-out",
        CHANGE_PASSWORD: "change-password",
    };

    const onLogout = () => {
        dispatch(authActions.logout());
    };

    const onChangePassword = () => {
        setOpenChangePassword(true);
    };

    const onCancelChangePassword = () => {
        setOpenChangePassword(false);
    };

    const onSaveNewPassword = ({ oldPassword, newPassword }) => {
        dispatch(authActions.changePassword(oldPassword, newPassword));
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.SIGN_OUT:
                onLogout();
                break;
            case KEY_ACTIONS.CHANGE_PASSWORD:
                onChangePassword();
                break;
            default:
                break;
        }
    };

    const items = [
        {
            key: KEY_ACTIONS.CHANGE_PASSWORD,
            label: "Đổi mật khẩu",
            icon: <LockOutlined />,
        },
        {
            key: KEY_ACTIONS.SIGN_OUT,
            label: "Đăng xuất",
            icon: <LogoutOutlined />,
        },
    ];

    return (
        <>
            <Layout.Header className="!sticky top-0 w-full !bg-white flex justify-end z-10">
                <Dropdown menu={{ items, onClick: onClick }}>
                    <Space>
                        <div className="flex items-center gap-x-2 cursor-pointer">
                            <span>
                                Xin chào,{" "}
                                <span className="font-bold">
                                    {userInfo?.name}
                                </span>
                            </span>
                            <Avatar
                                style={{ backgroundColor: PRIMARY_COLOR }}
                                icon={<UserOutlined />}
                            />
                        </div>
                    </Space>
                </Dropdown>
            </Layout.Header>
            {openChangePassword && (
                <ChangePasswordModal
                    open={openChangePassword}
                    isProcessing={isProcessing}
                    onCancel={onCancelChangePassword}
                    onSubmit={onSaveNewPassword}
                />
            )}
        </>
    );
}
