import { createSlice } from "@reduxjs/toolkit";
import buildingApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    buildings: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const buildingslice = createSlice({
    name: "building",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setBuildings: (state, action) => {
            state.buildings = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addBuilding: (state, action) => {
            state.isCompleted = true;
            state.buildings.unshift(action.payload);
        },
        editBuilding: (state, action) => {
            state.isCompleted = true;
            state.buildings = state.buildings.map((building) =>
                building._id === action.payload._id ? action.payload : building
            );
        },
        removeBuilding: (state, action) => {
            state.isCompleted = true;
            state.buildings = state.buildings.filter(
                (building) => building._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setBuildings,
    setPagination,
    addBuilding,
    editBuilding,
    removeBuilding,
} = buildingslice.actions;

export const fetchBuildings = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await buildingApi.getAll(params);
            dispatch(setBuildings(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createBuilding = (account) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await buildingApi.create(account);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addBuilding(data));
                toast.success("Thêm tòa nhà mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateBuilding = (account) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await buildingApi.update(account);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editBuilding(data));
                toast.success("Cập nhật tòa nhà thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteBuilding = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await buildingApi.delete(id);
            if (data.success) {
                dispatch(removeBuilding(id));
                toast.success("Xóa tòa nhà thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa tòa nhà thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default buildingslice.reducer;
