import {
    BankOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Form, Modal, Tabs } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { classNames, getSlug, parseTime } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";
import * as categoryActions from "../../../category/slice";
import TabAccount from "./Account";
import TabBank from "./Bank";
import TabConfig from "./Config";
import TabInfo from "./Info";
import TimeModal from "./TimeModal";

const getTimeTemp = (time) => {
    return {
        0: time[0].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        1: time[1].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        2: time[2].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        3: time[3].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        4: time[4].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        5: time[5].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        6: time[6].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
    };
};

const defaultTime = {
    0: [{ open: 420, close: 1380 }],
    1: [{ open: 420, close: 1380 }],
    2: [{ open: 420, close: 1380 }],
    3: [{ open: 420, close: 1380 }],
    4: [{ open: 420, close: 1380 }],
    5: [{ open: 420, close: 1380 }],
    6: [{ open: 420, close: 1380 }],
};

const defaultConfig = {
    delivery: {
        isAvailable: true,
    },
    pickup: {
        isAvailable: true,
        paymentRequired: false,
    },
    onsite: {
        isAvailable: true,
        paymentRequired: false,
    },
    deliveryFee: {
        internal: {
            isFree: false,
            isFreeWithMinOrder: false,
            minOrderValueToFree: 0,
            value: 0,
        },
        external: {
            isFree: false,
            isFreeWithMinOrder: false,
            minOrderValueToFree: 0,
            isFreeWithDistance: false,
            minDistanceToFree: 0,
            value: 0,
        },
        extra: {
            isActive: false,
            valueType: "percent",
            value: 0,
            reason: "Phí này áp dụng khi thời tiết bất lợi hoặc dịp lễ tết.",
        },
    },
};

function RestaurantDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, restaurant } = props;
    const [image, setImage] = useState(null);
    const [cover, setCover] = useState(null);
    const [openTimeModal, setOpenTimeModal] = useState(false);
    const [time, setTime] = useState(defaultTime);
    const [config, setConfig] = useState(defaultConfig);

    const { categories } = useSelector((state) => state.foodCategory);

    const dispatch = useDispatch();

    useEffect(() => {
        if (open && categories.length === 0) {
            dispatch(categoryActions.fetchCategories({ pageSize: 100 }));
        }
    }, [open]);

    const initialValues = restaurant
        ? {
              ...restaurant,
              config: restaurant.config || { ...defaultConfig },
              tags:
                  restaurant.tags?.map((e) => {
                      const tag = {
                          text: e.text,
                          color: `${e.color};${e.bgColor}`,
                      };
                      if (e.startTime && e.endTime) {
                          tag.time = [dayjs(e.startTime), dayjs(e.endTime)];
                          // tag.time = [
                          //   dayjs(tag.startTime, "YYYY-MM-DD HH:mm").add(7, "hour"),
                          //   dayjs(tag.endTime, "YYYY-MM-DD HH:mm").add(7, "hour"),
                          // ]
                      }
                      return tag;
                  }) || [],
              banking: restaurant.merchant?.banking || {
                  bankId: "",
                  accountNumber: "",
                  accountName: "",
              },
              account: restaurant.merchant || {
                  name: "",
                  phone: "",
                  email: "",
                  active: true,
                  password: "",
                  telegramId: "",
              },
          }
        : {
              name: "",
              slug: "",
              categories: [],
              address: "",
              long: 105.814932,
              lat: 21.016621,
              time: { ...defaultTime },
              phone: "",
              quote: "",
              keywords: "",
              tags: [],
              isNew: false,
              isHot: false,
              isPartner: false,
              active: true,
              banking: {
                  bankId: "",
                  accountNumber: "",
                  accountName: "",
              },
              account: {
                  name: "",
                  phone: "",
                  email: "",
                  active: true,
                  password: "",
              },
              config: defaultConfig,
          };
    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const restaurant = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(restaurant),
                    image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(restaurant, formData);
            })
            .catch((err) => {
                toast.error("Vui lòng kiểm tra lại các trường thông tin");
                console.error(err);
            });
    };

    const onCancelGetTime = () => {
        setOpenTimeModal(false);
    };

    const onValueChange = (changedValues) => {
        if (changedValues["banking"]?.["accountName"]) {
            form.setFieldValue(
                ["banking", "accountName"],
                changedValues["banking"]["accountName"].toUpperCase()
            );
        }
        if (changedValues["name"]) {
            form.setFieldValue("slug", getSlug(changedValues["name"]));
        }
        if (changedValues["config"]) {
            setConfig(changedValues["config"]);
        }
    };

    const onSubmitTime = (_time) => {
        form.setFieldsValue({ time: _time, timeTemp: getTimeTemp(_time) });
        setOpenTimeModal(false);
    };

    const items = [
        {
            key: "info",
            label: "Thông tin",
            forceRender: true,
            disabled: isProcessing,
            icon: <InfoCircleOutlined />,
            children: (
                <TabInfo
                    form={form}
                    restaurant={restaurant}
                    setTime={setTime}
                    setOpenTimeModal={setOpenTimeModal}
                    image={image}
                    setImage={setImage}
                    cover={cover}
                    setCover={setCover}
                    onCancel={onCancel}
                />
            ),
        },
        {
            key: "account",
            label: "Tài khoản chủ quán",
            forceRender: true,
            disabled: isProcessing,
            icon: <UserOutlined />,
            children: (
                <TabAccount
                    form={form}
                    restaurant={restaurant}
                    onCancel={onCancel}
                />
            ),
        },
        {
            key: "bank",
            label: "Tài khoản ngân hàng",
            forceRender: true,
            disabled: isProcessing,
            icon: <BankOutlined />,
            children: (
                <TabBank
                    form={form}
                    restaurant={restaurant}
                    onCancel={onCancel}
                />
            ),
        },
        {
            key: "config",
            label: "Thiết lập",
            forceRender: true,
            disabled: isProcessing,
            icon: <SettingOutlined />,
            children: (
                <TabConfig
                    form={form}
                    restaurant={restaurant}
                    onCancel={onCancel}
                    config={config}
                />
            ),
        },
    ];

    return (
        <>
            <Modal
                title="Quán ăn"
                okText="Lưu"
                cancelText="Huỷ"
                centered
                open={open}
                onOk={onOK}
                okButtonProps={{
                    className: classNames([Boolean(restaurant) && "hidden"]),
                }}
                cancelButtonProps={{
                    className: classNames([Boolean(restaurant) && "hidden"]),
                }}
                onCancel={onCancel}
                confirmLoading={isProcessing}
                width={840}
            >
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValueChange}
                    layout="horizontal"
                    {...formItemLayoutHorizontal}
                >
                    <Tabs defaultActiveKey="info" items={items} />
                </Form>
            </Modal>
            <TimeModal
                time={time}
                open={openTimeModal}
                onCancel={onCancelGetTime}
                onDone={onSubmitTime}
            />
        </>
    );
}

RestaurantDetailModal.propTypes = {
    open: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    restaurant: PropTypes.object,
};

RestaurantDetailModal.defaultProps = {
    isProcessing: false,
    open: false,
    restaurant: null,
};

export default RestaurantDetailModal;
