import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Image,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import CampaignDetailModal from "./components/DetailModal";
import * as campaignActions from "./slice";

const { Paragraph } = Typography;

function Campaign() {
    const dispatch = useDispatch();
    const { campaigns, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.foodCampaign);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingCampaign, setSelectingCampaign] = useState(null);
    const [searchName, setSearchName] = useState("");

    useEffect(() => {
        dispatch(campaignActions.fetchCampaigns(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingCampaign(null);
        }
    }, [isCompleted]);

    const onOpenDetailModal = (campaign = null) => {
        setSelectingCampaign(campaign);
        setOpenDetailModal(true);
    };

    const onDelete = (campaign) => {
        Modal.confirm({
            title: `Xác nhận xoá chiến dịch`,
            content: `Bạn có chắc chắn muốn xoá chiến dịch này?`,
            onOk: () => dispatch(campaignActions.deleteCampaign(campaign._id)),
            centered: true,
        });
    };

    const onSubmit = (campaign, formData) => {
        if (campaign._id) {
            dispatch(campaignActions.updateCampaign(campaign, formData));
        } else {
            dispatch(campaignActions.createCampaign(formData));
        }
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingCampaign(null);
        dispatch(campaignActions.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            campaignActions.fetchCampaigns({
                ...pagination,
                name: searchName,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            campaignActions.fetchCampaigns({
                ...pagination,
                name: searchName,
            })
        );
    };
    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Hình ảnh",
            dataIndex: "image",
            width: "280px",
            render: (image) =>
                image ? (
                    <Image
                        alt="Image of campaign"
                        src={getPhotoUrl(image?.path || null)}
                        width={250}
                        height={100}
                    />
                ) : null,
        },
        {
            title: "Tên chiến dịch",
            ellipsis: {
                showTitle: false,
            },
            render: (record) => (
                <Paragraph
                    strong
                    copyable={{
                        tooltips: ["Sao chép link", "Đã sao chép!"],
                        text: `https://link.gokaapp.vn/cp/${record.code}`,
                    }}
                >
                    {record.name}
                </Paragraph>
            ),
        },
        {
            title: "Số quán",
            dataIndex: "restaurantCount",
            align: "center",
            width: "10%",
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: "Hiển thị trên trang chủ",
            dataIndex: "inHome",
            align: "center",
            width: "15%",
            sorter: (a, b) => a.inHome - b.inHome,
            render: (inHome) =>
                inHome ? (
                    <span className="text-xl">
                        <CheckOutlined />
                    </span>
                ) : (
                    <span className="text-slate-300 text-xl">
                        <CloseOutlined />
                    </span>
                ),
        },
        {
            title: "Trạng thái",
            dataIndex: "active",
            align: "center",
            width: 150,
            render: (active) =>
                active ? (
                    <Tag color="#4caf50">Active</Tag>
                ) : (
                    <Tag color="#f44336">In-active</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];
    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Chiến dịch
                </Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={campaigns}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <CampaignDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    campaign={selectingCampaign}
                />
            )}
        </>
    );
}

export default Campaign;
