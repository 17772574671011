import {
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    FacebookFilled,
    ShareAltOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Carousel, Image, Row, Tooltip, Typography } from "antd";
import moment from "moment";
import { getPhotoUrl, priceToCurrency, randomNumber } from "utils/common";

const { Meta } = Card;
const { Text, Paragraph } = Typography;
function PostItem(props) {
    const {
        post: _post,
        onDetail,
        setOpenDetailModal,
        onDelete,
        onHide,
        onShow,
    } = props;
    const post = { ..._post };
    if (post.viaFB) {
        post.photos = post.fbPhotos.map((image) => ({ path: image }));
        post.author = { ...post.fbAuthor };
    }
    const view = randomNumber(0, 100);

    const handleDetail = () => {
        setOpenDetailModal(true);
        onDetail(post);
    };

    return (
        <Card
            cover={
                <Carousel>
                    {post.photos.map((image, index) => (
                        <div key={index}>
                            <Image
                                src={getPhotoUrl(image?.path || null)}
                                alt={`Slide ${index + 1}`}
                                width="100%"
                                height="240px"
                                style={{ objectFit: "cover" }}
                            />
                        </div>
                    ))}
                </Carousel>
            }
            actions={[
                <EditOutlined onClick={handleDetail} key="edit" />,
                post.status === "public" ? (
                    <EyeInvisibleOutlined
                        onClick={() => onHide(post._id)}
                        key="hide"
                    />
                ) : (
                    <EyeOutlined onClick={() => onShow(post._id)} key="hide" />
                ),
                <DeleteOutlined
                    onClick={() => onDelete(post._id)}
                    key="delete"
                />,
                <ShareAltOutlined
                    // onClick={() => onDelete(post._id)}
                    key="share"
                />,
                // <EllipsisOutlined key="ellipsis" />,
            ]}
        >
            <Meta
                avatar={
                    <Avatar
                        src={getPhotoUrl(
                            post.author.avatar?.path ||
                                post.author.avatar ||
                                null
                        )}
                    />
                }
                title={
                    <Row justify="space-between" align="middle">
                        <a
                            href={`https://facebook.com/${post.author.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text strong>{post.author.name}</Text>
                        </a>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            <EyeOutlined /> {view} ·{" "}
                            {moment(post.createdAt).fromNow(true)}
                            {post.viaFB && (
                                <span style={{ color: "#0866ff" }}>
                                    {" "}
                                    · <FacebookFilled />
                                </span>
                            )}
                        </Text>
                    </Row>
                }
                description={
                    <div style={{ minHeight: 125 }}>
                        <Text
                            strong
                            copyable={{
                                text: post._id,
                                tooltip: "Copy ID",
                            }}
                        >
                            <Tooltip title="Đã ghim">
                                {post.isPinned && "📌 "}
                            </Tooltip>
                            {post.title || "Chưa có tiêu đề"}
                        </Text>
                        <Paragraph
                            ellipsis={{
                                rows: 3,
                                expandable: true,
                                symbol: "Xem thêm",
                            }}
                        >
                            {post.content}
                        </Paragraph>
                        Danh mục: <Text strong>{post.category.name}</Text>
                        <br />
                        Giá: <Text strong>{priceToCurrency(post.price)}</Text>
                        <br />
                        SĐT:{" "}
                        <Text strong>{post.author.phone || "Không có"}</Text>
                        {post.status === "public" && post.verifiedAt && (
                            <>
                                <br />
                                Đã duyệt:{" "}
                                <Text strong>
                                    {moment(post.verifiedAt).fromNow(true)}
                                </Text>
                            </>
                        )}
                        <br />
                        {post.status === "reject" && post.rejectedAt && (
                            <>
                                <br />
                                Đã từ chối:{" "}
                                <Text strong type="danger">
                                    {moment(post.rejectedAt).fromNow(true)} -{" "}
                                    {post.rejectReason}
                                </Text>
                            </>
                        )}
                        {post.status === "hidden" && post.rejectedAt && (
                            <>
                                <br />
                                Đã ẩn:{" "}
                                <Text strong color="red">
                                    {post.hiddenReason}
                                </Text>
                            </>
                        )}
                    </div>
                }
            />
        </Card>
    );
}
export default PostItem;
