import { createSlice } from "@reduxjs/toolkit";
import placeApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    places: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const placeSlice = createSlice({
    name: "tuktuk-place",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setPlaces: (state, action) => {
            state.places = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addPlace: (state, action) => {
            state.isCompleted = true;
            state.places.unshift(action.payload);
        },
        editPlace: (state, action) => {
            state.isCompleted = true;
            state.places = state.places.map((place) =>
                place._id === action.payload._id ? action.payload : place
            );
        },
        removePlace: (state, action) => {
            state.isCompleted = true;
            state.places = state.places.filter(
                (user) => user._id !== action.payload._id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setPlaces,
    setPagination,
    addPlace,
    editPlace,
    removePlace,
} = placeSlice.actions;

export const fetchPlaces = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await placeApi.getAll(params);
            dispatch(setPlaces(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createPlace = (place) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await placeApi.create(place);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addPlace(data));
                toast.success("Thêm place mới thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updatePlace = (place, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await placeApi.update(place._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editPlace(data));
                toast.success("Cập nhật place thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deletePlace = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await placeApi.delete(id);
            if (data.success) dispatch(removePlace(id));
            toast.success("Xóa place thành công!");
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default placeSlice.reducer;
