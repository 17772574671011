import {
    Button,
    Divider,
    Input,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
    Tooltip,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import Actions from "./components/Actions";
import OrderDetailDrawer from "./components/DetailDrawer";
import OrderCostModal from "./components/CostDrawer";
import * as orderActions from "./slice";
import ConfirmCancelModal from "./components/ConfirmCancelModal";
import { SearchOutlined } from "@ant-design/icons";
import RejectModal from "./components/RejectModal";
import { DELIVERY_STATUS } from "utils/constants";
import useViewDetail from "hooks/useViewDetail";
import OrderEditDrawer from "./components/EditDrawer";

function Order() {
    const dispatch = useDispatch();
    const { orders, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.foodNewOrder);
    const [searchValue, setSearchValue] = useState({
        code: "",
        deliveryStatus: "",
        customer: "",
    });
    const [searchState, setSearchState] = useState({
        code: "",
        deliveryStatus: "",
        customer: "",
    });

    const onDisableOrderProcessing = () => {
        dispatch(orderActions.setIsProcessing(false));
    };

    const detailDrawer = useViewDetail(onDisableOrderProcessing);
    const costModal = useViewDetail(onDisableOrderProcessing);
    const rejectModal = useViewDetail(onDisableOrderProcessing);
    const cancelModal = useViewDetail(onDisableOrderProcessing);
    const editDrawer = useViewDetail(onDisableOrderProcessing);

    useEffect(() => {
        dispatch(orderActions.fetchOrders(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            cancelModal.onClose();
        }
    }, [isCompleted]);

    const onChangeSearchValue = (property, value) => {
        setSearchValue((prev) => ({ ...prev, [property]: value }));
    };

    const onSearch = () => {
        setSearchState(searchValue);
        dispatch(
            orderActions.fetchOrders({
                ...pagination,
                ...searchValue,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            orderActions.fetchOrders({
                ...pagination,
                ...searchState,
            })
        );
    };

    const onCancelOrder = (order, reason) => {
        dispatch(orderActions.cancelOrder(order._id, reason));
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (_, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Mã đơn",
            dataIndex: "code",
            width: "8%",
            render: (code) => <Typography.Text strong>#{code}</Typography.Text>,
        },
        {
            title: "Quán",
            width: "16%",
            render: (record) => (
                <>
                    <Typography.Text strong>
                        {record.routes.restaurant?.name}
                    </Typography.Text>
                    <br />
                    <a
                        href={`https://www.google.com/maps?q=${record.routes.restaurant.lat},${record.routes.restaurant.long}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Tooltip title={record.routes.restaurant?.address}>
                            <Typography.Text className="whitespace-nowrap">
                                {record.routes.restaurant?.address?.slice(
                                    0,
                                    25
                                ) +
                                    (record.routes.restaurant?.address?.length >
                                    25
                                        ? "..."
                                        : "")}
                            </Typography.Text>
                        </Tooltip>
                    </a>
                    {record.rejectCount ? (
                        <>
                            <br />
                            <Typography.Text type="warning">{`(${record.rejectCount} tài xế bỏ qua)`}</Typography.Text>
                        </>
                    ) : null}
                </>
            ),
        },
        {
            title: "Khách hàng",
            dataIndex: ["routes", "customer"],
            width: "16%",
            render: (customer) => (
                <>
                    <Typography.Text strong>{customer?.name}</Typography.Text>
                    <br />
                    <Typography.Text>{customer?.phone}</Typography.Text>
                    <br />
                    <a
                        href={`https://www.google.com/maps?q=${customer.lat},${customer.long}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Tooltip title={customer?.address}>
                            <Typography.Text>
                                {customer?.address?.slice(0, 25) +
                                    (customer?.address?.length > 25
                                        ? "..."
                                        : "")}
                            </Typography.Text>
                        </Tooltip>
                    </a>
                </>
            ),
        },
        {
            title: "Trạng thái",
            dataIndex: "deliveryStatus",
            render: (deliveryStatus) => (
                <Tag color={DELIVERY_STATUS[deliveryStatus].color}>
                    {DELIVERY_STATUS[deliveryStatus].state}
                </Tag>
            ),
        },
        {
            title: "Tổng tiền",
            width: "200px",
            render: (record) => (
                <>
                    <Typography.Text>
                        Tiền món: {priceToCurrency(record.dishFeeActual)}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        Tiền ship: {priceToCurrency(record.deliveryFeeActual)} (
                        {record.distance}km)
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        Giảm giá: {priceToCurrency(record.discount)}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        App nhận: {priceToCurrency(record.appReceiveActual)}
                    </Typography.Text>
                    <br />
                    <Typography.Text strong>
                        Tổng tiền: {priceToCurrency(record.total)}
                    </Typography.Text>
                </>
            ),
        },
        {
            title: "Thời gian",
            width: "180px",
            render: (record) => (
                <>
                    <Typography.Text>
                        Đặt đơn: {moment(record.orderedAt).format("HH:mm")}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        Nhận đơn:{" "}
                        {record.assignedAt
                            ? moment(record.assignedAt).format("HH:mm")
                            : "-"}
                    </Typography.Text>
                    <br />
                    {record.arrivedAt ? (
                        <>
                            {" "}
                            <Typography.Text>
                                Đến quán:{" "}
                                {moment(record.arrivedAt).format("HH:mm")}
                            </Typography.Text>
                            <br />
                        </>
                    ) : null}
                    {record.pickupAt ? (
                        <>
                            {" "}
                            <Typography.Text>
                                Lấy đơn:{" "}
                                {moment(record.pickupAt).format("HH:mm")}
                            </Typography.Text>
                            <br />
                        </>
                    ) : null}
                    {record.shipper ? (
                        <>
                            {" "}
                            <Typography.Text strong>
                                Tài xế: {record.shipper.name}
                            </Typography.Text>
                        </>
                    ) : null}
                </>
            ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "12%",
            render: (record) => (
                <Actions
                    record={record}
                    onViewDetail={detailDrawer.onOpen}
                    onViewCost={costModal.onOpen}
                    onViewReject={rejectModal.onOpen}
                    onCancel={cancelModal.onOpen}
                    onEdit={editDrawer.onOpen}
                />
            ),
        },
    ];

    const orderStatusOptions = [
        {
            label: "Tất cả trạng thái",
            value: "",
        },
        {
            label: "Đang tìm tài xế",
            value: "finding",
        },
        {
            label: "Tài xế đang đến quán",
            value: "going-to-restaurant",
        },
        {
            label: "Tài xế đã đến quán",
            value: "arrived-restaurant",
        },
        {
            label: "Tài xế đã lấy hàng",
            value: "picked-up",
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Đơn hàng đang xử lý
                </Typography.Title>
                <Space size="middle">
                    <Select
                        options={orderStatusOptions}
                        onChange={(value) =>
                            onChangeSearchValue("deliveryStatus", value)
                        }
                        value={searchValue.deliveryStatus}
                        allowClear
                        placeholder="Trạng thái đơn hàng"
                        className="w-60"
                    />
                    <Input
                        placeholder="Tìm theo mã đơn"
                        onChange={(e) =>
                            onChangeSearchValue("code", e.target.value)
                        }
                        allowClear
                        value={searchValue.code}
                    />
                    <Input
                        placeholder="Tìm theo SĐT khách hàng"
                        onChange={(e) =>
                            onChangeSearchValue("customer", e.target.value)
                        }
                        allowClear
                        value={searchValue.customer}
                    />
                    <Button
                        onClick={onSearch}
                        icon={<SearchOutlined />}
                        type="primary"
                    />
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={orders}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {detailDrawer.open && (
                <OrderDetailDrawer
                    open={detailDrawer.open}
                    onClose={detailDrawer.onClose}
                    order={detailDrawer.detail}
                    onViewCost={() => {
                        costModal.onOpen(detailDrawer.detail);
                        detailDrawer.onClose();
                    }}
                />
            )}
            {costModal.open && (
                <OrderCostModal
                    open={costModal.open}
                    onClose={costModal.onClose}
                    order={costModal.detail}
                />
            )}
            {rejectModal.open && (
                <RejectModal
                    open={rejectModal.open}
                    onClose={rejectModal.onClose}
                    order={rejectModal.detail}
                />
            )}
            {cancelModal.open && (
                <ConfirmCancelModal
                    open={cancelModal.open}
                    onClose={cancelModal.onClose}
                    order={cancelModal.detail}
                    onSubmit={onCancelOrder}
                    loading={isProcessing}
                />
            )}
            {editDrawer.open && (
                <OrderEditDrawer
                    open={editDrawer.open}
                    onClose={editDrawer.onClose}
                    order={editDrawer.detail}
                />
            )}
        </>
    );
}

export default Order;
