import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Image,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import SubCategoryDetailModal from "./components/DetailModal";
import { fetchCategories } from "../category/slice";
import * as subCategoryActions from "./slice";

function Category() {
    const dispatch = useDispatch();
    const { subCategories, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.serviceSubCategory);
    const { categories } = useSelector((state) => state.serviceCategory) || [];

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingSubCategory, setSelectingCategory] = useState(null);
    const [searchName, setSearchName] = useState("");
    useEffect(() => {
        dispatch(
            subCategoryActions.fetchSubCategories({
                ...pagination,
                pageSize: 10,
            })
        );
        dispatch(fetchCategories({ pageSize: 100 }));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingCategory(null);
        }
    }, [isCompleted]);

    const onOpenDetailModal = (category = null) => {
        setSelectingCategory(category);
        setOpenDetailModal(true);
    };

    const onDelete = (category) => {
        Modal.confirm({
            title: `Xác nhận xoá danh mục`,
            content: `Bạn có chắc chắn muốn xoá danh mục này?`,
            onOk: () =>
                dispatch(subCategoryActions.deleteSubCategory(category._id)),
            centered: true,
        });
    };

    const onSubmit = (category, formData) => {
        if (category._id) {
            dispatch(subCategoryActions.updateSubCategory(category, formData));
        } else {
            dispatch(subCategoryActions.createSubCategory(formData));
        }
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingCategory(null);
        dispatch(subCategoryActions.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = (searchValue) => {
        dispatch(
            subCategoryActions.fetchSubCategories({
                ...pagination,
                name: searchValue,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            subCategoryActions.fetchSubCategories({
                ...pagination,
                name: searchName,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Hình ảnh",
            width: "10%",
            dataIndex: "image",
            render: (image) => (
                <Image
                    alt="Image of food category"
                    src={getPhotoUrl(image?.path || null)}
                    width={80}
                    height={80}
                />
            ),
        },
        {
            title: "Tên danh mục con",
            dataIndex: "name",
            width: "15%",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Danh mục",
            dataIndex: ["category", "name"],
            width: "15%",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: "Nổi bật",
            dataIndex: "isHot",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.isHot - b.isHot,
            render: (isHot) =>
                isHot ? (
                    <span className="text-xl">
                        <CheckOutlined />
                    </span>
                ) : (
                    <span className="text-slate-300 text-xl">
                        <CloseOutlined />
                    </span>
                ),
        },
        {
            title: "Hiển thị trên trang chủ",
            dataIndex: "inHome",
            align: "center",
            width: "15%",
            sorter: (a, b) => a.inHome - b.inHome,
            render: (inHome) =>
                inHome ? (
                    <span className="text-xl">
                        <CheckOutlined />
                    </span>
                ) : (
                    <span className="text-slate-300 text-xl">
                        <CloseOutlined />
                    </span>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "10%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>Danh mục con</Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={subCategories}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <SubCategoryDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    subCategory={selectingSubCategory}
                    categories={categories}
                />
            )}
        </>
    );
}

export default Category;
