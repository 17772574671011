import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Space,
    Table,
    Typography,
    Image,
    Input,
    Modal,
    Row,
    Divider,
    Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl, priceToCurrency } from "utils/common";
import ComboDetailModal from "./components/DetailModal";
import Actions from "./components/Actions";
import * as comboActions from "./slice";

const { Paragraph } = Typography;

export default function Combo() {
    const { combos, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.tuktukCombo);
    useEffect(() => {
        dispatch(comboActions.fetchCombos(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingCombo(null);
        }
    }, [isCompleted]);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingCombo, setSelectingCombo] = useState(null);
    const [searchName, setSearchName] = useState("");

    const dispatch = useDispatch();

    const onTableChange = async (pagination) => {
        dispatch(
            comboActions.fetchCombos({
                ...pagination,
                name: searchName,
            })
        );
    };

    const onSubmit = (combo, formData) => {
        if (!combo._id) {
            dispatch(comboActions.createCombo(formData));
        } else {
            dispatch(comboActions.updateCombo(combo, formData));
        }
    };

    const onCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectingCombo(null);
        dispatch(comboActions.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            comboActions.fetchCombos({
                ...pagination,
                name: searchName,
                current: 1,
            })
        );
    };

    const onOpenDetailModal = (combo = null) => {
        setSelectingCombo(combo);
        setOpenDetailModal(true);
    };

    const onDelete = (combo) => {
        Modal.confirm({
            title: `Xác nhận xóa combo`,
            content: `Bạn có chắc chắn muốn xóa combo này?`,
            onOk: () => dispatch(comboActions.deleteCombo(combo._id)),
            centered: true,
        });
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Paragraph>
            ),
        },
        {
            title: "Tên",
            // width: "10%",
            dataIndex: "name",
        },
        {
            title: "Giá",
            width: "20%",
            render: (record) => (
                <>
                    <Paragraph>{priceToCurrency(record.price)}</Paragraph>
                    {record.rawPrice && (
                        <Paragraph delete disabled>
                            {priceToCurrency(record.rawPrice)}
                        </Paragraph>
                    )}
                </>
            ),
        },
        {
            title: "Hình ảnh",
            width: "240px",
            dataIndex: "image",
            render: (image) => (
                <Image
                    alt="Combo image"
                    src={getPhotoUrl(image?.path || null)}
                    width="200px"
                    height="200px"
                />
            ),
        },
        {
            title: "Thứ tự",
            dataIndex: "priority",
            width: "10%",
            align: "center",
        },
        {
            title: "Kích hoạt",
            dataIndex: "active",
            align: "center",
            width: "10%",
            render: (active) =>
                active ? (
                    <Tag color="#4caf50">Active</Tag>
                ) : (
                    <Tag color="#f44336">In-active</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];
    return (
        <>
            <Row align="middle" justify="space-between">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Gói tiết kiệm
                </Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={combos}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <ComboDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCloseDetailModal}
                    combo={selectingCombo}
                />
            )}
        </>
    );
}
