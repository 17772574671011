import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routers } from "router";

import { default as Logo } from "assets/img/logo_trans_512.png";

const { Sider } = Layout;

import {
    AlertOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined,
    BankOutlined,
    BlockOutlined,
    BookOutlined,
    CarOutlined,
    CompassOutlined,
    ContainerOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
    FileDoneOutlined,
    FileImageOutlined,
    FileProtectOutlined,
    FireOutlined,
    FormOutlined,
    HistoryOutlined,
    InboxOutlined,
    LaptopOutlined,
    MobileOutlined,
    PercentageOutlined,
    PictureOutlined,
    RestOutlined,
    RiseOutlined,
    RocketOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    SnippetsOutlined,
    StarOutlined,
    TagOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    TruckOutlined,
    UserOutlined,
    UserSwitchOutlined,
    WalletOutlined,
    ZoomInOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

export default function DashboardSider() {
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const { userInfo } = useSelector((state) => state.auth);

    const menuItems = {
        super_admin: [
            {
                label: "Dashboard",
                key: routers.dashboard,
                icon: <DashboardOutlined />,
            },
            {
                label: "Module",
                key: routers.module,
                icon: <AppstoreOutlined />,
            },
            {
                label: "Banner",
                key: routers.banner,
                icon: <PictureOutlined />,
            },
            {
                label: "Tòa nhà",
                key: routers.building,
                icon: <BankOutlined />,
            },
            {
                label: "Người dùng",
                key: routers.user,
                icon: <TeamOutlined />,
            },
            {
                label: "Lệnh nạp - rút ví",
                key: routers.walletRequest,
                icon: <WalletOutlined />,
            },
            {
                label: "Xe Tuktuk",
                key: routers.tuktuk.index,
                icon: <TruckOutlined />,
                children: [
                    {
                        label: "Yêu cầu gọi xe",
                        key: [
                            routers.tuktuk.index,
                            routers.tuktuk.request,
                        ].join("/"),
                        icon: <AlertOutlined />,
                    },
                    {
                        label: "Lịch sử chuyến đi",
                        key: [routers.tuktuk.index, routers.tuktuk.order].join(
                            "/"
                        ),
                        icon: <HistoryOutlined />,
                    },
                    {
                        label: "Banner",
                        key: [routers.tuktuk.index, routers.tuktuk.banner].join(
                            "/"
                        ),
                        icon: <PictureOutlined />,
                    },
                    {
                        label: "Tài xế",
                        key: [routers.tuktuk.index, routers.tuktuk.driver].join(
                            "/"
                        ),
                        icon: <UserSwitchOutlined />,
                    },
                    {
                        label: "Gói tiết kiệm",
                        key: [routers.tuktuk.index, routers.tuktuk.combo].join(
                            "/"
                        ),
                        icon: <PercentageOutlined />,
                    },
                    {
                        label: "Yêu cầu mua gói",
                        key: [
                            routers.tuktuk.index,
                            routers.tuktuk.comboTransaction,
                        ].join("/"),
                        icon: <FileDoneOutlined />,
                    },
                    {
                        label: "Địa điểm Hot",
                        key: [routers.tuktuk.index, routers.tuktuk.place].join(
                            "/"
                        ),
                        icon: <EnvironmentOutlined />,
                    },
                    {
                        label: "Thiết lập",
                        key: [routers.tuktuk.index, routers.tuktuk.config].join(
                            "/"
                        ),
                        icon: <SettingOutlined />,
                    },
                ],
            },
            {
                label: "Giao hàng - Mua hộ",
                key: routers.delivery.index,
                icon: <ShoppingCartOutlined />,
                children: [
                    {
                        label: "Đơn hàng",
                        key: [
                            routers.delivery.index,
                            routers.delivery.order,
                        ].join("/"),
                        icon: <HistoryOutlined />,
                    },
                    {
                        label: "Shipper",
                        key: [
                            routers.delivery.index,
                            routers.delivery.shipper,
                        ].join("/"),
                        icon: <UserSwitchOutlined />,
                    },
                    {
                        label: "Thiết lập",
                        key: [
                            routers.delivery.index,
                            routers.delivery.config,
                        ].join("/"),
                        icon: <SettingOutlined />,
                    },
                ],
            },
            {
                label: "Đặt xe",
                key: routers.transport.index,
                icon: <CarOutlined />,
                children: [
                    {
                        label: "Yêu cầu đặt xe",
                        key: [
                            routers.transport.index,
                            routers.transport.order,
                        ].join("/"),
                        icon: <HistoryOutlined />,
                    },
                    {
                        label: "Tài xế",
                        key: [
                            routers.transport.index,
                            routers.transport.driver,
                        ].join("/"),
                        icon: <UserSwitchOutlined />,
                    },
                ],
            },
            {
                label: "Bảng tin",
                key: routers.news.index,
                icon: <CompassOutlined />,
                children: [
                    {
                        label: "Bài viết",
                        key: [routers.news.index, routers.news.post].join("/"),
                        icon: <ContainerOutlined />,
                    },
                    {
                        label: "Duyệt bài",
                        key: [routers.news.index, routers.news.verify].join(
                            "/"
                        ),
                        icon: <FileProtectOutlined />,
                    },
                ],
            },
            {
                label: "Thanh lý",
                key: routers.liquidation.index,
                icon: <LaptopOutlined />,
                children: [
                    {
                        label: "Danh mục",
                        key: [
                            routers.liquidation.index,
                            routers.liquidation.category,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Bài viết",
                        key: [
                            routers.liquidation.index,
                            routers.liquidation.post,
                        ].join("/"),
                        icon: <ContainerOutlined />,
                    },
                    {
                        label: "Duyệt bài",
                        key: [
                            routers.liquidation.index,
                            routers.liquidation.verify,
                        ].join("/"),
                        icon: <FileProtectOutlined />,
                    },
                    {
                        label: "Từ khóa nổi bật",
                        key: [
                            routers.liquidation.index,
                            routers.liquidation.hotKeyword,
                        ].join("/"),
                        icon: <ZoomInOutlined />,
                    },
                ],
            },
            {
                label: "Chợ cư dân",
                key: routers.market.index,
                icon: <ShoppingCartOutlined />,
                children: [
                    {
                        label: "Danh mục",
                        key: [
                            routers.market.index,
                            routers.market.category,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Bài viết",
                        key: [routers.market.index, routers.market.post].join(
                            "/"
                        ),
                        icon: <ContainerOutlined />,
                    },
                    {
                        label: "Duyệt bài",
                        key: [routers.market.index, routers.market.verify].join(
                            "/"
                        ),
                        icon: <FileProtectOutlined />,
                    },
                    {
                        label: "Từ khóa nổi bật",
                        key: [
                            routers.market.index,
                            routers.market.hotKeyword,
                        ].join("/"),
                        icon: <ZoomInOutlined />,
                    },
                ],
            },
            {
                label: "Sổ tay dịch vụ",
                key: routers.services.index,
                icon: <BookOutlined />,
                children: [
                    {
                        label: "Danh mục",
                        key: [
                            routers.services.index,
                            routers.services.category,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Danh mục con",
                        key: [
                            routers.services.index,
                            routers.services.subCategory,
                        ].join("/"),
                        icon: <AppstoreAddOutlined />,
                    },
                    {
                        label: "Điểm dịch vụ",
                        key: [
                            routers.services.index,
                            routers.services.service,
                        ].join("/"),
                        icon: <EnvironmentOutlined />,
                    },
                    {
                        label: "Đánh giá",
                        key: [
                            routers.services.index,
                            routers.services.review,
                        ].join("/"),
                        icon: <StarOutlined />,
                    },
                    {
                        label: "Banner",
                        key: [
                            routers.services.index,
                            routers.services.banner,
                        ].join("/"),
                        icon: <PictureOutlined />,
                    },
                    {
                        label: "Từ khóa nổi bật",
                        key: [
                            routers.services.index,
                            routers.services.hotKeyword,
                        ].join("/"),
                        icon: <ZoomInOutlined />,
                    },
                ],
            },
            {
                label: "Giao đồ ăn",
                key: routers.food.index,
                icon: <RestOutlined />,
                children: [
                    {
                        label: "Danh mục đồ ăn",
                        key: [routers.food.index, routers.food.category].join(
                            "/"
                        ),
                        icon: <InboxOutlined />,
                    },
                    {
                        label: "Quán ăn",
                        key: [routers.food.index, routers.food.restaurant].join(
                            "/"
                        ),
                        icon: <ShopOutlined />,
                    },
                    {
                        label: "Đơn hàng",
                        key: [routers.food.order.index].join("/"),
                        icon: <FormOutlined />,
                        children: [
                            {
                                label: "Trực tiếp",
                                key: [
                                    routers.food.index,
                                    routers.food.order.index,
                                    routers.food.order.newLive,
                                ].join("/"),
                                icon: <ThunderboltOutlined />,
                            },
                            {
                                label: "Đang xử lý",
                                key: [
                                    routers.food.index,
                                    routers.food.order.index,
                                    routers.food.order.new,
                                ].join("/"),
                                icon: <SnippetsOutlined />,
                            },
                            {
                                label: "Lịch sử",
                                key: [
                                    routers.food.index,
                                    routers.food.order.index,
                                    routers.food.order.history,
                                ].join("/"),
                                icon: <HistoryOutlined />,
                            },
                        ],
                    },
                    {
                        label: "Banner",
                        key: [routers.food.banner.index].join("/"),
                        icon: <PictureOutlined />,
                        children: [
                            {
                                label: "App user",
                                key: [
                                    routers.food.index,
                                    routers.food.banner.index,
                                    routers.food.banner.user,
                                ].join("/"),
                                icon: <MobileOutlined />,
                            },
                            {
                                label: "App quán",
                                key: [
                                    routers.food.index,
                                    routers.food.banner.index,
                                    routers.food.banner.merchant,
                                ].join("/"),
                                icon: <ShopOutlined />,
                            },
                        ],
                    },
                    {
                        label: "Chiến dịch",
                        key: [routers.food.index, routers.food.campaign].join(
                            "/"
                        ),
                        icon: <RiseOutlined />,
                    },
                    {
                        label: "Nhãn quán",
                        key: [
                            routers.food.index,
                            routers.food.restaurantLabel,
                        ].join("/"),
                        icon: <FileImageOutlined />,
                    },
                    {
                        label: "Bộ sưu tập",
                        key: [routers.food.index, routers.food.collection].join(
                            "/"
                        ),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Quảng cáo",
                        key: routers.food.ad.index,
                        icon: <FireOutlined />,
                        children: [
                            {
                                label: "Danh sách",
                                key: [
                                    routers.food.index,
                                    routers.food.ad.index,
                                    routers.food.ad.list,
                                ].join("/"),
                                icon: <RocketOutlined />,
                            },
                            {
                                label: "Loại hình",
                                key: [
                                    routers.food.index,
                                    routers.food.ad.index,
                                    routers.food.ad.type,
                                ].join("/"),
                                icon: <BlockOutlined />,
                            },
                        ],
                    },
                    {
                        label: "Voucher",
                        key: [routers.food.index, routers.food.voucher].join(
                            "/"
                        ),
                        icon: <TagOutlined />,
                    },
                    {
                        label: "Đánh giá",
                        key: [routers.food.index, routers.food.review].join(
                            "/"
                        ),
                        icon: <StarOutlined />,
                    },
                    {
                        label: "Thiết lập",
                        key: [routers.food.setup.index].join("/"),
                        icon: <SettingOutlined />,
                        children: [
                            {
                                label: "Thiêt lập chung",
                                key: [
                                    routers.food.index,
                                    routers.food.setup.index,
                                    routers.food.setup.general,
                                ].join("/"),
                                icon: <SettingOutlined />,
                            },
                            {
                                label: "Từ khóa nổi bật",
                                key: [
                                    routers.food.index,
                                    routers.food.setup.index,
                                    routers.food.setup.hotKeyword,
                                ].join("/"),
                                icon: <ZoomInOutlined />,
                            },
                            {
                                label: "Voucher đặt 1 đơn",
                                key: [
                                    routers.food.index,
                                    routers.food.setup.index,
                                    routers.food.setup.event,
                                ].join("/"),
                                icon: <TagOutlined />,
                            },
                        ],
                    },
                ],
            },
            {
                label: "Tài khoản",
                key: routers.account,
                icon: <UserOutlined />,
            },
        ],
        customer_service: [],
    };

    useEffect(() => {
        setSelectedKeys(location.pathname.slice(1));
        setOpenKeys(location.pathname.split("/").slice(1));
    }, [location]);

    const onSelectMenuItem = (item) => {
        navigate(item.key);
    };

    const onOpenChangeSubMenu = (item) => {
        setOpenKeys(item);
    };

    return (
        <>
            <Sider
                collapsible
                theme="dark"
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                className="overflow-auto h-screen !sticky inset-0"
                width={250}
            >
                <div style={{ textAlign: "center" }}>
                    <img
                        src={Logo}
                        alt="logo"
                        className="w-full"
                        style={{ width: collapsed ? "100%" : "130px" }}
                    />
                    {/* <h1
                        style={{
                            margin: 0,
                            fontSize: 48,
                            color: PRIMARY_COLOR,
                        }}
                    >
                        CUZI
                    </h1> */}
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    items={menuItems[userInfo.role]}
                    onSelect={onSelectMenuItem}
                    onOpenChange={onOpenChangeSubMenu}
                />
            </Sider>
        </>
    );
}
