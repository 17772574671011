import { Modal, Radio, Space } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import restaurantApi from "../api";

export default function SetAutoOpenModal({ open, onCancel, restaurant }) {
    const [time, setTime] = useState("none");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTime("none");
    }, [restaurant]);

    const onChange = (e) => {
        setTime(e.target.value);
    };

    const onSubmit = async () => {
        if (time && time !== "none") {
            try {
                setLoading(true);
                const data = await restaurantApi.setAutoOpen(restaurant, time);
                if (data.error) {
                    toast.error(data.error.msg);
                } else {
                    toast.success(
                        "Thiết lập thời gian tự động mở cửa lại thành công"
                    );
                    onCancel();
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        } else {
            onCancel();
        }
    };

    return (
        <>
            <Modal
                title="Thiết lập thời gian tự động mở cửa lại"
                okText="Lưu"
                cancelText="Huỷ"
                centered
                open={open}
                onOk={onSubmit}
                onCancel={onCancel}
                confirmLoading={loading}
                width={380}
            >
                <Radio.Group onChange={onChange} value={time}>
                    <Space direction="vertical">
                        <Radio value={"1h"}>Sau 1 giờ</Radio>
                        <Radio value={"4h"}>Sau 4 giờ</Radio>
                        <Radio value={"tomorrow"}>Cho đến ngày mai</Radio>
                        <Radio value={"none"}>Cho đến khi được mở lại</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    );
}
