import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_ROLES } from "utils/constants";
import Actions from "./components/Actions";
import AccountDetailModal from "./components/DetailModal";
import * as accountActions from "./slice";

function Account() {
    const dispatch = useDispatch();
    const { accounts, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.account);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingAccount, setSelectingAccount] = useState(null);
    const [searchRole, setSearchRole] = useState("");
    const [searchName, setSearchName] = useState("");

    useEffect(() => {
        dispatch(accountActions.fetchAccounts(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingAccount(null);
        }
    }, [isCompleted]);

    const onOpenDetailModal = (account = null) => {
        setSelectingAccount(account);
        setOpenDetailModal(true);
    };

    const onResetPassword = (account) => {
        Modal.confirm({
            title: `Xác nhận reset mật khẩu`,
            content: `Bạn có chắc chắn muốn reset mật khẩu tài khoản này về mặc định?`,
            onOk: () => {
                if (account._id) {
                    dispatch(accountActions.resetPasswordAccount(account._id));
                }
            },
            centered: true,
        });
    };

    const onSubmit = (account) => {
        if (account._id) {
            dispatch(accountActions.updateAccount(account));
        } else {
            dispatch(accountActions.createAccount(account));
        }
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingAccount(null);
        dispatch(accountActions.setIsProcessing(false));
    };

    const onSelectRoleSearch = (role) => {
        setSearchRole(role);
        dispatch(
            accountActions.fetchAccounts({
                ...pagination,
                role: role,
                name: searchName,
                current: 1,
            })
        );
    };
    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            accountActions.fetchAccounts({
                ...pagination,
                role: searchRole,
                name: searchName,
                current: 1,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            accountActions.fetchAccounts({
                ...pagination,
                role: searchRole,
                name: searchName,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Tên đăng nhập",
            width: "20%",
            ellipsis: {
                showTitle: false,
            },
            render: (record) => record.username,
        },
        {
            title: "Tên",
            dataIndex: "name",
            width: "20%",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Quyền",
            dataIndex: "role",
            width: "20%",
            render: (role) =>
                role === ADMIN_ROLES.SUPER_ADMIN ? (
                    <div className="p-2 bg-yellow-200 rounded-full text-center lg:w-fit">
                        <span>{role.toUpperCase()}</span>
                    </div>
                ) : (
                    role.toUpperCase()
                ),
        },
        {
            title: "Tham gia",
            dataIndex: "createdAt",
            width: "15%",
            render: (createdAt) => moment(createdAt).fromNow(true),
        },
        {
            title: "Trạng thái",
            dataIndex: "active",
            align: "center",
            width: "10%",
            render: (active) =>
                active ? (
                    <Tag color="#4caf50">Active</Tag>
                ) : (
                    <Tag color="#f44336">In-active</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "10%",
            render: (record) => (
                <Actions record={record} onEdit={onOpenDetailModal} />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Tài khoản
                </Typography.Title>
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Tìm theo quyền"
                        onChange={onSelectRoleSearch}
                    >
                        <Select.Option key="all" value="">
                            Tất cả
                        </Select.Option>
                        <Select.Option
                            key={ADMIN_ROLES.SUPER_ADMIN}
                            value={ADMIN_ROLES.SUPER_ADMIN}
                        >
                            Super Admin
                        </Select.Option>
                        <Select.Option
                            key={ADMIN_ROLES.ADMIN}
                            value={ADMIN_ROLES.ADMIN}
                        >
                            Admin
                        </Select.Option>
                        <Select.Option
                            key={ADMIN_ROLES.CUSTOMER_SERVICE}
                            value={ADMIN_ROLES.CUSTOMER_SERVICE}
                        >
                            Customer Service
                        </Select.Option>
                    </Select>
                    <Input.Search
                        placeholder="Tìm theo tên hoặc username"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                        style={{ width: 280 }}
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={accounts}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <AccountDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onResetPassword={onResetPassword}
                    onCancel={onCancel}
                    account={selectingAccount}
                />
            )}
        </>
    );
}

export default Account;
