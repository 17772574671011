import { createSlice } from "@reduxjs/toolkit";
import bannerApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    banners: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const bannerSlice = createSlice({
    name: "service-banner",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setBanners: (state, action) => {
            state.banners = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addBanner: (state, action) => {
            state.isCompleted = true;
            state.banners.unshift(action.payload);
        },
        editBanner: (state, action) => {
            state.isCompleted = true;
            state.banners = state.banners.map((banner) =>
                banner._id === action.payload._id ? action.payload : banner
            );
        },
        removeBanner: (state, action) => {
            state.isCompleted = true;
            state.banners = state.banners.filter(
                (user) => user._id !== action.payload._id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setBanners,
    setPagination,
    addBanner,
    editBanner,
    removeBanner,
} = bannerSlice.actions;

export const fetchBanners = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await bannerApi.getAll(params);
            dispatch(setBanners(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createBanner = (banner) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await bannerApi.create(banner);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addBanner(data));
                toast.success("Thêm banner mới thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateBanner = (banner, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await bannerApi.update(banner._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editBanner(data));
                toast.success("Cập nhật banner thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteBanner = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await bannerApi.delete(id);
            if (data.success) dispatch(removeBanner(id));
            toast.success("Xóa banner thành công!");
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default bannerSlice.reducer;
