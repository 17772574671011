import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Switch, Upload } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoThumbnailUrl, getSlug } from "utils/common";

function CategoryDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, category } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoThumbnailUrl(category?.image?.path || null)
    );
    const [image, setImage] = useState(null);

    const initialValues = category || {
        name: "",
        slug: "",
        priority: 99,
        inHome: false,
        inFilter: false,
    };
    const onValuesChange = (changedValues) => {
        if (changedValues.name) {
            form.setFieldValue("slug", getSlug(changedValues.name));
        }
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const category = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(category),
                    image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(category, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType = file.type === "image/svg+xml";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Danh mục thanh lý"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form
                // labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                // wrapperCol={{ xs: { span: 24 }, sm: { span: 16 } }}
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="name"
                    label="Tên danh mục"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="slug" label="Slug" dependencies={["name"]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item label="Hình ảnh">
                    <Upload
                        accept={"image/svg+xml"}
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
}

CategoryDetailModal.propTypes = {
    open: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    category: PropTypes.object,
};

CategoryDetailModal.defaultProps = {
    isProcessing: false,
    open: false,
    category: null,
};

export default CategoryDetailModal;
