import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as placeActions from "../../slice";

const { TextArea } = Input;

export default function TabDetail() {
    return (
        <>
            <Form.Item name="detail" label="Giới thiệu">
                <TextArea
                    autoSize={{ minRows: 3, maxRows: 8 }}
                    placeholder="Giới thiệu chi tiết"
                />
            </Form.Item>
            <Form.Item name="owner" label="Chủ địa điểm">
                <Input placeholder="ID chủ địa điểm" />
            </Form.Item>
            <Form.Item name="branches" label="Cơ sở khác">
                <Input placeholder="ID các cơ sở khác" />
            </Form.Item>
        </>
    );
}
