import {
    MinusCircleOutlined,
    PlusOutlined,
    SendOutlined,
} from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    Modal,
    Segmented,
    Select,
    Space,
    Switch,
    Upload,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    formatTime,
    getBase64,
    getPhotoThumbnailUrl,
    getSlug,
    isValidTime,
    parseTime,
} from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

export default function DishDetailModal(props) {
    const [form] = Form.useForm();
    const {
        open,
        onSubmit,
        onCancel,
        onApplyDiscount,
        onApplySellTime,
        dish,
        restaurant,
        menu,
    } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoThumbnailUrl(dish?.image?.path || null)
    );
    const [image, setImage] = useState(null);
    const [isDiscount, setIsDiscount] = useState(dish?.isDiscount);
    const [discountType, setDiscountType] = useState(
        dish?.discountType || "percent"
    );
    const [isSell24h, setIsSell24h] = useState(dish?.sell24h ?? true);

    const {
        isProcessing,
        menuInfo: { dishOptions, menus },
    } = useSelector((state) => state.foodRestaurant);

    const initialValues = dish
        ? {
              ...dish,
              sell24h: dish.sell24h ?? true,
              sellTime: dish.sellTime
                  ? dish.sellTime.map((time) => ({
                        from: parseTime(time.from),
                        to: parseTime(time.to),
                    }))
                  : [],
          }
        : {
              name: "",
              slug: "",
              description: "",
              priority: 99,
              isDiscount: false,
              isAvailable: true,
              isHidden: false,
              isHot: false,
              sell24h: true,
              sellTime: [],
              price: 0,
              rawPrice: 0,
              restaurant: restaurant._id,
              menu: menu ? menu._id : null,
              options: [],
              deleted: false,
              isEvent: false,
          };

    const checkTime = (rule, value) => {
        if (value.trim() === "") {
            return Promise.reject("Trường này là bắt buộc");
        }
        if (!isValidTime(value)) {
            return Promise.reject("Thời gian không đúng định dạng");
        }
        return Promise.resolve();
    };

    const onValuesChange = (changedValues, allValues) => {
        if (changedValues.name) {
            form.setFieldValue("slug", getSlug(changedValues.name));
        } else if (changedValues.isDiscount !== undefined) {
            setIsDiscount(changedValues.isDiscount);
            calculatorPrice(allValues);
        } else if (changedValues.discountType) {
            setDiscountType(changedValues.discountType);
            calculatorPrice(allValues);
        } else if (changedValues.discountValue !== undefined) {
            calculatorPrice(allValues);
        } else if (changedValues.sell24h !== undefined) {
            setIsSell24h(changedValues.sell24h);
        } else if (changedValues.isEvent !== undefined) {
            form.setFieldValue("menu", null);
        }
    };

    const calculatorPrice = (allValues) => {
        if (!allValues.isDiscount) {
            form.setFieldValue("price", allValues.rawPrice);
        } else {
            const price =
                allValues.rawPrice -
                (allValues.discountType === "direct"
                    ? allValues.discountValue
                    : (allValues.rawPrice * allValues.discountValue) / 100);
            form.setFieldValue("price", price > 0 ? price : 0);
        }
    };

    const handleApplyDiscount = () => {
        Modal.confirm({
            title: `Cập nhật giảm giá cho tất cả món`,
            content: `Thao tác này sẽ thay đổi giá bán của tất cả các món khác theo chương trình khuyến mãi như trên.\nBạn có chắc muốn tiếp tục ?`,
            onOk: async () => {
                const discountData = {
                    isDiscount: form.getFieldValue("isDiscount"),
                    discountType: form.getFieldValue("discountType"),
                    discountValue: form.getFieldValue("discountValue"),
                };
                await onApplyDiscount(discountData);
            },
            centered: true,
        });
    };

    const handleApplySellTime = () => {
        Modal.confirm({
            title: `Cập nhật thời gian bán cho tất cả món`,
            content: `Thao tác này sẽ thay đổi thời gian bán của tất cả các món khác theo thời gian bán như món này.\nBạn có chắc muốn tiếp tục ?`,
            onOk: async () => {
                const sellTimeData = {
                    sell24h: form.getFieldValue("sell24h"),
                };

                if (sellTimeData.sell24h) {
                    sellTimeData.sellTime = [];
                } else {
                    sellTimeData.sellTime = form
                        .getFieldValue("sellTime")
                        .map((time) => ({
                            from: formatTime(time.from),
                            to: formatTime(time.to),
                        }));
                    if (sellTimeData.sellTime.length === 0) {
                        toast.error("Vui lòng chọn thời gian bán");
                        return;
                    }
                }
                await onApplySellTime(sellTimeData);
            },
            centered: true,
        });
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                if (values.sell24h) {
                    values.sellTime = [];
                } else {
                    if (values.sellTime.length === 0) {
                        toast.error("Vui lòng chọn thời gian bán");
                        return;
                    }
                    values.sellTime = values.sellTime.map((time) => ({
                        from: formatTime(time.from),
                        to: formatTime(time.to),
                    }));
                }
                const formData = new FormData();
                const dish = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(dish),
                    image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(dish, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Món ăn"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={720}
        >
            <Form
                // layout="vertical"
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="name"
                    label="Tên món ăn"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="slug" label="Slug" dependencies={["name"]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name="description" label="Mô tả">
                    <Input />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item
                    name="rawPrice"
                    label="Giá gốc"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input
                        type="number"
                        suffix="₫"
                        min={0}
                        max={100}
                        style={{ width: "140px" }}
                    />
                </Form.Item>
                <Form.Item
                    label="Giảm giá"
                    style={{ marginBottom: 0 }}
                    required
                >
                    <Form.Item
                        name="isDiscount"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={handleApplyDiscount}
                            icon={<SendOutlined />}
                        >
                            Áp dụng cho tất cả món
                        </Button>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name="discountType"
                    label="Loại giảm giá"
                    hidden={!isDiscount}
                    required
                >
                    <Segmented
                        options={[
                            { label: "Phần trăm (%)", value: "percent" },
                            { label: "Số tiền (đ)", value: "direct" },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="discountValue"
                    label="Giá trị giảm"
                    tooltip="Trừ vào giá gốc"
                    hidden={!isDiscount}
                    required
                >
                    {discountType === "percent" ? (
                        <Input
                            type="number"
                            suffix="%"
                            min={0}
                            max={100}
                            style={{ width: "140px" }}
                            rules={[
                                {
                                    required: isDiscount,
                                    message: "Trường này là bắt buộc",
                                },
                            ]}
                        />
                    ) : (
                        <Input
                            type="number"
                            suffix="₫"
                            style={{ width: "140px" }}
                            rules={[
                                {
                                    required: isDiscount,
                                    message: "Trường này là bắt buộc",
                                },
                            ]}
                        />
                    )}
                </Form.Item>
                <Form.Item name="price" label="=> Giá bán" hidden={!isDiscount}>
                    <Input
                        type="number"
                        suffix="₫"
                        min={0}
                        max={100}
                        style={{ width: "140px" }}
                        readOnly
                    />
                </Form.Item>
                <Form.Item
                    name="menu"
                    label="Menu"
                    rules={[
                        ({ getFieldValue }) => ({
                            required: !getFieldValue("isEvent"),
                            message: "Trường này là bắt buộc",
                        }),
                    ]}
                >
                    <Select>
                        {menus.map((menu) => (
                            <Select.Option key={menu._id} value={menu._id}>
                                {menu.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="options" label="Nhóm tuỳ chọn">
                    <Select mode="multiple">
                        {dishOptions.map((option) => (
                            <Select.Option key={option._id} value={option._id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Bán 24h" style={{ marginBottom: 0 }} required>
                    <Form.Item
                        name="sell24h"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={handleApplySellTime}
                            icon={<SendOutlined />}
                        >
                            Áp dụng cho tất cả món
                        </Button>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Thời gian bán" hidden={isSell24h}>
                    <Form.List name="sellTime">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: "flex",
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, "from"]}
                                            rules={[{ validator: checkTime }]}
                                            style={{
                                                width: "100px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Input placeholder="Từ" />
                                        </Form.Item>
                                        <span
                                            style={{
                                                display: "inline-block",
                                                width: "10px",
                                                lineHeight: "32px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {" "}
                                            -{" "}
                                        </span>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "to"]}
                                            rules={[{ validator: checkTime }]}
                                            style={{
                                                width: "100px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Input placeholder="đến" />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    </Space>
                                ))}
                                <Form.Item style={{ width: "226px" }}>
                                    <Button
                                        type="dashed"
                                        onClick={() =>
                                            add({ from: "06:00", to: "21:00" })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Thêm giờ bán
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item label="Hình ảnh">
                    <Upload
                        accept={"image/*"}
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item label=":" style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="isAvailable"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch
                            checkedChildren="Còn hàng"
                            unCheckedChildren="Còn hàng"
                        />
                    </Form.Item>
                    <Form.Item
                        name="isHot"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch
                            checkedChildren="Nổi bật"
                            unCheckedChildren="Nổi bật"
                        />
                    </Form.Item>
                    <Form.Item
                        name="isHidden"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch
                            checkedChildren="Ẩn khỏi app"
                            unCheckedChildren="Ẩn khỏi app"
                        />
                    </Form.Item>
                    <Form.Item
                        name="isEvent"
                        valuePropName="checked"
                        style={{
                            display: "inline-block",
                            marginRight: "20px",
                        }}
                    >
                        <Switch
                            checkedChildren="Món deal"
                            unCheckedChildren="Món deal"
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
}
