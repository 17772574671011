import { createSlice } from "@reduxjs/toolkit";
import comboTransactionApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    comboTransactions: [],
    stats: {},
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const comboTransactionSlice = createSlice({
    name: "tuktuk-combo-transaction",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setComboTransactions: (state, action) => {
            state.comboTransactions = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addComboTransaction: (state, action) => {
            state.isCompleted = true;
            state.comboTransactions.unshift(action.payload);
        },
        editComboTransaction: (state, action) => {
            state.isCompleted = true;
            state.comboTransactions = state.comboTransactions.map(
                (comboTransaction) =>
                    comboTransaction._id === action.payload._id
                        ? action.payload
                        : comboTransaction
            );
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setComboTransactions,
    setPagination,
    addComboTransaction,
    editComboTransaction,
    removeComboTransaction,
    setStats,
} = comboTransactionSlice.actions;

export const fetchComboTransactions = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await comboTransactionApi.getAll(params);
            dispatch(setComboTransactions(data.data.transactions));
            dispatch(setStats(data.data.stats));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createTransaction = (comboTransaction) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await comboTransactionApi.create(comboTransaction);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addComboTransaction(data.data));
                toast.success("Tạo giao dịch mua gói thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateComboTransactionStatus = (comboTransaction) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await comboTransactionApi.updateStatus(
                comboTransaction
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editComboTransaction(data.data));
                toast.success("Thay đổi trạng thái giao dịch thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default comboTransactionSlice.reducer;
