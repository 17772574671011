import { Form, Modal, Tabs } from "antd";
import { useState } from "react";
import { getSlug, parseTime } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";
import TabDetail from "./Detail";
import TabInfo from "./Info";
import TimeModal from "./OpenTime";
import TabPhoto from "./Photo";

const getTimeTemp = (time) => {
    return {
        0: time[0].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        1: time[1].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        2: time[2].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        3: time[3].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        4: time[4].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        5: time[5].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        6: time[6].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
    };
};

const defaultTime = {
    0: [{ open: 390, close: 1320 }],
    1: [{ open: 390, close: 1320 }],
    2: [{ open: 390, close: 1320 }],
    3: [{ open: 390, close: 1320 }],
    4: [{ open: 390, close: 1320 }],
    5: [{ open: 390, close: 1320 }],
    6: [{ open: 390, close: 1320 }],
};

function PlaceDetailModal(props) {
    const [form] = Form.useForm();
    const {
        open,
        isProcessing,
        onSubmit,
        onCancel,
        place,
        categories,
        subCategories,
    } = props;
    const [avatar, setAvatar] = useState(null);
    const [images, setImages] = useState(null);
    const [openTimeModal, setOpenTimeModal] = useState(false);
    const [time, setTime] = useState(defaultTime);
    const initialValues = place
        ? {
              ...place,
              subCategories: place?.subCategories?.map((e) => e._id) || [],
          }
        : {
              name: "",
              slug: "",
              summary: "",
              detail: "",
              subCategories: [],
              branches: [],
              address: " - Vinhomes Smart City, Tây Mỗ, Nam Từ Liêm, Hà Nội",
              shortAddress: "",
              long: 105.814932,
              lat: 21.016621,
              time: { ...defaultTime },
              phone: "",
              owner: "",
              priority: 1,
              isHot: false,
              isPinned: false,
              isVerified: false,
              active: true,
          };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const place = { ...initialValues, ...values };
                formData.append("data", JSON.stringify(place));
                if (avatar) formData.append("avatar", avatar);
                if (place.imgNew?.length)
                    for (let image of place.imgNew) {
                        formData.append("images", image);
                    }
                onSubmit(place, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const onCancelGetTime = () => {
        setOpenTimeModal(false);
    };

    const onValueChange = (changedValues) => {
        if (changedValues["name"]) {
            form.setFieldValue("slug", getSlug(changedValues["name"]));
        }
    };

    const onSubmitTime = (_time) => {
        form.setFieldsValue({ time: _time, timeTemp: getTimeTemp(_time) });
        setOpenTimeModal(false);
    };

    const items = [
        {
            key: "info",
            label: "Thông tin",
            forceRender: true,
            disabled: isProcessing,
            children: (
                <TabInfo
                    form={form}
                    place={place}
                    setTime={setTime}
                    setOpenTimeModal={setOpenTimeModal}
                    setImage={setAvatar}
                    onCancel={onCancel}
                    image={avatar}
                    categories={categories}
                    subCategories={subCategories}
                />
            ),
        },
        {
            key: "detail",
            label: "Chi tiết",
            forceRender: true,
            disabled: isProcessing,
            children: (
                <TabDetail form={form} place={place} onCancel={onCancel} />
            ),
        },
        {
            key: "photo",
            label: "Hình ảnh",
            forceRender: true,
            disabled: isProcessing,
            children: (
                <TabPhoto form={form} place={place} onCancel={onCancel} />
            ),
        },
    ];

    return (
        <>
            <Modal
                title="Điểm dịch vụ"
                okText="Lưu"
                cancelText="Huỷ"
                centered
                open={open}
                onOk={onOK}
                onCancel={onCancel}
                confirmLoading={isProcessing}
                width={840}
            >
                <Form
                    form={form}
                    initialValues={initialValues}
                    onValuesChange={onValueChange}
                    layout="horizontal"
                    {...formItemLayoutHorizontal}
                >
                    <Tabs defaultActiveKey="info" items={items} />
                </Form>
            </Modal>
            <TimeModal
                time={time}
                open={openTimeModal}
                onCancel={onCancelGetTime}
                onDone={onSubmitTime}
            />
        </>
    );
}

export default PlaceDetailModal;
