import {
    FireOutlined,
    PictureOutlined,
    PlusOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    Modal,
    Segmented,
    Select,
    Space,
    Upload,
} from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBase64, getPhotoUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";
import * as adTypeActions from "../../type/slice";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const AD_POSTIONS = [
    { label: "Banner trang chủ", value: "bhome" },
    { label: "Banner tìm kiếm", value: "bsearch" },
    { label: "List tìm kiếm", value: "lsearch" },
    { label: "List danh mục", value: "lcategory" },
    { label: "List chiến dịch", value: "lcampaign" },
    { label: "Khác", value: "unknown" },
];

function AdDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, ad } = props;

    const dispatch = useDispatch();

    const { adTypes } = useSelector((state) => state.foodAdType);

    const [imageUrl, setImageUrl] = useState(
        getPhotoUrl(ad?.banner?.path || null, "w=200&h=102")
    );
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (open && adTypes.length === 0) {
            dispatch(adTypeActions.fetchAdTypes({ pageSize: 100 }));
        }
    }, [open]);

    const initialValues = ad
        ? {
              ...ad,
              adType: ad.adType?._id,
              restaurant: ad.restaurant._id,
              date: [
                  dayjs(ad.startDate, "YYYY-MM-DD"),
                  dayjs(ad.endDate, "YYYY-MM-DD"),
              ],
          }
        : {
              startDate: "",
              endDate: "",
              restaurant: "",
              type: "banner",
              note: "",
              position: [],
              active: true,
              time: [dayjs("00:00", "HH:mm"), dayjs("23:59", "HH:mm")],
          };
    const onValuesChange = (changedValues) => {
        if (changedValues.date) {
            form.setFieldValue(
                "startDate",
                changedValues.date[0].format("YYYY-MM-DD")
            );
            form.setFieldValue(
                "endDate",
                changedValues.date[1].format("YYYY-MM-DD")
            );
        }
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const ad = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(ad),
                    image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                delete ad.date;

                onSubmit(ad, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Quảng cáo"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={680}
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
            >
                <Form.Item name="startDate" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="endDate" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="adType" label="Loại hình" required>
                    <Select placeholder="--Loại hình--">
                        {adTypes.map((type) => (
                            <Select.Option key={type._id} value={type._id}>
                                {type.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="type" label="Dạng hiển thị" required>
                    <Segmented
                        options={[
                            {
                                label: "Banner",
                                value: "banner",
                                icon: <PictureOutlined />,
                            },
                            {
                                label: "List",
                                value: "list",
                                icon: <UnorderedListOutlined />,
                            },
                            {
                                label: "Khác",
                                value: "other",
                                icon: <FireOutlined />,
                            },
                        ]}
                    ></Segmented>
                </Form.Item>
                <Form.Item name="position" label="Vị trí" required>
                    <Checkbox.Group options={AD_POSTIONS} />
                </Form.Item>
                <Form.Item
                    name="restaurant"
                    label="ID Quán"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Nhập ID quán" />
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        placeholder="Ghi chú"
                    />
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Thời gian"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <RangePicker
                        style={{ width: "100%" }}
                        placeholder={[
                            "Thời gian bắt đầu",
                            "Thời gian kết thúc",
                        ]}
                        format="YYYY-MM-DD"
                    />
                </Form.Item>
                <Form.Item
                    name="active"
                    label="Trạng thái"
                    rules={[{ required: true }]}
                >
                    <Select placeholder="--Trạng thái--">
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>In-active</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Ảnh Banner">
                    <Space>
                        <Upload
                            accept={"image/*"}
                            name="banner"
                            listType="picture-card"
                            className="avatar-uploader-cover"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="image"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
}

AdDetailModal.propTypes = {
    open: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    ad: PropTypes.object,
};

AdDetailModal.defaultProps = {
    isProcessing: false,
    open: false,
    ad: null,
};

export default AdDetailModal;
