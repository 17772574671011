import { useSelector } from "react-redux";
import { Forbidden } from "components";

const Private = ({ component: Component, roles = [], ...rest }) => {
    const { userInfo } = useSelector((state) => state.auth);

    if (roles.length === 0 || roles.includes(userInfo.role)) {
        return <Component {...rest} />;
    } else {
        return <Forbidden />;
    }
};

export default Private;
