import {
    Col,
    Divider,
    Empty,
    Modal,
    Pagination,
    Row,
    Select,
    Space,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostDetailModal from "./components/DetailModal";
import PostItem from "./components/PostItem";
import * as postActions from "./slice";

function Post() {
    const dispatch = useDispatch();
    const { posts, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.newsPost);

    useEffect(() => {
        dispatch(postActions.fetchPosts({ ...pagination, status: "public" }));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setelectedPost(null);
        }
    }, [isCompleted]);

    const onChangeSearchStatus = (status) => {
        dispatch(
            postActions.fetchPosts({
                ...pagination,
                current: 1,
                status,
            })
        );
    };

    const onChangePagination = (current) => {
        dispatch(
            postActions.fetchPosts({
                ...pagination,
                current,
            })
        );
    };

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectedPost, setelectedPost] = useState(null);

    const onSubmitDetailPost = (post, formData) => {
        dispatch(postActions.updatePost(post, formData));
    };

    const onDetail = (post = null) => {
        setelectedPost(post);
        setOpenDetailModal(true);
    };

    const onCancelDetailPost = () => {
        setelectedPost(null);
        setOpenDetailModal(false);
    };

    const onDeletePost = (postID) => {
        Modal.confirm({
            title: `Xác nhận xoá bài viết`,
            content: `Bạn có chắc muốn xoá bài viết này?`,
            onOk: () => dispatch(postActions.deletePost(postID)),
            centered: true,
        });
    };

    const onHidePost = (postID) => {
        Modal.confirm({
            title: `Xác nhận ẩn bài viết`,
            content: `Bạn có chắc muốn ẩn bài viết này?`,
            onOk: () => dispatch(postActions.hidePost(postID)),
            centered: true,
        });
    };

    const onShowPost = (postID) => {
        Modal.confirm({
            title: `Xác nhận hiển thị bài viết`,
            content: `Bạn có chắc muốn  hiển thị bài viết này?`,
            onOk: () => dispatch(postActions.showPost(postID)),
            centered: true,
        });
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Bài đăng trong bản tin cư dân
                </Typography.Title>
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 180 }}
                        placeholder="Tìm theo trạng thái"
                        onChange={onChangeSearchStatus}
                    >
                        <Select.Option key={"all"} value={""}>
                            Tất cả
                        </Select.Option>
                        <Select.Option key={"public"} value={"public"}>
                            Công khai
                        </Select.Option>
                        <Select.Option key={"pending"} value={"pending"}>
                            Chờ duyệt
                        </Select.Option>
                        <Select.Option key={"reject"} value={"reject"}>
                            Từ chối
                        </Select.Option>
                        <Select.Option key={"hidden"} value={"hidden"}>
                            Bị ẩn
                        </Select.Option>
                        <Select.Option key={"deleted"} value={"deleted"}>
                            Đã xóa
                        </Select.Option>
                    </Select>
                </Space>
            </Row>
            <Divider />
            <Row gutter={[30, 30]}>
                {posts.length === 0 ? (
                    <div className="flex justify-center w-full">
                        <Empty description="Không có dữ liệu" />
                    </div>
                ) : (
                    posts.map((post) => (
                        <Col span={6} key={post._id}>
                            <PostItem
                                post={post}
                                setOpenDetailModal={setOpenDetailModal}
                                onDetail={onDetail}
                                onDelete={onDeletePost}
                                onHide={onHidePost}
                                onShow={onShowPost}
                            />
                        </Col>
                    ))
                )}
            </Row>
            <br />
            <br />
            <div className="flex justify-center	">
                <Pagination
                    defaultCurrent={pagination?.current || 1}
                    pageSize={pagination?.pageSize || 10}
                    total={pagination?.total || 0}
                    onChange={onChangePagination}
                />
            </div>
            <PostDetailModal
                open={openDetailModal}
                onCancel={onCancelDetailPost}
                onSubmit={onSubmitDetailPost}
                post={selectedPost}
            />
        </>
    );
}

export default Post;
