import { FieldTimeOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Select,
    Space,
    Switch,
    Upload,
} from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import commonApi from "utils/api";
import {
    classNames,
    getBase64,
    getPhotoThumbnailUrl,
    parseTime,
} from "utils/common";

const getTimeTemp = (time) => {
    return {
        0: time[0].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        1: time[1].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        2: time[2].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        3: time[3].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        4: time[4].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        5: time[5].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
        6: time[6].map((t) => ({
            open: parseTime(t.open),
            close: parseTime(t.close),
        })),
    };
};

export default function TabInfo({
    form,
    place,
    setTime,
    setOpenTimeModal,
    setImage,
    subCategories,
}) {
    const [imageUrl, setImageUrl] = useState(
        getPhotoThumbnailUrl(place?.avatar?.path || null)
    );
    const [isGettingGeoAddress, setIsGettingGeoAddress] = useState(false);
    const [isGettingGeoAddressByPlusCode, setIsGettingGeoAddressByPlusCode] =
        useState(false);

    const onGetTime = () => {
        const time = { ...form.getFieldValue("time") };
        setTime(getTimeTemp(time));
        setOpenTimeModal(true);
    };

    const placeStatusProperties = [
        { name: "active", label: "Kích hoạt" },
        { name: "isHot", label: "Nổi bật" },
        { name: "isPinned", label: "Ghim" },
        { name: "isVerified", label: "Đã duyệt" },
    ];

    const shouldUpdatePlaceStatusFormItem = (prev, current) => {
        for (const property of placeStatusProperties) {
            if (prev[property.name] !== current[property.name]) {
                return true;
            }
        }
        return false;
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const onGetGeoAddress = async () => {
        if (!isGettingGeoAddress) {
            const address = form.getFieldValue("address");
            try {
                setIsGettingGeoAddress(true);
                const data = await commonApi.getGeoAddress(address);
                const { long, lat } = data;
                form.setFieldsValue({ long, lat });
            } catch (e) {
                toast.error("Lấy tọa độ thất bại! Vui lòng thử lại sau");
            } finally {
                setIsGettingGeoAddress(false);
            }
        }
    };

    const onGetGeoAddressByPlusCode = async () => {
        if (!isGettingGeoAddressByPlusCode) {
            const plusCode = form.getFieldValue("plusCode");
            try {
                setIsGettingGeoAddressByPlusCode(true);
                const data = await commonApi.getGeoAddressByPlusCode(plusCode);
                if (data.error) throw new Error(data.error.msg);
                const { long, lat } = data;
                form.setFieldsValue({ long, lat });
            } catch (e) {
                toast.error("Lấy tọa độ thất bại! Vui lòng thử lại sau");
            } finally {
                setIsGettingGeoAddressByPlusCode(false);
            }
        }
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={14}>
                    <Form.Item
                        name="name"
                        label="Tên"
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                        labelCol={{
                            xs: { span: 24 },
                            sm: { span: 7 },
                        }}
                        wrapperCol={{
                            xs: { span: 24 },
                            sm: { span: 17 },
                        }}
                    >
                        <Input placeholder="Tên địa điểm" />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        name="slug"
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        label={false}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="subCategories"
                label="Danh mục"
                rules={[{ required: true, type: "array" }]}
            >
                <Select
                    mode="multiple"
                    placeholder="--Danh mục--"
                    optionFilterProp="label"
                    options={subCategories.map((category) => ({
                        label: category.name,
                        value: category._id,
                    }))}
                />
            </Form.Item>
            <Form.Item
                name="summary"
                label="Mô tả ngắn"
                rules={[{ required: true }]}
            >
                <Input placeholder="Mô tả ngắn về dịch vụ (vd: Quán cà phê)" />
            </Form.Item>

            <Form.Item
                name="shortAddress"
                label="Địa chỉ ngắn"
                rules={[
                    {
                        required: true,
                        message: "Trường này là bắt buộc",
                    },
                ]}
            >
                <Input placeholder="Địa chỉ ngắn gọn (vd: S403.3505A)" />
            </Form.Item>
            <Form.Item
                name="address"
                label="Địa chỉ chính xác"
                rules={[
                    {
                        required: true,
                        message: "Trường này là bắt buộc",
                    },
                ]}
            >
                <Input
                    placeholder="Địa chỉ đầy đủ"
                    allowClear
                    addonAfter={
                        <div
                            className={classNames(
                                "select-none",
                                isGettingGeoAddress
                                    ? "opacity-50"
                                    : "cursor-pointer"
                            )}
                            onClick={onGetGeoAddress}
                        >
                            Lấy tọa độ
                        </div>
                    }
                />
            </Form.Item>
            <Form.Item name="plusCode" label="Plus code">
                <Input
                    placeholder="Nhập plus code để lấy toạ độ (không bắt buộc)"
                    addonAfter={
                        <div
                            className={classNames(
                                "select-none",
                                isGettingGeoAddressByPlusCode
                                    ? "opacity-50"
                                    : "cursor-pointer"
                            )}
                            onClick={onGetGeoAddressByPlusCode}
                        >
                            Lấy tọa độ
                        </div>
                    }
                />
            </Form.Item>
            <Form.Item label="Tọa độ" style={{ marginBottom: 0 }}>
                <Row gutter={24}>
                    <Col span={9}>
                        <Form.Item name="long">
                            <Input addonBefore="Kinh độ" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name="lat">
                            <Input addonBefore="Vĩ độ" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button
                            block
                            href={`https://www.google.com/maps/search/?api=1&query=${form.getFieldValue(
                                "lat"
                            )},${form.getFieldValue("long")}`}
                            target="_blank"
                        >
                            Xem vị trí
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item name="time" label="T.gian mở cửa">
                <div
                    onClick={onGetTime}
                    style={{
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#1890ff",
                        margin: "4px 8px",
                    }}
                >
                    <FieldTimeOutlined /> Thiết lập giờ mở cửa
                </div>
            </Form.Item>
            <Form.Item
                name="phone"
                label="Số điện thoại"
                rules={[{ required: true }]}
            >
                <Input placeholder="SĐT" />
            </Form.Item>

            <Form.Item name="priority" label="Thứ tự ưu tiên">
                <Input placeholder="Thứ tự ưu tiên" type={"number"} min={0} />
            </Form.Item>
            <Form.Item label="Ảnh đại diện" rules={[{ required: true }]}>
                <Upload
                    accept={"image/*"}
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                >
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt="image"
                            style={{ width: "100%" }}
                        />
                    ) : (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    )}
                </Upload>
            </Form.Item>
            <Row>
                <Col xs={0} sm={4}></Col>
                <Col xs={24} sm={20}>
                    <Form.Item shouldUpdate={shouldUpdatePlaceStatusFormItem}>
                        {() => (
                            <Space size="large" wrap>
                                {placeStatusProperties.map((property) => {
                                    const isStatusTrue = form.getFieldValue(
                                        property.name
                                    );
                                    return (
                                        <Form.Item
                                            name={property.name}
                                            key={property.name}
                                            valuePropName="checked"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Switch
                                                defaultChecked={isStatusTrue}
                                                checkedChildren={property.label}
                                                unCheckedChildren={
                                                    property.label
                                                }
                                            />
                                        </Form.Item>
                                    );
                                })}
                            </Space>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
