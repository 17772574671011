import { createSlice } from "@reduxjs/toolkit";
import requestApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    requests: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const requestSlice = createSlice({
    name: "tuktuk-request",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setRequests: (state, action) => {
            state.requests = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addRequest: (state, action) => {
            state.isCompleted = true;
            state.requests.unshift(action.payload);
        },
        editRequest: (state, action) => {
            state.isCompleted = true;
            state.requests = state.requests.map((request) =>
                request._id === action.payload._id ? action.payload : request
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setRequests,
    setPagination,
    addRequest,
    editRequest,
    removeRequest,
} = requestSlice.actions;

export const fetchRequests = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await requestApi.getAll(params);
            dispatch(setRequests(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const updateRequestStatus = (request) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await requestApi.updateStatus(request);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRequest(data.data));
                toast.success("Thay đổi trạng thái giao dịch thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default requestSlice.reducer;
