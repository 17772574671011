import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Switch, Upload } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

function ComboDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, combo } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoUrl(combo?.image?.path || null)
    );
    const [image, setImage] = useState(null);

    const initialValues = combo
        ? {
              ...combo,
          }
        : {
              name: "",
              content: "",
              price: "",
              rawPrice: "",
              saved: "",
              priority: 100,
              active: false,
          };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const combo = { ...initialValues, ...values };
                Object.entries({
                    data: JSON.stringify(combo),
                    image: image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(combo, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Gói tiết kiệm"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="680px"
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="content"
                    label="Nội dung"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                </Form.Item>
                <Form.Item label="Ảnh">
                    <Upload
                        accept={"image/*"}
                        name="combo"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item
                    name="ticket"
                    label="Số lượt"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input
                        type={"number"}
                        min={0}
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="price"
                    label="Giá tiền"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="rawPrice" label="Giá gốc">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item
                    name="saved"
                    label="Tiết kiệm"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item
                    label="Kích hoạt"
                    name="active"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ComboDetailModal;
