import { createSlice } from "@reduxjs/toolkit";
import reviewApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    reviews: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const reviewSlice = createSlice({
    name: "food-restaurant-review",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setReviews: (state, action) => {
            state.reviews = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addReview: (state, action) => {
            state.isCompleted = true;
            state.reviews.unshift(action.payload);
        },
        editReview: (state, action) => {
            state.isCompleted = true;
            state.reviews = state.reviews.map((review) =>
                review._id === action.payload._id ? action.payload : review
            );
        },
        removeReview: (state, action) => {
            state.isCompleted = true;
            state.reviews = state.reviews.filter(
                (user) => user._id !== action.payload._id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setReviews,
    setPagination,
    addReview,
    editReview,
    removeReview,
} = reviewSlice.actions;

export const fetchReviews = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await reviewApi.getAllRestaurant(params);
            dispatch(setReviews(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const updateReview = (review) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await reviewApi.update(review);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editReview(data));
                toast.success("Cập nhật đánh giá thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateReviewHiddenStatus = (review, hiddenReason) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await reviewApi.updateHiddenStatus(
                review._id,
                hiddenReason
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editReview({ ...review, ...data.data }));
                toast.success("Cập nhật trạng thái đánh giá thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteReview = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await reviewApi.delete(id);
            dispatch(removeReview(data));
            toast.success("Xóa đánh giá thành công!");
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default reviewSlice.reducer;
