import { createSlice } from "@reduxjs/toolkit";
import adTypeApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    adTypes: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const adSlice = createSlice({
    name: "food-ad-type",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setAdTypes: (state, action) => {
            state.adTypes = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addAdType: (state, action) => {
            state.isCompleted = true;
            state.adTypes.unshift(action.payload);
        },
        editAdType: (state, action) => {
            state.isCompleted = true;
            state.adTypes = state.adTypes.map((ad) =>
                ad._id === action.payload._id ? action.payload : ad
            );
        },
        removeAdType: (state, action) => {
            state.isCompleted = true;
            state.adTypes = state.adTypes.filter(
                (ad) => ad._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setAdTypes,
    setPagination,
    addAdType,
    editAdType,
    removeAdType,
} = adSlice.actions;

export const fetchAdTypes = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await adTypeApi.getAll(params);
            dispatch(setAdTypes(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createAdType = (adType) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await adTypeApi.create(adType);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addAdType(data));
                toast.success("Thêm loại hình quảng cáo mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateAdType = (adType) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await adTypeApi.update(adType);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editAdType(data));
                toast.success("Cập nhật loại hình quảng cáo thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteAdType = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await adTypeApi.delete(id);
            if (data.success) {
                dispatch(removeAdType(id));
                toast.success("Xóa loại hình quảng cáo thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa loại hình quảng cáo thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default adSlice.reducer;
