import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Form,
    Input,
    Modal,
    Space,
    Switch,
    Upload,
    Button,
    Select,
} from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBase64, getPhotoUrl } from "utils/common";
import { formItemLayoutHorizontal } from "utils/constants";

const tagOptions = [
    { label: "Orange Tag", value: "#d46b08;#fff7e6" },
    { label: "Blue Tag", value: "#0958d9;#e6f4ff" },
    { label: "Green Tag", value: "#389e0d;#f6ffed" },
    { label: "Geekblue Tag", value: "#1d39c4;#f0f5ff" },
    { label: "Purple Tag", value: "#531dab;#f9f0ff" },
];

function PlaceDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, place } = props;
    const [imageUrl, setImageUrl] = useState(
        getPhotoUrl(place?.image?.path || null)
    );
    const [image, setImage] = useState(null);

    const initialValues = place
        ? {
              ...place,
              tags:
                  place.tags?.map((e) => {
                      const tag = {
                          text: e.text,
                          color: `${e.color};${e.bgColor}`,
                      };
                      return tag;
                  }) || [],
          }
        : {
              name: "",
              tags: [],
              priority: 100,
              inHome: false,
          };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                const place = { ...initialValues, ...values };
                place.tags = place.tags.map((tag) => {
                    const _tag = {
                        color: tag.color.split(";")[0],
                        bgColor: tag.color.split(";")[1],
                        text: tag.text,
                    };
                    return _tag;
                });
                Object.entries({
                    data: JSON.stringify(place),
                    image: image,
                }).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                onSubmit(place, formData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const beforeUpload = async (file) => {
        const validType =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/webp";
        if (!validType) {
            toast.error("Chỉ chấp nhận file ảnh!");
        } else {
            const preview = await getBase64(file);
            setImageUrl(preview);
            setImage(file);
        }
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            title="Gói tiết kiệm"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="680px"
        >
            <Form
                {...formItemLayoutHorizontal}
                form={form}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Ảnh">
                    <Upload
                        accept={"image/*"}
                        name="place"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="image"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item label="Tags">
                    <Form.List name="tags">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        align="start"
                                        style={{
                                            display: "flex",
                                            marginBottom: 8,
                                        }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, "color"]}
                                            style={{
                                                width: "140px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Select options={tagOptions} />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "text"]}
                                            style={{
                                                width: "180px",
                                                marginBottom: "10px",
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Nội dung không được để trống",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Nội dung" />
                                        </Form.Item>
                                        <Button
                                            style={{ height: "32px" }}
                                            size={"small"}
                                            onClick={() => remove(name)}
                                        >
                                            <CloseOutlined />
                                        </Button>
                                    </Space>
                                ))}
                                <Button
                                    style={{ height: "32px" }}
                                    size={"small"}
                                    onClick={() =>
                                        add({
                                            color: tagOptions[0].value,
                                            text: "",
                                        })
                                    }
                                >
                                    <PlusOutlined /> Thêm tag
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item name="priority" label="Thứ tự ưu tiên">
                    <Input type={"number"} min={0} />
                </Form.Item>
                <Form.Item
                    label="Hiển thị"
                    name="inHome"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default PlaceDetailModal;
