import axiosClient from "utils/api/axios-client";

const buildingApi = {
    getAll: (params) => {
        const url = "/api/v1/buildings";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/buildings/${id}`;
        return axiosClient.get(url);
    },
    create: (account) => {
        const url = `/api/v1/buildings/`;
        return axiosClient.post(url, account);
    },
    update: (account) => {
        const url = `/api/v1/buildings/${account._id}`;
        return axiosClient.put(url, account);
    },
    delete: (id) => {
        const url = `/api/v1/buildings/${id}`;
        return axiosClient.delete(url);
    },
};

export default buildingApi;
