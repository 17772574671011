import {
    Button,
    Collapse,
    Form,
    Input,
    InputNumber,
    Select,
    Space,
    Switch,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { currencyToPrice, priceToCurrency } from "utils/common";
import * as restaurantActions from "../../slice";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export default function TabConfig({ form, restaurant, onCancel, config }) {
    const dispatch = useDispatch();
    const { isProcessing } = useSelector((state) => state.foodRestaurant);
    const [isDeliveryExtraActive, setIsDeliveryExtraActive] = useState(false);
    const [deliveryExtraValueType, setDeliveryExtraValueType] =
        useState("percent");

    useEffect(() => {
        setIsDeliveryExtraActive(
            form.getFieldValue("config")?.deliveryFee?.extra?.isActive || false
        );
        setDeliveryExtraValueType(
            form.getFieldValue("config")?.deliveryFee?.extra?.valueType ||
                "percent"
        );
    }, [form]);

    useEffect(() => {
        console.log(config);
        if (config?.deliveryFee?.extra?.isActive !== undefined) {
            setIsDeliveryExtraActive(config.deliveryFee.extra.isActive);
        } else if (config?.deliveryFee?.extra?.valueType !== undefined) {
            setDeliveryExtraValueType(config.deliveryFee.extra.valueType);
        }
    }, [config]);

    const onSubmit = () => {
        form.validateFields()
            .then((values) => {
                dispatch(
                    restaurantActions.updateRestaurantConfig(
                        restaurant,
                        values.config
                    )
                );
            })
            .catch((err) => {
                if (
                    err.errorFields?.some((field) => field.name[0] === "config")
                ) {
                    toast.error("Vui lòng kiểm tra lại thông tin");
                } else {
                    dispatch(
                        restaurantActions.updateRestaurantConfig(
                            restaurant,
                            err.values.config
                        )
                    );
                }
            });
    };

    const items = [
        {
            key: "method",
            label: "Tùy chọn giao hàng",
            children: (
                <>
                    <Form.Item
                        name={["config", "delivery", "isAvailable"]}
                        label="Giao tận nơi"
                        {...layout}
                    >
                        <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                    </Form.Item>
                    {/* <Row>
                <Col xs={0} sm={4}> */}
                    <Form.Item label="Tự đến lấy" {...layout}>
                        <Space size="large" wrap>
                            <Form.Item
                                name={["config", "pickup", "isAvailable"]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bật"
                                    unCheckedChildren="Tắt"
                                />
                            </Form.Item>
                            <Form.Item
                                name={["config", "pickup", "paymentRequired"]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bắt buộc thanh toán trước"
                                    unCheckedChildren="Bắt buộc thanh toán trước"
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Ăn tại quán" {...layout}>
                        <Space size="large" wrap>
                            <Form.Item
                                name={["config", "onsite", "isAvailable"]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bật"
                                    unCheckedChildren="Tắt"
                                />
                            </Form.Item>
                            <Form.Item
                                name={["config", "onsite", "paymentRequired"]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bắt buộc thanh toán trước"
                                    unCheckedChildren="Bắt buộc thanh toán trước"
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                </>
            ),
        },
        {
            key: "fee",
            label: "Phí giao hàng",
            children: (
                <>
                    <h4 style={{ marginLeft: 30, marginTop: 0 }}>• Nội khu</h4>
                    <Form.Item
                        name={["config", "deliveryFee", "internal", "isFree"]}
                        label="Freeship tất cả đơn"
                        valuePropName="checked"
                        {...layout}
                    >
                        <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                    </Form.Item>
                    <Form.Item label="Freeship đơn tối thiểu" {...layout}>
                        <Space size="large" wrap>
                            <Form.Item
                                name={[
                                    "config",
                                    "deliveryFee",
                                    "internal",
                                    "isFreeWithMinOrder",
                                ]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bật"
                                    unCheckedChildren="Tắt"
                                />
                            </Form.Item>
                            <Form.Item
                                name={[
                                    "config",
                                    "deliveryFee",
                                    "internal",
                                    "minOrderValueToFree",
                                ]}
                                noStyle
                            >
                                <InputNumber
                                    style={{ width: 125 }}
                                    formatter={(value) =>
                                        priceToCurrency(value)
                                    }
                                    parser={(value) => currencyToPrice(value)}
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item
                        name={["config", "deliveryFee", "internal", "value"]}
                        label="Phí giao hàng/đơn"
                        {...layout}
                    >
                        <InputNumber
                            style={{ width: 200 }}
                            formatter={(value) => priceToCurrency(value)}
                            parser={(value) => currencyToPrice(value)}
                        />
                    </Form.Item>
                    <h4 style={{ marginLeft: 30 }}>• Ngoài khu</h4>
                    <Form.Item
                        name={["config", "deliveryFee", "external", "isFree"]}
                        label="Freeship tất cả đơn"
                        valuePropName="checked"
                        {...layout}
                    >
                        <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                    </Form.Item>
                    <Form.Item label="Freeship đơn tối thiểu" {...layout}>
                        <Space size="large" wrap>
                            <Form.Item
                                name={[
                                    "config",
                                    "deliveryFee",
                                    "external",
                                    "isFreeWithMinOrder",
                                ]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bật"
                                    unCheckedChildren="Tắt"
                                />
                            </Form.Item>
                            <Form.Item
                                name={[
                                    "config",
                                    "deliveryFee",
                                    "external",
                                    "minOrderValueToFree",
                                ]}
                                noStyle
                            >
                                <InputNumber
                                    style={{ width: 125 }}
                                    formatter={(value) =>
                                        priceToCurrency(value)
                                    }
                                    parser={(value) => currencyToPrice(value)}
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item label="Freeship trong bán kính" {...layout}>
                        <Space size="large" wrap>
                            <Form.Item
                                name={[
                                    "config",
                                    "deliveryFee",
                                    "external",
                                    "isFreeWithDistance",
                                ]}
                                noStyle
                            >
                                <Switch
                                    checkedChildren="Bật"
                                    unCheckedChildren="Tắt"
                                />
                            </Form.Item>
                            <Form.Item
                                name={[
                                    "config",
                                    "deliveryFee",
                                    "external",
                                    "minDistanceToFree",
                                ]}
                                noStyle
                            >
                                <InputNumber
                                    style={{ width: 125 }}
                                    suffix="km"
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item
                        name={["config", "deliveryFee", "external", "value"]}
                        label="Phí giao hàng/km"
                        {...layout}
                    >
                        <InputNumber
                            style={{ width: 200 }}
                            formatter={(value) => priceToCurrency(value)}
                            parser={(value) => currencyToPrice(value)}
                        />
                    </Form.Item>
                    {/* <h4 style={{ marginLeft: 30 }}>• Tăng phí ship</h4>

                    <Form.Item
                        name={["config", "deliveryFee", "extra", "isActive"]}
                        label="Tăng phí ship"
                        {...layout}
                    >
                        <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                    </Form.Item>
                    <Form.Item
                        name={["config", "deliveryFee", "extra", "valueType"]}
                        label="Tăng phí ship theo"
                        {...layout}
                    >
                        <Select
                            style={{ width: 200 }}
                            disabled={!isDeliveryExtraActive}
                        >
                            <Select.Option value="percent">
                                Phần trăm (%)
                            </Select.Option>
                            <Select.Option value="fixed">
                                Số tiền cố định (đ)
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={["config", "deliveryFee", "extra", "value"]}
                        label="Giá trị tăng"
                        rules={[
                            {
                                required: isDeliveryExtraActive,
                                message: "Trường này là bắt buộc",
                            },
                            {
                                type: "number",
                                max:
                                    deliveryExtraValueType === "percent"
                                        ? 100
                                        : 10000000000,
                                message:
                                    deliveryExtraValueType === "percent"
                                        ? "% không được lớn hơn 100"
                                        : "",
                            },
                            {
                                type: "number",
                                min: isDeliveryExtraActive ? 1 : undefined,
                                message: "Giá trị tăng phí ship phải lớn hơn 0",
                            },
                        ]}
                        {...layout}
                    >
                        <InputNumber
                            style={{ width: 200 }}
                            disabled={!isDeliveryExtraActive}
                            min={0}
                            max={
                                deliveryExtraValueType === "percent"
                                    ? 100
                                    : 10000000000
                            }
                            suffix={
                                deliveryExtraValueType === "percent" ? "%" : "đ"
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Lý do tăng"
                        name={["config", "deliveryFee", "extra", "reason"]}
                        rules={[
                            {
                                required: isDeliveryExtraActive,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                        {...layout}
                    >
                        <Input disabled={!isDeliveryExtraActive} />
                    </Form.Item> */}
                </>
            ),
        },
    ];

    return (
        <>
            <Collapse
                size="large"
                defaultActiveKey={["method", "fee"]}
                items={items}
                ghost
            />
            {restaurant && (
                <>
                    <Button
                        className="float-right"
                        type="primary"
                        onClick={onSubmit}
                        loading={isProcessing}
                    >
                        Lưu thay đổi thiết lập
                    </Button>
                    <Button
                        className="float-right mr-4"
                        onClick={onCancel}
                        disabled={isProcessing}
                    >
                        Huỷ
                    </Button>
                </>
            )}
        </>
    );
}
