import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Image,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoUrl } from "utils/common";
import Actions from "./components/Actions";
import RestaurantLabelDetailModal from "./components/DetailModal";
import * as restaurantLabelActions from "./slice";
import dayjs from "dayjs";

const { Paragraph } = Typography;

function RestaurantLabel() {
    const dispatch = useDispatch();
    const {
        restaurantLabels,
        pagination,
        isLoading,
        isProcessing,
        isCompleted,
    } = useSelector((state) => state.foodRestaurantLabel);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingRestaurantLabel, setSelectingRestaurantLabel] =
        useState(null);
    const [searchName, setSearchName] = useState("");

    useEffect(() => {
        dispatch(restaurantLabelActions.fetchRestaurantLabels(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingRestaurantLabel(null);
        }
    }, [isCompleted]);

    const onOpenDetailModal = (restaurantLabel = null) => {
        setSelectingRestaurantLabel(restaurantLabel);
        setOpenDetailModal(true);
    };

    const onDelete = (restaurantLabel) => {
        Modal.confirm({
            title: `Xác nhận xoá nhãn quán`,
            content: `Bạn có chắc chắn muốn xoá nhãn quán này?`,
            onOk: () =>
                dispatch(
                    restaurantLabelActions.deleteRestaurantLabel(
                        restaurantLabel._id
                    )
                ),
            centered: true,
        });
    };

    const onSubmit = (restaurantLabel, formData) => {
        if (restaurantLabel._id) {
            dispatch(
                restaurantLabelActions.updateRestaurantLabel(
                    restaurantLabel,
                    formData
                )
            );
        } else {
            dispatch(restaurantLabelActions.createRestaurantLabel(formData));
        }
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingRestaurantLabel(null);
        dispatch(restaurantLabelActions.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            restaurantLabelActions.fetchRestaurantLabels({
                ...pagination,
                name: searchName,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            restaurantLabelActions.fetchRestaurantLabels({
                ...pagination,
                name: searchName,
            })
        );
    };
    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Hình ảnh",
            dataIndex: "image",
            width: "280px",
            render: (image) =>
                image ? (
                    <Image
                        alt="Image of restaurantLabel"
                        src={getPhotoUrl(image?.path || null)}
                        width={250}
                        height={100}
                    />
                ) : null,
        },
        {
            title: "Tên nhãn",
            dataIndex: "name",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Số quán",
            dataIndex: "restaurantCount",
            align: "center",
            width: "10%",
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: "Thời gian hiển thị",
            width: 200,
            render: (record) => (
                <>
                    <Typography.Text>
                        {dayjs(record.startTime).format("HH:mm DD/MM/YYYY")}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        - {dayjs(record.endTime).format("HH:mm DD/MM/YYYY")}
                    </Typography.Text>
                </>
            ),
        },
        {
            title: "Trạng thái",
            dataIndex: "active",
            align: "center",
            width: 150,
            render: (active) =>
                active ? (
                    <Tag color="#4caf50">Active</Tag>
                ) : (
                    <Tag color="#f44336">In-active</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];
    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Nhãn quán
                </Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={restaurantLabels}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <RestaurantLabelDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    restaurantLabel={selectingRestaurantLabel}
                />
            )}
        </>
    );
}

export default RestaurantLabel;
