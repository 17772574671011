import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";
import { ADMIN_ROLES } from "utils/constants";

export default function Actions({ record, onEdit, onDelete }) {
    const { userInfo } = useSelector((state) => state.auth);
    const KEY_ACTIONS = {
        EDIT: "edit",
        DELETE: "delete",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onEdit(record);
                break;
            case KEY_ACTIONS.DELETE:
                onDelete(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.DELETE,
            label: "Xoá",
            danger: true,
            icon: <DeleteOutlined />,
            disabled: ![ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN].includes(
                userInfo.role
            ),
        },
    ];

    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
