import { createSlice } from "@reduxjs/toolkit";
import subCategoryApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    subCategories: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const categorySlice = createSlice({
    name: "service-sub-category",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setSubCategories: (state, action) => {
            state.subCategories = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addSubCategory: (state, action) => {
            state.isCompleted = true;
            state.subCategories.unshift(action.payload);
        },
        editSubCategory: (state, action) => {
            state.isCompleted = true;
            state.subCategories = state.subCategories.map((category) =>
                category._id === action.payload._id ? action.payload : category
            );
        },
        removeSubCategory: (state, action) => {
            state.isCompleted = true;
            state.subCategories = state.subCategories.filter(
                (category) => category._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setSubCategories,
    setPagination,
    addSubCategory,
    editSubCategory,
    removeSubCategory,
} = categorySlice.actions;

export const fetchSubCategories = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await subCategoryApi.getAll(params);
            dispatch(setSubCategories(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createSubCategory = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await subCategoryApi.create(formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addSubCategory(data));
                toast.success("Thêm danh mục con mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateSubCategory = (category, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await subCategoryApi.update(category._id, formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editSubCategory(data));
                toast.success("Cập nhật danh mục con thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteSubCategory = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await subCategoryApi.delete(id);
            if (data.success) {
                dispatch(removeSubCategory(id));
                toast.success("Xóa danh mục con thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa danh mục con thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default categorySlice.reducer;
