import {
    ContainerOutlined,
    CreditCardOutlined,
    DollarOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Tag,
    Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import { COMBO_TRANSACTION_STATUS } from "utils/constants";
import Actions from "./components/Actions";
import ChangeStatusModal from "./components/ChangeStatus";
import ComboTransactionDetailModal from "./components/DetailModal";
import * as comboTransactionActions from "./slice";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const RANGE_PRESETS = [
    {
        label: "Hôm nay",
        value: [dayjs(), dayjs()],
    },
    {
        label: "Hôm qua",
        value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
        label: "Tuần này",
        value: [dayjs().startOf("week"), dayjs()],
    },
    {
        label: "Tuần trước",
        value: [
            dayjs().subtract(1, "week").startOf("week"),
            dayjs().subtract(1, "week").endOf("week"),
        ],
    },
    {
        label: "Tháng này",
        value: [dayjs().startOf("month"), dayjs()],
    },
    {
        label: "Tháng trước",
        value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
        ],
    },
    {
        label: "Tất cả",
        value: [dayjs("13/10/2024", "DD/MM/YYYY"), dayjs()],
    },
];

function ComboTransaction() {
    const dispatch = useDispatch();
    const {
        comboTransactions,
        stats,
        pagination,
        isLoading,
        isProcessing,
        isCompleted,
    } = useSelector((state) => state.tuktukComboTransaction);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectingComboTransaction, setSelectingComboTransaction] =
        useState(null);
    const [searchValue, setSearchValue] = useState({
        status: "success",
        range: [dayjs().startOf("month"), dayjs()],
        from: dayjs().startOf("month").format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });
    const [searchState, setSearchState] = useState({
        status: "success",
        range: [dayjs().startOf("month"), dayjs()],
        from: dayjs().startOf("month").format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });

    useEffect(() => {
        dispatch(
            comboTransactionActions.fetchComboTransactions({
                ...pagination,
                ...searchValue,
            })
        );
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setOpenChangeStatusModal(false);
            setSelectingComboTransaction(null);
        }
    }, [isCompleted]);

    const onChangeSearchValue = (property, value, refresh) => {
        setSearchValue((prev) => ({ ...prev, [property]: value }));
        if (refresh) {
            setSearchState((prev) => ({ ...prev, [property]: value }));
            dispatch(
                comboTransactionActions.fetchComboTransactions({
                    ...pagination,
                    ...searchValue,
                    [property]: value,
                })
            );
        }
    };

    const onChangeSearchRange = (dates) => {
        setSearchValue((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
        setSearchState((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
        dispatch(
            comboTransactionActions.fetchComboTransactions({
                ...pagination,
                ...searchValue,
                from: dates[0].format("YYYY-MM-DD"),
                to: dates[1].format("YYYY-MM-DD"),
            })
        );
    };

    const onCreateItem = () => {
        setSelectingComboTransaction(null);
        setOpenDetailModal(true);
    };

    const onOpenChangeStatus = (comboTransaction) => {
        setSelectingComboTransaction(comboTransaction);
        setOpenChangeStatusModal(true);
    };

    const onSubmit = (comboTransaction) => {
        dispatch(comboTransactionActions.createTransaction(comboTransaction));
    };

    const onChangeStatus = (comboTransaction) => {
        dispatch(
            comboTransactionActions.updateComboTransactionStatus(
                comboTransaction
            )
        );
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingComboTransaction(null);
        dispatch(comboTransactionActions.setIsProcessing(false));
    };

    const onCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
        setSelectingComboTransaction(null);
        dispatch(comboTransactionActions.setIsProcessing(false));
    };

    const onTableChange = async (pagination) => {
        dispatch(
            comboTransactionActions.fetchComboTransactions({
                ...pagination,
                ...searchState,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Khách hàng",
            dataIndex: "user",
            render: (user) => (
                <>
                    <Typography.Text>
                        {user.name}{" "}
                        {user.tuktuk?.ticket > 0
                            ? `(${user.tuktuk.ticket} lượt)`
                            : ""}
                    </Typography.Text>
                    <Typography.Paragraph>{user.phone}</Typography.Paragraph>
                </>
            ),
        },
        {
            title: "Gói",
            dataIndex: ["combo", "name"],
        },
        {
            title: "Số tiền",
            dataIndex: "amount",
            render: (amount) => priceToCurrency(amount),
        },
        {
            title: "Số lượt",
            dataIndex: "ticket",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (status) => (
                <Tag color={COMBO_TRANSACTION_STATUS[status].color}>
                    {COMBO_TRANSACTION_STATUS[status].label}
                </Tag>
            ),
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: "15%",
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Thao tác",
            align: "center",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenChangeStatus}
                    editDisabled={
                        record.status !==
                        COMBO_TRANSACTION_STATUS.processing.value
                    }
                />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Yêu cầu mua gói tiết kiệm
                </Typography.Title>
                <Space size="middle">
                    <RangePicker
                        presets={RANGE_PRESETS}
                        value={searchValue.range}
                        onChange={onChangeSearchRange}
                        style={{ width: "250px" }}
                    />
                    <Select
                        onChange={(value) =>
                            onChangeSearchValue("status", value, true)
                        }
                        value={searchValue.status}
                        options={[
                            {
                                value: "",
                                label: "Tất cả",
                            },
                            ...Object.values(COMBO_TRANSACTION_STATUS),
                        ]}
                        style={{ width: "140px" }}
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={onCreateItem}
                    >
                        Tạo giao dịch
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
                <Col xs={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Tổng combo"
                            value={stats?.combos || 0}
                            prefix={<CreditCardOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Tổng tiền"
                            value={priceToCurrency(stats?.amount || 0)}
                            prefix={<DollarOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Tổng số lượt"
                            value={priceToCurrency(stats?.tickets || 0)}
                            prefix={<ContainerOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Số lượt còn lại"
                            value={stats?.ticketsLeft || 0}
                            prefix={<ContainerOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={comboTransactions}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <ComboTransactionDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            )}
            {openChangeStatusModal && (
                <ChangeStatusModal
                    open={openChangeStatusModal}
                    onCancel={onCloseChangeStatusModal}
                    comboTransaction={selectingComboTransaction}
                    onSubmit={onChangeStatus}
                    isProcessing={isProcessing}
                />
            )}
        </>
    );
}

export default ComboTransaction;
