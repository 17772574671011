import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    GiftOutlined,
    TagOutlined,
} from "@ant-design/icons";
import {
    Button,
    Dropdown,
    Image,
    Input,
    Modal,
    Row,
    Space,
    Switch,
    Table,
    Tag,
    Typography,
} from "antd";
import DefaultDishImg from "assets/img/no-avatar.jpg";
import restaurantApi from "features/food/restaurant/api";
import * as restaurantActions from "features/food/restaurant/slice";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPhotoUrl, getSlug, priceToCurrency } from "utils/common";
import AutoAvailableDishModal from "./AutoAvailableDishModal";

const { Paragraph } = Typography;

export default function DishOfMenuModal({
    onCancel,
    menu,
    open,
    onOpenDishModal,
}) {
    const {
        menuInfo: { dishes, menus },
    } = useSelector((state) => state.foodRestaurant);

    const dispatch = useDispatch();

    const [dishesOfMenu, setDishesOfMenu] = useState(
        menu ? dishes.filter((dish) => dish.menu === menu._id) : dishes
    );
    const [selectedDish, setSelectedDish] = useState(null);
    const [isOpenAutoAvailableDishModal, setIsOpenAutoAvailableDishModal] =
        useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        setDishesOfMenu(
            menu ? dishes.filter((dish) => dish.menu === menu._id) : dishes
        );
    }, [dishes]);

    const onDelete = (record) => {
        Modal.confirm({
            title: `Xác nhận xoá món ăn`,
            content: `Bạn có chắc chắn muốn xoá món ăn này?`,
            onOk: () => dispatch(restaurantActions.deleteDish(record._id)),
            centered: true,
        });
    };

    const KEY_ACTIONS = {
        EDIT: "edit",
        DELETE: "delete",
    };

    const onClick = (key) => (dish) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onOpenDishModal(dish);
                break;
            case KEY_ACTIONS.DELETE:
                onDelete(dish);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.DELETE,
            label: "Xóa",
            danger: true,
            icon: <DeleteOutlined />,
        },
    ];

    const onChangeSearch = debounce((e) => {
        const keyword = e.target.value;
        const dishesFilter = (
            menu ? dishes.filter((dish) => dish.menu === menu._id) : dishes
        ).filter(
            (dish) =>
                dish.name.toLowerCase().includes(keyword.toLowerCase()) ||
                dish._id.toLowerCase().includes(keyword.toLowerCase()) ||
                dish.slug.includes(getSlug(keyword))
        );
        setDishesOfMenu(dishesFilter);
    }, 300);

    const onChangeDishIsHot = (dish, isHot) => {
        Modal.confirm({
            title: `Xác nhận cập nhật trạng thái món nổi bật`,
            content: `Bạn có chắc chắn muốn cập nhật trạng thái món nổi bật?`,
            onOk: () =>
                dispatch(
                    restaurantActions.updateDishIsHot({ _id: dish._id, isHot })
                ),
            centered: true,
        });
    };

    const onChangeDishIsAvailable = (dish, isAvailable) => {
        Modal.confirm({
            title: `Xác nhận cập nhật trạng thái món`,
            content: `Bạn có chắc chắn muốn cập nhật trạng thái còn món?`,
            onOk: () => {
                dispatch(
                    restaurantActions.updateDishIsAvailable({
                        _id: dish._id,
                        isAvailable,
                    })
                );
                if (!isAvailable) {
                    setSelectedDish(dish);
                    setIsOpenAutoAvailableDishModal(true);
                }
            },
            centered: true,
        });
    };

    const onChangeDishIsHidden = (dish, isHidden) => {
        Modal.confirm({
            title: `Xác nhận cập nhật trạng thái món`,
            content: `Bạn có chắc chắn muốn ${isHidden ? "ẩn" : "hiện"} món?`,
            onOk: () =>
                dispatch(
                    restaurantActions.updateDishIsHidden({
                        _id: dish._id,
                        isHidden,
                    })
                ),
            centered: true,
        });
    };

    const onCloseAutoAvailableDish = () => {
        setIsOpenAutoAvailableDishModal(false);
    };

    const onSubmitAutoAvailableDish = async (time) => {
        if (selectedDish?._id && time) {
            setIsProcessing(true);
            try {
                const data = await restaurantApi.autoAvailableDish(
                    selectedDish._id,
                    time
                );
                if (data.error) throw new Error(data.error.msg);
                toast.success("Thiết lập tự động bật lại món thành công!");
                setIsOpenAutoAvailableDishModal(false);
                setSelectedDish(null);
            } catch (error) {
                toast.error(
                    error.message || "Có lỗi xảy ra, vui lòng thử lại sau!"
                );
            } finally {
                setIsProcessing(false);
            }
        } else {
            setSelectedDish(null);
            setIsOpenAutoAvailableDishModal(false);
        }
    };
    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {index + 1}
                </Paragraph>
            ),
        },
        {
            title: "Hình ảnh",
            width: "10%",
            dataIndex: "image",
            render: (image) => (
                <Image
                    alt="Image of food category"
                    src={
                        image
                            ? getPhotoUrl(image?.path || null)
                            : DefaultDishImg
                    }
                    width={80}
                    height={80}
                />
            ),
        },
        {
            title: "Tên món",
            // dataIndex: "name",
            width: "15%",
            ellipsis: {
                showTitle: false,
            },
            render: (record) => (
                <>
                    {record.name}
                    <br />
                    {record.isEvent ? (
                        <Tag
                            icon={<GiftOutlined />}
                            color="#ff004b"
                            style={{ marginTop: "6px" }}
                        >
                            Món Deal
                        </Tag>
                    ) : null}
                </>
            ),
        },
        {
            title: "Danh mục",
            dataIndex: "menu",
            width: "10%",
            render: (menu) => {
                const menuOfDish = menus.find((_menu) => _menu._id === menu);
                return <span>{menuOfDish?.name || "---"}</span>;
            },
        },
        {
            title: "Giá",
            // dataIndex: "price",
            align: "center",
            width: "10%",
            render: (record) =>
                record.isDiscount ? (
                    <>
                        <small style={{ color: "#ff6c00" }}>
                            <TagOutlined />{" "}
                            {record.discountType === "direct"
                                ? `${
                                      record.discountValue % 1000 === 0
                                          ? `${Math.floor(
                                                record.discountValue / 1000
                                            )}K`
                                          : `${record.discountValue}₫`
                                  }`
                                : `${record.discountValue}%`}
                        </small>
                        &nbsp;<del>{priceToCurrency(record.rawPrice || 0)}</del>
                        <br />
                        <span>{priceToCurrency(record.price)}</span>
                    </>
                ) : (
                    <span>{priceToCurrency(record.price)}</span>
                ),
        },
        {
            title: "Đã bán",
            dataIndex: "soldCount",
            align: "center",
            width: "8%",
            render: (soldCount) => soldCount ?? "-",
        },
        {
            title: "Tùy chọn",
            dataIndex: "optionCount",
            align: "center",
            width: "8%",
            render: (optionCount) => `${optionCount} tùy chọn`,
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            align: "center",
            width: "8%",
        },
        {
            title: "Còn món",
            align: "center",
            width: "10%",
            render: (record) => (
                <Switch
                    size="default"
                    checked={record.isAvailable}
                    onChange={(value) => onChangeDishIsAvailable(record, value)}
                />
            ),
        },
        {
            title: "Nổi bật",
            align: "center",
            width: "10%",
            render: (record) => (
                <Switch
                    size="default"
                    checked={record.isHot}
                    onChange={(value) => onChangeDishIsHot(record, value)}
                />
            ),
        },
        {
            title: "Ẩn khỏi app",
            align: "center",
            width: "10%",
            render: (record) => (
                <Switch
                    size="default"
                    checked={record.isHidden}
                    onChange={(value) => onChangeDishIsHidden(record, value)}
                />
            ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "12%",
            render: (record) => (
                <Dropdown
                    menu={{
                        items: actions,
                        onClick: ({ key }) => onClick(key)(record),
                    }}
                >
                    <Button>
                        <Space>
                            Thao tác
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            ),
        },
    ];
    return (
        <>
            <Modal
                title={`Món ăn trong danh mục ${menu ? menu.name : "Tất cả"}`}
                cancelText="Huỷ"
                centered
                open={open}
                onCancel={onCancel}
                width="80%"
                okButtonProps={{ className: "hidden" }}
            >
                <Space direction="vertical" size="middle">
                    <Row justify="end" align="middle">
                        <Space>
                            <Input.Search
                                placeholder="Tìm món"
                                onChange={onChangeSearch}
                                enterButton
                                allowClear
                                style={{ width: "300px" }}
                            />
                            <Button
                                className="float-right"
                                type="primary"
                                onClick={() => onOpenDishModal()}
                            >
                                Thêm món ăn
                            </Button>
                        </Space>
                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={dishesOfMenu}
                        rowKey={(record) => record._id}
                        pagination={{ pageSize: 50 }}
                        scroll={{
                            y: "60vh",
                        }}
                    />
                </Space>
            </Modal>
            {isOpenAutoAvailableDishModal && (
                <AutoAvailableDishModal
                    open={isOpenAutoAvailableDishModal}
                    onClose={onCloseAutoAvailableDish}
                    onSubmit={onSubmitAutoAvailableDish}
                    isProcessing={isProcessing}
                />
            )}
        </>
    );
}
