import { Form, Input, Modal, Select } from "antd";
import { REQUEST_STATUS } from "utils/constants";

function ChangeStatusModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, order } = props;

    const initialValues = {
        _id: order._id,
        status: order.status || "new",
        note: order.note || "",
        shipperId: order.shipperId || "",
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const data = { ...initialValues, ...values };
                onSubmit(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Cập nhật trạng thái"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="status"
                    label="Trạng thái"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select options={Object.values(REQUEST_STATUS)} />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="note" label="Ghi chú">
                        <Input.TextArea />
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Form.Item name="shipper" label="ID Shipper nhận">
                        <Input />
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangeStatusModal;
