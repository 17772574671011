import { createSlice } from "@reduxjs/toolkit";
import restaurantLabelApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    restaurantLabels: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const restaurantLabelSlice = createSlice({
    name: "food-restaurantlabel",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setRestaurantLabels: (state, action) => {
            state.restaurantLabels = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addRestaurantLabel: (state, action) => {
            state.isCompleted = true;
            state.restaurantLabels.unshift(action.payload);
        },
        editRestaurantLabel: (state, action) => {
            state.isCompleted = true;
            state.restaurantLabels = state.restaurantLabels.map(
                (restaurantLabel) =>
                    restaurantLabel._id === action.payload._id
                        ? action.payload
                        : restaurantLabel
            );
        },
        removeRestaurantLabel: (state, action) => {
            state.isCompleted = true;
            state.restaurantLabels = state.restaurantLabels.filter(
                (restaurantLabel) => restaurantLabel._id !== action.payload
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setRestaurantLabels,
    setPagination,
    addRestaurantLabel,
    editRestaurantLabel,
    removeRestaurantLabel,
} = restaurantLabelSlice.actions;

export const fetchRestaurantLabels = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await restaurantLabelApi.getAll(params);
            dispatch(setRestaurantLabels(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createRestaurantLabel = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantLabelApi.create(formData);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addRestaurantLabel(data));
                toast.success("Thêm nhãn quán mới thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateRestaurantLabel = (restaurantLabel, formData) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantLabelApi.update(
                restaurantLabel._id,
                formData
            );
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editRestaurantLabel(data));
                toast.success("Cập nhật nhãn quán thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const deleteRestaurantLabel = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await restaurantLabelApi.delete(id);
            if (data.success) {
                dispatch(removeRestaurantLabel(id));
                toast.success("Xóa nhãn quán thành công!");
            } else {
                toast.error("Lỗi hệ thống, xóa nhãn quán thất bại!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default restaurantLabelSlice.reducer;
