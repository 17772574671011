import {
    DownOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useSelector } from "react-redux";

export default function Actions({ record, onViewDetail, onHide }) {
    const { userInfo } = useSelector((state) => state.auth);
    const KEY_ACTIONS = {
        VIEW_DETAIL: "view_detail",
        HIDE_REVIEW: "hide-review",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.VIEW_DETAIL:
                onViewDetail(record);
                break;
            case KEY_ACTIONS.HIDE_REVIEW:
                onHide(record);
                break;
            default:
                break;
        }
    };

    const actions = {
        super_admin: [
            {
                key: KEY_ACTIONS.VIEW_DETAIL,
                label: "Xem chi tiết",
                icon: <EyeOutlined />,
            },
            {
                key: KEY_ACTIONS.HIDE_REVIEW,
                label: `${record.isHidden ? "Hiện" : "Ẩn"} đánh giá`,
                icon: record.isHidden ? (
                    <EyeOutlined />
                ) : (
                    <EyeInvisibleOutlined />
                ),
                danger: !record.isHidden,
            },
        ],
    };

    return (
        <Dropdown menu={{ items: actions["super_admin"] || [], onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
