import { Form, Input, Modal, Select } from "antd";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { formItemLayoutHorizontal } from "utils/constants";
import { useState } from "react";
import SelectPhotoModal from "components/common/SelectPhotoModal";

function AdTypeDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, adType } = props;

    const [content, setContent] = useState("");
    const [visiblePhotoModal, setVisiblePhotoModal] = useState(false);

    const initialValues = adType ?? {
        name: "",
        description: "",
        detail: "",
        priority: 99,
        active: true,
    };

    const handleOpenPhotoModal = async () => {
        setVisiblePhotoModal(true);
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => onSubmit({ ...initialValues, ...values }))
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <>
            <Modal
                title="Loại hình quảng cáo"
                okText="Lưu"
                cancelText="Huỷ"
                centered
                open={open}
                onOk={onOK}
                onCancel={onCancel}
                confirmLoadTypeing={isProcessing}
                width={960}
            >
                <Form
                    {...formItemLayoutHorizontal}
                    form={form}
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="name"
                        label="Tên"
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                    >
                        <Input placeholder="Tên" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Mô tả"
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                    >
                        <Input placeholder="Mô tả ngắn" />
                    </Form.Item>

                    <Form.Item
                        name="detail"
                        label="Chi tiết"
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                    >
                        {/* <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder="Nội dung tin nhắn"
          /> */}
                        <MDEditor
                            value={content}
                            onChange={setContent}
                            height={400}
                            commands={[
                                { ...commands.title1, icon: "H1" },
                                { ...commands.title2, icon: "H2" },
                                { ...commands.title3, icon: "H3" },
                                { ...commands.title4, icon: "H4" },
                                { ...commands.title5, icon: "H5" },
                                commands.divider,
                                commands.bold,
                                commands.italic,
                                commands.strikethrough,
                                commands.divider,
                                {
                                    ...commands.link,
                                    execute: (state, api) => {
                                        let text = state.selectedText || "text";
                                        let template = `[${text}](url)`;
                                        api.replaceSelection(template);
                                    },
                                },
                                {
                                    ...commands.image,
                                    execute: (state, api) => {
                                        handleOpenPhotoModal();
                                        let alt = state.selectedText || "alt";
                                        let template = `![${alt}](url)`;
                                        api.replaceSelection(template);
                                    },
                                },
                                commands.quote,
                                commands.code,
                                commands.codeBlock,
                                commands.divider,
                                commands.unorderedListCommand,
                                commands.orderedListCommand,
                                commands.checkedListCommand,
                            ]}
                        />
                    </Form.Item>
                    <Form.Item name="priority" label="Thứ tự ưu tiên">
                        <Input type={"number"} min={0} />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        label="Trạng thái"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="--Trạng thái--">
                            <Select.Option value={true}>Active</Select.Option>
                            <Select.Option value={false}>
                                In-active
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            {visiblePhotoModal && (
                <SelectPhotoModal
                    open={visiblePhotoModal}
                    onClose={() => setVisiblePhotoModal(false)}
                />
            )}
        </>
    );
}

export default AdTypeDetailModal;
