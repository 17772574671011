import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Empty,
    Form,
    Input,
    Modal,
    Pagination,
    Row,
    Space,
    Typography,
} from "antd";
import PostItem from "./components/PostItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as pendingPostActions from "./slice";
import RejectModal from "./components/RejectModal";

function PostVerify() {
    const dispatch = useDispatch();
    const { posts, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.liquidationPendingPost);

    useEffect(() => {
        dispatch(
            pendingPostActions.fetchPendingPosts({
                ...pagination,
                status: "pending",
            })
        );
    }, []);

    const onChangePagination = (current) => {
        dispatch(
            pendingPostActions.fetchPendingPosts({
                ...pagination,
                current,
            })
        );
    };

    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [selectedPost, setelectedPost] = useState(null);
    const onConfirmReject = (post, rejectReason) => {
        dispatch(pendingPostActions.rejectPost(post, rejectReason));
        setOpenRejectModal(false);
    };

    const onReject = (postID) => {
        setelectedPost(postID);
        setOpenRejectModal(true);
    };

    const onCancelReject = () => {
        setOpenRejectModal(false);
        setelectedPost(null);
    };
    const onConfirmVerify = (postID) => {
        dispatch(pendingPostActions.verifyPost(postID));
    };

    const onVerify = (postID) => {
        Modal.confirm({
            title: `Phê duyệt bài viết`,
            content: `Bạn có chắc muốn phê duyệt bài viết này?`,
            onOk: () => onConfirmVerify(postID),
            centered: true,
        });
    };

    return (
        <>
            <RejectModal
                open={openRejectModal}
                onCancel={onCancelReject}
                onSubmit={onConfirmReject}
                selectedPost={selectedPost}
            />
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Đang chờ phê duyệt
                </Typography.Title>
                <Space size="middle">
                    <Button type="primary" icon={<CheckOutlined />}>
                        Phê duyệt tất cả
                    </Button>
                    <Button icon={<CloseOutlined />} danger>
                        Từ chối tất cả
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Row gutter={[30, 30]}>
                {posts.length === 0 ? (
                    <div className="flex justify-center w-full">
                        <Empty description="Không có dữ liệu" />
                    </div>
                ) : (
                    posts.map((post) => (
                        <Col span={6} key={post._id}>
                            <PostItem
                                post={post}
                                onReject={onReject}
                                setOpenRejectModal={setOpenRejectModal}
                                onVerify={onVerify}
                            />
                        </Col>
                    ))
                )}
            </Row>
            <br />
            <br />
            <div className="flex justify-center	">
                <Pagination
                    defaultCurrent={pagination?.current || 1}
                    pageSize={pagination?.pageSize || 10}
                    total={pagination?.total || 0}
                    onChange={onChangePagination}
                />
            </div>
        </>
    );
}

export default PostVerify;
