import axiosClient from "utils/api/axios-client";

const hotKeywordApi = {
    getHotKeywords: () => {
        const url = "/api/v1/market/hot-keywords";
        return axiosClient.get(url);
    },

    update: (keywords) => {
        const url = `/api/v1/market/hot-keywords`;
        return axiosClient.put(url, keywords);
    },
};

export default hotKeywordApi;
