import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardLayout } from "components";
import Private from "components/common/private";

import Dashboard from "features/dashboard";
import Login from "features/auth/login";

import Module from "features/module";
import Banner from "features/banner";
import Building from "features/building";
import Account from "features/account";
import User from "features/user";
import WalletRequest from "features/wallet-request";

import TuktukRequest from "features/tuktuk/request";
import TuktukOrder from "features/tuktuk/order";
import TuktukBanner from "features/tuktuk/banner";
import TuktukDriver from "features/tuktuk/driver";
import TuktukCombo from "features/tuktuk/combo";
import TuktukComboTransaction from "features/tuktuk/combo-transaction";
import TuktukPlace from "features/tuktuk/place";
import TuktukConfig from "features/tuktuk/config";

import NewsPost from "features/news/post";
import NewsPostVerify from "features/news/verify";

import LiquidationCategory from "features/liquidation/category";
import LiquidationPost from "features/liquidation/post";
import LiquidationPostVerify from "features/liquidation/verify";
import LiquidationHotKeyword from "features/liquidation/hot-keyword";

import MarketCategory from "features/market/category";
import MarketPost from "features/market/post";
import MarketPostVerify from "features/market/verify";
import MarketHotKeyword from "features/market/hot-keyword";

import ServiceCategory from "features/service/category";
import ServiceSubCategory from "features/service/sub-category";
import ServicePlace from "features/service/place";
import ServiceBanner from "features/service/banner";
import ServiceHotKeyword from "features/service/hot-keyword";

import FoodCategory from "features/food/category";
import FoodCampaign from "features/food/campaign";
import FoodCollection from "features/food/collection";
import FoodRestaurant from "features/food/restaurant";
import FoodBannerUser from "features/food/banner/app-user";
import FoodBannerMerchant from "features/food/banner/app-merchant";
import FoodAdList from "features/food/ad/list";
import FoodAdType from "features/food/ad/type";
import FoodVoucher from "features/food/voucher";
import FoodReview from "features/food/review";
import FoodNewLiveOrder from "features/food/order/new";
import FoodOrder from "features/food/order/history";
import FoodHotKeyword from "features/food/setup/hot-keyword";
import FoodRestaurantLabel from "features/food/restaurant-label";
import FoodConfigGeneral from "features/food/setup/general";
import FoodEvent11 from "features/food/setup/event-1-1";

import DeliveryOrder from "features/delivery/order";
import DeliveryShipper from "features/delivery/shipper";
import DeliveryConfig from "features/delivery/config";

import TransportOrder from "features/transport/order";
import TransportDriver from "features/transport/driver";

export const routers = {
    dashboard: "",
    login: "dang-nhap",
    account: "tai-khoan",
    module: "app-module",
    banner: "app-banner",
    building: "toa-nha",
    user: "nguoi-dung",
    walletRequest: "lenh-nap-rut-vi",
    delivery: {
        index: "giao-hang-mua-ho",
        shipper: "shipper",
        order: "don-hang",
        config: "thiet-lap",
    },
    transport: {
        index: "dat-xe",
        driver: "tai-xe",
        order: "don-hang",
    },
    tuktuk: {
        index: "tuktuk",
        request: "goi-xe",
        order: "lich-su-chuyen-di",
        banner: "banner",
        driver: "tai-xe",
        place: "dia-diem-hot",
        combo: "goi-tiet-kiem",
        comboTransaction: "yeu-cau-mua-goi",
        config: "thiet-lap",
    },
    news: {
        index: "bang-tin",
        post: "bai-dang",
        verify: "duyet-bai",
    },
    liquidation: {
        index: "thanh-ly",
        category: "danh-muc",
        post: "bai-dang",
        verify: "duyet-bai",
        hotKeyword: "tu-khoa-noi-bat",
    },
    market: {
        index: "cho-cu-dan",
        category: "danh-muc",
        post: "bai-dang",
        verify: "duyet-bai",
        hotKeyword: "tu-khoa-noi-bat",
    },
    services: {
        index: "so-tay-dich-vu",
        category: "danh-muc",
        subCategory: "danh-muc-con",
        service: "diem-dich-vu",
        review: "danh-gia",
        banner: "banner",
        hotKeyword: "tu-khoa-noi-bat",
    },
    food: {
        index: "giao-do-an",
        category: "danh-muc",
        restaurant: "quan-an",
        order: {
            index: "don-hang",
            newLive: "don-hang-moi-live",
            new: "don-hang-moi",
            history: "lich-su-don-hang",
        },
        banner: {
            index: "banner",
            user: "app-user",
            merchant: "app-merchant",
        },
        campaign: "chien-dich",
        restaurantLabel: "nhan-quan",
        ad: {
            index: "quang-cao",
            list: "danh-sach",
            type: "loai-quang-cao",
        },
        collection: "bo-suu-tap",
        voucher: "voucher",
        review: "danh-gia",
        setup: {
            index: "thiet-lap",
            general: "chung",
            hotKeyword: "tu-khoa-noi-bat",
            event: "event-1-1",
        },
    },
};

const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<DashboardLayout />}>
                    <Route index element={<Private component={Dashboard} />} />
                    <Route
                        path={routers.module}
                        element={<Private component={Module} />}
                    />
                    <Route
                        path={routers.banner}
                        element={<Private component={Banner} />}
                    />
                    <Route
                        path={routers.building}
                        element={<Private component={Building} />}
                    />
                    <Route
                        path={routers.account}
                        element={<Private component={Account} />}
                    />
                    <Route
                        path={routers.user}
                        element={<Private component={User} />}
                    />
                    <Route
                        path={routers.walletRequest}
                        element={<Private component={WalletRequest} />}
                    />
                    <Route path={routers.tuktuk.index}>
                        <Route
                            path={routers.tuktuk.request}
                            element={<Private component={TuktukRequest} />}
                        />
                        <Route
                            path={routers.tuktuk.order}
                            element={<Private component={TuktukOrder} />}
                        />
                        <Route
                            path={routers.tuktuk.banner}
                            element={<Private component={TuktukBanner} />}
                        />
                        <Route
                            path={routers.tuktuk.driver}
                            element={<Private component={TuktukDriver} />}
                        />
                        <Route
                            path={routers.tuktuk.combo}
                            element={<Private component={TuktukCombo} />}
                        />
                        <Route
                            path={routers.tuktuk.comboTransaction}
                            element={
                                <Private component={TuktukComboTransaction} />
                            }
                        />
                        <Route
                            path={routers.tuktuk.place}
                            element={<Private component={TuktukPlace} />}
                        />
                        <Route
                            path={routers.tuktuk.config}
                            element={<Private component={TuktukConfig} />}
                        />
                    </Route>
                    <Route path={routers.delivery.index}>
                        <Route
                            path={routers.delivery.order}
                            element={<Private component={DeliveryOrder} />}
                        />
                        <Route
                            path={routers.delivery.shipper}
                            element={<Private component={DeliveryShipper} />}
                        />
                        <Route
                            path={routers.delivery.config}
                            element={<Private component={DeliveryConfig} />}
                        />
                    </Route>
                    <Route path={routers.transport.index}>
                        <Route
                            path={routers.transport.order}
                            element={<Private component={TransportOrder} />}
                        />
                        <Route
                            path={routers.transport.driver}
                            element={<Private component={TransportDriver} />}
                        />
                    </Route>
                    <Route path={routers.food.index}>
                        <Route
                            path={routers.food.category}
                            element={<Private component={FoodCategory} />}
                        />
                        <Route
                            path={routers.food.campaign}
                            element={<Private component={FoodCampaign} />}
                        />
                        <Route
                            path={routers.food.restaurantLabel}
                            element={
                                <Private component={FoodRestaurantLabel} />
                            }
                        />
                        <Route
                            path={routers.food.collection}
                            element={<Private component={FoodCollection} />}
                        />
                        <Route
                            path={routers.food.restaurant}
                            element={<Private component={FoodRestaurant} />}
                        />
                        <Route path={routers.food.banner.index}>
                            <Route
                                path={routers.food.banner.user}
                                element={<Private component={FoodBannerUser} />}
                            />
                            <Route
                                path={routers.food.banner.merchant}
                                element={
                                    <Private component={FoodBannerMerchant} />
                                }
                            />
                        </Route>
                        <Route path={routers.food.ad.index}>
                            <Route
                                path={routers.food.ad.list}
                                element={<Private component={FoodAdList} />}
                            />
                            <Route
                                path={routers.food.ad.type}
                                element={<Private component={FoodAdType} />}
                            />
                        </Route>
                        <Route
                            path={routers.food.voucher}
                            element={<Private component={FoodVoucher} />}
                        />
                        <Route
                            path={routers.food.review}
                            element={<Private component={FoodReview} />}
                        />
                        <Route path={routers.food.order.index}>
                            <Route
                                path={routers.food.order.history}
                                element={<Private component={FoodOrder} />}
                            />
                            <Route
                                path={routers.food.order.newLive}
                                element={
                                    <Private component={FoodNewLiveOrder} />
                                }
                            />
                            <Route
                                path={routers.food.order.new}
                                element={
                                    <Private component={FoodNewLiveOrder} />
                                }
                            />
                        </Route>
                        <Route path={routers.food.setup.index}>
                            <Route
                                path={routers.food.setup.general}
                                element={
                                    <Private component={FoodConfigGeneral} />
                                }
                            />
                            <Route
                                path={routers.food.setup.hotKeyword}
                                element={<Private component={FoodHotKeyword} />}
                            />
                            <Route
                                path={routers.food.setup.event}
                                element={<Private component={FoodEvent11} />}
                            />
                        </Route>
                    </Route>
                    <Route path={routers.news.index}>
                        <Route
                            path={routers.news.post}
                            element={<Private component={NewsPost} />}
                        />
                        <Route
                            path={routers.news.verify}
                            element={<Private component={NewsPostVerify} />}
                        />
                    </Route>
                    <Route path={routers.liquidation.index}>
                        <Route
                            path={routers.liquidation.category}
                            element={
                                <Private component={LiquidationCategory} />
                            }
                        />
                        <Route
                            path={routers.liquidation.post}
                            element={<Private component={LiquidationPost} />}
                        />
                        <Route
                            path={routers.liquidation.verify}
                            element={
                                <Private component={LiquidationPostVerify} />
                            }
                        />
                        <Route
                            path={routers.liquidation.hotKeyword}
                            element={
                                <Private component={LiquidationHotKeyword} />
                            }
                        />
                    </Route>
                    <Route path={routers.market.index}>
                        <Route
                            path={routers.market.category}
                            element={<Private component={MarketCategory} />}
                        />
                        <Route
                            path={routers.market.post}
                            element={<Private component={MarketPost} />}
                        />
                        <Route
                            path={routers.market.verify}
                            element={<Private component={MarketPostVerify} />}
                        />
                        <Route
                            path={routers.market.hotKeyword}
                            element={<Private component={MarketHotKeyword} />}
                        />
                    </Route>
                    <Route path={routers.services.index}>
                        <Route
                            path={routers.services.category}
                            element={<Private component={ServiceCategory} />}
                        />
                        <Route
                            path={routers.services.subCategory}
                            element={<Private component={ServiceSubCategory} />}
                        />
                        <Route
                            path={routers.services.service}
                            element={<Private component={ServicePlace} />}
                        />
                        <Route
                            path={routers.services.banner}
                            element={<Private component={ServiceBanner} />}
                        />
                        <Route
                            path={routers.services.hotKeyword}
                            element={<Private component={ServiceHotKeyword} />}
                        />
                    </Route>
                </Route>
                <Route path={routers.login} element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Routers;
