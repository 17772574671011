import {
    CommentOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    HeartOutlined,
    ShareAltOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Badge,
    Card,
    Carousel,
    Image,
    Row,
    Space,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import moment from "moment";
import { getPhotoUrl, randomNumber } from "utils/common";

const { Meta } = Card;
const { Text, Paragraph } = Typography;
function PostItem(props) {
    const { post, onDetail, setOpenDetailModal, onDelete, onHide, onShow } =
        props;
    const view = randomNumber(0, 100);

    const handleDetail = () => {
        setOpenDetailModal(true);
        onDetail(post);
    };

    return (
        <Card
            cover={
                <Carousel>
                    {post.photos.map((image, index) => (
                        <div key={index}>
                            <Image
                                src={getPhotoUrl(image?.path || null)}
                                alt={`Slide ${index + 1}`}
                                width="100%"
                                height="240px"
                                style={{ objectFit: "cover" }}
                            />
                        </div>
                    ))}
                </Carousel>
            }
            actions={[
                <EditOutlined onClick={handleDetail} key="edit" />,
                post.status === "public" ? (
                    <EyeInvisibleOutlined
                        onClick={() => onHide(post._id)}
                        key="hide"
                    />
                ) : (
                    <EyeOutlined onClick={() => onShow(post._id)} key="hide" />
                ),
                <DeleteOutlined
                    onClick={() => onDelete(post._id)}
                    key="delete"
                />,
                <ShareAltOutlined
                    // onClick={() => onDelete(post._id)}
                    key="share"
                />,
            ]}
        >
            <Meta
                avatar={
                    <Avatar
                        src={getPhotoUrl(post.author.avatar?.path || null)}
                    />
                }
                title={
                    <Row justify="space-between" align="middle">
                        <Text strong>{post.author.name}</Text>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            <EyeOutlined /> {view} ·{" "}
                            {moment(post.createdAt).fromNow(true)}
                        </Text>
                    </Row>
                }
                description={
                    <div style={{ minHeight: 125 }}>
                        <Text
                            strong
                            copyable={{
                                text: post._id,
                                tooltip: "Copy ID",
                            }}
                        >
                            <Tooltip title="Hiển thị trên trang chủ">
                                {post.inHome && "🔥 "}
                            </Tooltip>
                            <Tooltip title="Bài viết nổi bật">
                                {post.isHot && "⭐ "}
                            </Tooltip>
                            <Tooltip title="Đã ghim">
                                {post.isPinned && "📌 "}
                            </Tooltip>
                            {post.code || "---"}
                        </Text>
                        {post.status === "reject" && post.rejectReason && (
                            <>
                                <br />
                                <Text type="danger" strong>
                                    [{post.rejectReason}]
                                </Text>
                                <br />
                            </>
                        )}
                        <Paragraph
                            ellipsis={{
                                rows: 3,
                                expandable: true,
                                symbol: "Xem thêm",
                            }}
                        >
                            {post.content}
                        </Paragraph>
                        <Space size="middle">
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                <HeartOutlined /> {post.likeCount || 0}
                            </Text>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                <CommentOutlined /> {post.commentCount || 0}
                            </Text>
                        </Space>
                    </div>
                }
            />
        </Card>
    );
}
export default PostItem;
