import { Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as comboActions from "../../combo/slice";

function ComboTransactionDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel } = props;

    const { combos } = useSelector((state) => state.tuktukCombo);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open && combos.length === 0) {
            dispatch(
                comboActions.fetchCombos({
                    pageSize: 1000,
                })
            );
        }
    }, [open]);

    const initialValues = {
        combo: "",
        user: "",
        note: "",
    };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                const comboTransaction = { ...initialValues, ...values };
                onSubmit(comboTransaction);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal
            title="Giao dịch mua gói"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
        >
            <Form layout="vertical" form={form} initialValues={initialValues}>
                <Form.Item
                    name="combo"
                    label="Gói tiết kiệm"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select
                        options={
                            combos &&
                            combos
                                .filter((combo) => !!combo.active)
                                .map((combo) => ({
                                    value: combo._id,
                                    label: combo.name,
                                }))
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="user"
                    label="ID khách hàng"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ComboTransactionDetailModal;
