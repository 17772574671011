import { Button, DatePicker, Descriptions, Empty, Modal } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { priceToCurrency } from "utils/common";
import restaurantApi from "../api";

const { RangePicker } = DatePicker;
const rangePresets = [
    {
        label: "Hôm nay",
        value: [dayjs(), dayjs()],
    },
    {
        label: "Hôm qua",
        value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
        label: "Tuần này",
        value: [dayjs().startOf("week"), dayjs()],
    },
    {
        label: "Tuần trước",
        value: [
            dayjs().subtract(1, "week").startOf("week"),
            dayjs().subtract(1, "week").endOf("week"),
        ],
    },
    {
        label: "Tháng này",
        value: [dayjs().startOf("month"), dayjs()],
    },
    {
        label: "Tháng trước",
        value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
        ],
    },
    {
        label: "Tất cả",
        value: [dayjs("13/10/2024", "DD/MM/YYYY"), dayjs()],
    },
];

export default function StatsModal({ open, onClose, restaurant }) {
    const [range, setRange] = useState([dayjs(), dayjs()]);
    const [stats, setStats] = useState(null);

    useEffect(() => {
        const getRestaurantStats = async () => {
            const data = await restaurantApi.getStats(
                restaurant._id,
                range[0].format("YYYY-MM-DD"),
                range[1].format("YYYY-MM-DD")
            );
            if (!data || data.error) {
                toast.error(data.error);
                setStats(null);
            } else setStats(data);
        };
        if (restaurant) getRestaurantStats();
    }, [restaurant, range]);

    const onRangeChange = (dates) => {
        setRange(dates);
    };
    return (
        <>
            <Modal
                open={open}
                onCancel={onClose}
                title="Thống kê quán"
                footer={[
                    <Button type="primary" key="back" onClick={onClose}>
                        OK
                    </Button>,
                ]}
            >
                <RangePicker
                    presets={rangePresets}
                    value={range}
                    onChange={onRangeChange}
                />
                <br />
                <br />
                {stats ? (
                    <Descriptions
                        bordered
                        contentStyle={{ fontWeight: 500 }}
                        column={1}
                    >
                        <Descriptions.Item label="Quán">
                            {restaurant.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tổng đơn">
                            {stats.totalOrders}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tổng tiền món">
                            {priceToCurrency(stats.totalDishFee)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tổng tiền quán nhận">
                            {priceToCurrency(stats.totalRestaurantReceived)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tổng tiền CK app nhận">
                            {priceToCurrency(
                                stats.totalDishFee -
                                    stats.totalRestaurantReceived
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tổng tiền app nhận">
                            {priceToCurrency(stats.totalAppReceived)}
                        </Descriptions.Item>
                    </Descriptions>
                ) : (
                    <Empty
                        description={`Không có dữ liệu thống kê quán ${restaurant.name} trong thời gian này!`}
                    />
                )}
            </Modal>
        </>
    );
}
