import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { priceToCurrency } from "utils/common";
import { useState } from "react";
import DishRow from "./DishRow";

export default function NewDishDisplay({
    dish,
    dishInfo,
    onRemoveDish,
    onSaveDish,
}) {
    const [isEditing, setIsEditing] = useState(false);
    if (isEditing) {
        return (
            <DishRow
                dish={dishInfo}
                prevSelectedDish={dish}
                onCancelEditing={() => setIsEditing(false)}
                onConfirmSelect={onSaveDish}
            />
        );
    } else {
        return (
            <tr>
                <td className="border-0 border-t border-solid border-gray-300 w-1">
                    {dish.quantity}x
                </td>
                <td className="border-0 border-t border-solid border-gray-300">
                    <div>{dish.name}</div>
                    {dish.customizesSummary && (
                        <div>
                            <Typography.Text className="text-xs">
                                {dish.customizesSummary}
                            </Typography.Text>
                        </div>
                    )}
                    {dish.note && (
                        <div>
                            <Typography.Text className="text-xs">
                                {dish.note}
                            </Typography.Text>
                        </div>
                    )}
                </td>
                <td className="border-0 border-t border-solid border-gray-300 text-right w-1">
                    <div className="flex gap-x-4 justify-end">
                        {priceToCurrency(dish.totalCost)}
                        <div
                            className="cursor-pointer"
                            onClick={() => setIsEditing(true)}
                        >
                            <EditOutlined />
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => onRemoveDish(dish)}
                        >
                            <DeleteOutlined className="text-red-500" />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}
