import { DatePicker, Form, Input, Modal, Typography } from "antd";
import { useState } from "react";

export default function RejectModal(props) {
    const { open, onSubmit, onCancel, confirmLoading, selectedPost } = props;
    const [rejectReason, setRejectReason] = useState("Vi phạm nội quy");
    const onChangeRejectReason = (e) => {
        setRejectReason(e.target.value);
    };

    return (
        <Modal
            title="Từ chối bài viết"
            okText="Xác nhận"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={() => onSubmit(selectedPost, rejectReason)}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            width={380}
        >
            <Typography.Paragraph>
                Bạn có chắc chắn muốn từ chối bài viết này?
            </Typography.Paragraph>
            <Form layout="vertical">
                <Form.Item label="Lý do từ chối">
                    <Input.TextArea
                        allowClear
                        value={rejectReason}
                        onChange={onChangeRejectReason}
                        autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
