import {
    CheckOutlined,
    CloseOutlined,
    CommentOutlined,
    EyeOutlined,
    HeartOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Button,
    Card,
    Carousel,
    Image,
    Row,
    Space,
    Tooltip,
    Typography,
} from "antd";
import moment from "moment";
import { getPhotoUrl, priceToCurrency, randomNumber } from "utils/common";

const { Meta } = Card;
const { Text, Paragraph } = Typography;

function PostItem(props) {
    const { post, onReject, onVerify, setOpenRejectModal } = props;
    const view = randomNumber(0, 100);

    const handleReject = () => {
        setOpenRejectModal(true);
        onReject(post._id);
    };

    return (
        <>
            <Card
                cover={
                    <Carousel>
                        {post.photos.map((image, index) => (
                            <div key={index}>
                                <Image
                                    src={getPhotoUrl(image?.path || null)}
                                    alt={`Slide ${index + 1}`}
                                    width="100%"
                                    height="240px"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        ))}
                    </Carousel>
                }
                actions={[
                    <Button
                        onClick={() => onVerify(post._id)}
                        key="verify"
                        type="text"
                    >
                        <CheckOutlined /> Phê duyệt
                    </Button>,
                    <Button onClick={handleReject} key="reject" type="text">
                        <CloseOutlined /> Từ chối
                    </Button>,
                ]}
            >
                <Meta
                    avatar={
                        <Avatar
                            src={getPhotoUrl(post.author.avatar?.path || null)}
                        />
                    }
                    title={
                        <Row justify="space-between" align="middle">
                            <Text strong>{post.author.name}</Text>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                <EyeOutlined /> {view} ·{" "}
                            </Text>
                        </Row>
                    }
                    description={
                        <div style={{ minHeight: 125 }}>
                            <Text
                                strong
                                copyable={{
                                    text: post._id,
                                    tooltip: "Copy ID",
                                }}
                            >
                                <Tooltip title="Đã ghim">
                                    {post.isPinned && "📌 "}
                                </Tooltip>
                                {post.title || "Chưa có tiêu đề"}
                            </Text>
                            <Paragraph
                                ellipsis={{
                                    rows: 3,
                                    expandable: true,
                                    symbol: "Xem thêm",
                                }}
                            >
                                {post.content}
                            </Paragraph>
                            Lần cuối cập nhật:{" "}
                            <Text strong>
                                {moment(
                                    post.userUpdatedAt || post.updatedAt
                                ).fromNow(true)}
                            </Text>
                            <br />
                            <Space size="middle">
                                <Text type="secondary" style={{ fontSize: 12 }}>
                                    <HeartOutlined /> {post.likeCount || 0}
                                </Text>
                                <Text type="secondary" style={{ fontSize: 12 }}>
                                    <CommentOutlined /> {post.commentCount || 0}
                                </Text>
                            </Space>
                        </div>
                    }
                />
            </Card>
        </>
    );
}
export default PostItem;
