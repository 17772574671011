import { createSlice } from "@reduxjs/toolkit";
import walletRequestApi from "./api";
import { toast } from "react-toastify";

const initialState = {
    walletRequests: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const walletRequestSlice = createSlice({
    name: "wallet-request",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setWalletRequests: (state, action) => {
            state.walletRequests = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addWalletRequest: (state, action) => {
            state.isCompleted = true;
            state.walletRequests.unshift(action.payload);
        },
        editWalletRequest: (state, action) => {
            state.isCompleted = true;
            state.walletRequests = state.walletRequests.map((walletRequest) =>
                walletRequest._id === action.payload._id
                    ? action.payload
                    : walletRequest
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setWalletRequests,
    setPagination,
    addWalletRequest,
    editWalletRequest,
    removeWalletRequest,
} = walletRequestSlice.actions;

export const fetchWalletRequests = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await walletRequestApi.getAll(params);
            dispatch(setWalletRequests(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export const createRequest = (walletRequest) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await walletRequestApi.create(walletRequest);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addWalletRequest(data.data));
                toast.success(
                    `Tạo lệnh ${
                        walletRequest.type === "RECHARGE" ? "nạp" : "rút"
                    } tiền thành công!`
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export const updateWalletRequestStatus = (walletRequest) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await walletRequestApi.updateStatus(walletRequest);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editWalletRequest(data.data));
                toast.success(
                    "Thay đổi trạng thái lệnh nạp - rút ví thành công!"
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};
export default walletRequestSlice.reducer;
