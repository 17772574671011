import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./components/Actions";
import BuildingDetailModal from "./components/DetailModal";
import * as buildingsAction from "./slice";

function Building() {
    const dispatch = useDispatch();
    const { buildings, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.building);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectingBuilding, setSelectingBuilding] = useState(null);
    const [searchName, setSearchName] = useState("");

    useEffect(() => {
        dispatch(buildingsAction.fetchBuildings(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectingBuilding(null);
        }
    }, [isCompleted]);

    const onOpenDetailModal = (account = null) => {
        setSelectingBuilding(account);
        setOpenDetailModal(true);
    };

    const onSubmit = (account) => {
        if (account._id) {
            dispatch(buildingsAction.updateBuilding(account));
        } else {
            dispatch(buildingsAction.createBuilding(account));
        }
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingBuilding(null);
        dispatch(buildingsAction.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            buildingsAction.fetchBuildings({
                ...pagination,
                name: searchName,
                current: 1,
            })
        );
    };

    const onDelete = (building) => {
        Modal.confirm({
            title: `Xác nhận xoá tòa nhà`,
            content: `Bạn có chắc chắn muốn xoá tòa nhà này?`,
            onOk: () => dispatch(buildingsAction.deleteBuilding(building._id)),
            centered: true,
        });
    };

    const onTableChange = async (pagination) => {
        dispatch(
            buildingsAction.fetchBuildings({
                ...pagination,
                name: searchName,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            width: "5%",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Tên tòa nhà",
            width: "20%",
            ellipsis: {
                showTitle: false,
            },
            render: (record) => record.name,
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: "Trạng thái",
            dataIndex: "active",
            align: "center",
            width: "10%",
            render: (active) =>
                active ? (
                    <Tag color="#4caf50">Active</Tag>
                ) : (
                    <Tag color="#f44336">In-active</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Tòa nhà
                </Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm theo tên"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={buildings}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <BuildingDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    building={selectingBuilding}
                />
            )}
        </>
    );
}

export default Building;
