import { Avatar, Button, Input, List, Modal, Typography } from "antd";
import { flatten } from "flat";
import { useState } from "react";
import { useEffect } from "react";
import orderApi from "../api";
import { getPhotoUrl } from "utils/common";

export default function RejectModal({ open, onClose, order }) {
    const [rejects, setRejects] = useState([]);

    useEffect(() => {
        const getOrderRejects = async () => {
            const data = await orderApi.getRejects(order._id);
            setRejects(data);
        };
        getOrderRejects();
    }, [order]);

    return (
        <>
            <Modal
                open={open}
                onCancel={onClose}
                title="Danh sách tài xế bỏ qua đơn hàng"
                footer={[
                    <Button type="primary" key="back" onClick={onClose}>
                        OK
                    </Button>,
                ]}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={rejects}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        src={getPhotoUrl(
                                            item.avatar?.path || null
                                        )}
                                    />
                                }
                                // title={<a href="https://ant.design">{item.title}</a>}
                                description={
                                    <b style={{ color: "#111" }}>{item.name}</b>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
}
